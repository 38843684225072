import { IResourceComponentsProps, useList } from "@refinedev/core";

import {
    DateField,
    List,
    TagField,
    useModal, useSelect,
    useTable,
} from '@refinedev/antd';

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import {Modal, Space, Table, Tooltip, Typography, Popover, Button, Row, Col, Spin, Select} from 'antd';
import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import {
    IDevice,
    IDeviceType,
    ILogPosition,
    IStatusWareHouse,
    IWareHousePosition,
} from '../../interfaces';
import {StoreContext} from '../../contexts/StoreContext';
import {SocketContext} from '../../contexts/socket';
import { CSVLink } from "react-csv";
import dayjs from 'dayjs';
import {API_URL} from '../../constants';
import {UtilityHelper} from '../../helper/utility';
const {Title} = Typography;
export const DashboardList: React.FC<IResourceComponentsProps> = () => {
    const utilityHelper = UtilityHelper(API_URL + '/api');
    const {value, value2} = useContext(StoreContext);
    const [store, setStore] = value;
    const socket = useContext(SocketContext);
    const [lis, setLis] = useState('');
    const [role, setRole] = useState('');
    const [dataDashBoard, setDataDashBoard] = useState<any>([]);

    const {tableProps, searchFormProps, tableQueryResult} = useTable<IStatusWareHouse>({
        resource: "custom/dashboardBox",
        meta: {populate: 'device,deviceType,seller'},
        syncWithLocation: false,
        queryOptions: {
          enabled: false
        },
        pagination: {
          mode: 'off'
        },
        filters: {
            permanent: [
                {
                    field: 'seller][id]',
                    operator: 'eq',
                    value: store.id ? store.id : 0
                },
                {
                    field: 'device][device_type][isMagazzino]',
                    operator: 'eq',
                    value: true
                }
            ]
        },
        sorters: {
            permanent: [
                {
                    field: 'device.description',
                    order: 'asc'
                },
                {
                    field: 'module',
                    order: 'asc'
                },
            ]
        }
    });

    const {tableProps: logTableProps, tableQueryResult: dataLog} = useTable<ILogPosition>({
        resource: "log-positions",
        meta: {populate: 'stock,seller, stock.product, stock.product.category'},
        syncWithLocation: false,
        pagination: {
            pageSize: 15,
            mode: "server"
        },

        filters: {
            permanent: [
                {
                    field: 'seller][id]',
                    operator: 'eq',
                    value: store.id
                }
            ]
        },
        sorters: {
            permanent: [
                {
                    field: 'createdAt',
                    order: 'desc'
                }
            ]
        }
    });

    useEffect(() => {
        tableQueryResult.refetch().then(res => {
            // console.log(res.data?.data);
            setDataDashBoard(res.data?.data.sort((a, b) => Number(a.device) - Number(b.device)));
        });
    }, [store.id])

    useEffect(() => {
        utilityHelper.getListino().then( (res: any) => {
            setLis(res);
        });
    }, [])
    const [position, setPosition] = useState<string>();
    const [product, setProduct] = useState<any>('');
    const { show, modalProps, close } = useModal();

    const {data: checkBox, refetch: getCheckBox}  = useList<any>({
        resource: "custom/checkBox",
        queryOptions: {
            enabled: false
        },
    });

    const [updateLog, setUpdateLog] = useState<any>([]);
    const [loadLog, setLoadLog] = useState<boolean>(true);
    const [updateEsp, setUpdateEsp] = useState<any>([]);
    const [loadLogEsp, setLoadEsp] = useState<boolean>(true);
    const [device, setDevice] = useState<any[]>([]);

    const [count, setCount] = useState(0);
    let d: any = {};

    const { selectProps: deviceSelectProps } = useSelect<IDevice>({
        resource: "devices",
        optionValue: 'id',
        optionLabel: 'description',

        pagination: {
            mode: "off"
        }
    });

    useEffect(() => {
        //Implementing the setInterval method
        // const interval = setInterval(() => {
            console.log('DEVICE: ', device);
            Object.keys(device).map((d: any) => {
                const diff = new Date().getTime() - new Date(device[d]['data']).getTime();
                const seconds = Math.floor(diff / 1000);
                console.log(seconds);
                if (seconds >= 50) {
                    console.log(d, device[d]['value'])
                    device[d]['value'] = false
                    console.log(device);
                    setDevice({...device});
                }
            })

        //Clearing the interval
        // return () => {
        //     clearInterval(interval);
        // }

    }, [count]);

    const [deviceId, setDeviceId] = useState(0);

    useEffect(() => {
        utilityHelper.permission().then( (res: any) => {
            console.log(res);
            setRole(res);
            socket.on("updateData", (data: any, error: any) => {
                // console.log('RICEVO AGGIORNAMENTO SOCKET MAGAZZINO')
                tableQueryResult.refetch().then(res => {
                    // console.log(res);
                    setDataDashBoard(res.data?.data.sort((a, b) => Number(a.device) - Number(b.device)));
                });
                getCheckBox();
                // console.log(data);
            });
            socket.on("checkUpdateMater", (data: any, error: any) => {
                // console.log('CONTROLLO MASTER: ', data);
                d[data.data.device.id] = {value: data.data.success, data: data.data.device.data};
                // console.log(d);
                setDevice({...d});
                setCount(count + 1);
            });
            // console.log(res);
            if (res === 'admin') {
                socket.on("checkEsp32", (data: any, error: any) => {
                    // console.log(data.log);
                    setLoadEsp(false);
                    setUpdateEsp((current: any) => [...current, data?.log]);

                });
                socket.on("checkUpdate", (data: any, error: any) => {
                    setLoadLog(false);
                    setUpdateLog((current: any) => [...current, data?.log]);
                });
            }

            return () => {
                socket.off('updateData');
                socket.off('checkUpdateMater');
                if (res === 'admin') {
                    socket.off('checkEsp32');
                    socket.off('checkUpdate');
                }
            };
        })
    }, []);

    const filterLog = (data: any) => {
        console.log(data);
        setDeviceId(data);
    }
    const [loading, setLoading] = useState(false);

    // useEffect(() => {
    //     // console.log(isSuccess, isLoading, isFetching, isFetched, isRefetching)
    //     if (isSuccess && !isLoading && !isFetching && isFetched && !isRefetching) {
    //         setProduct(data?.data?.[0].stock);
    //     }
    // });

    const viewProduct = (record: any, value: any, column: string) => {
        // console.log(record, value, column);
        // if (value) {
        //     setPosition(column);
        //     refetch().then( result => {
        //         setLoading(true);
        //     })
        // }

    }

    const modalClose = () => {
        close();
    }

    const [showDashboard, setShowDashboard] = useState(false);
    const [showUpateLog, setShowUpdateLog] = useState(false);
    const [showUpateEsp, setShowUpdateEsp] = useState(false);
    const [showLog, setShowLog] = useState(false);
    const [dataExp, setDataExp] = useState<any>([]);
    const exportMovement = (event: any, done: any) => {
        dataLog.data?.data.forEach( (v: any) => {
            console.log(v);
            setDataExp((current: any) => [...current, {
                codice: v.stock.product.codice,
                descrizione: v.stock.product.description,
                posizione: v.position + '_',
                data: dayjs(v.dataUpdate).format('DD-MM-YYYY HH:mm'),
                azione: v.isPresent ? 'Inserito' : 'Prelevato'
            }])
        })
        setTimeout(() => {
            done(true);
        }, 1000)
    }

    const [boxOccupati, setBoxOccupati] = useState(0);
    const [boxLiberi, setBoxLiberi] = useState(0);
    const showBox = () => {
        setShowDashboard(!showDashboard);
        tableQueryResult.refetch().then((res: any) => {
            let countGreen = 0;
            let countRed = 0;
            if (res && res.data && res.data.data && res.data.data.length > 0) {
                for (const o of res.data.data) {
                    for (const l of Object.keys(o)) {
                        if (l.includes('col')) {
                            if (o[l]) {
                                countGreen++
                            } else {
                                countRed++
                            }
                        }
                    }
                }
                console.log(countGreen, countRed);
                setBoxOccupati(countGreen);
                setBoxLiberi(countRed);
            }
        });
    }
    return (
        <List title={''}>
            <Row gutter={24}>
                <Col span={24}>
                    <Title style={{color: 'greenyellow'}} level={2}>Ultimo Aggiornamento Listino : <DateField style={{fontSize: 28}} value={lis} format={'DD-MM-YYYY'} /></Title>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={8}>
                    <Space>
                        <Button type={'primary'} style={{backgroundColor: !showLog ? 'red' : 'green'}} onClick={() => setShowLog(!showLog)}>Mostra Log</Button>
                        <Button type={'primary'} style={{backgroundColor: !showDashboard ? 'red' : 'green'}} onClick={() => showBox()}>Mostra Magazzino</Button>
                        <Button hidden={role !== 'admin'} type={'primary'} style={{backgroundColor: !showUpateLog ? 'red' : 'green'}} onClick={() => setShowUpdateLog(!showUpateLog)}>Mostra Log Device</Button>
                        <Button hidden={role !== 'admin'} type={'primary'} style={{backgroundColor: !showUpateEsp ? 'red' : 'green'}} onClick={() => setShowUpdateEsp(!showUpateEsp)}>Mostra Log Box</Button>
                    </Space>
                </Col>
                <Col span={8} hidden={role === 'admin'}>
                    <Title level={3} style={{
                        color: 'green'
                    }}>Box Occupati N. {boxOccupati}</Title>
                </Col>
                <Col span={8} hidden={role === 'admin'}>
                    <Title style={{
                        color: 'red'
                    }} level={3}>Box Liberi N. {boxLiberi}</Title>
                </Col>
            </Row>
            <hr />
            <div hidden={!showLog}>
                <Table {...logTableProps}
                    size={'small'}
                    footer={(value) => (<CSVLink
                        data={dataExp}
                        separator={';'}
                        asyncOnClick={true}
                        filename={'Log_Operazioni_' + dayjs().format('DD_MM_YYYY') + '.csv'}
                        onClick={exportMovement}
                    >
                        <Button icon={<Icons.FileExcelOutlined />}></Button>
                    </CSVLink>)}
                >
                    <Table.Column
                        dataIndex={'stock'}
                        title={'Prodotto'}
                        key={'prodotto.codice'}
                        render={(value) => (
                            value?.product?.codice
                        )}
                    />
                    <Table.Column
                        dataIndex={'stock'}
                        title={'Prodotto'}
                        key={'prodotto'}
                        render={(value) => (
                            value?.product?.description
                        )}
                    />
                    <Table.Column
                        dataIndex={'position'}
                        title={'Posizione'}
                        key={'position'}
                        />
                    <Table.Column
                        dataIndex={'dataUpdate'}
                        title={'Data Operazione'}
                        key={'dataUpdate'}
                        render={(value) => (
                            <DateField value={value} format={'DD-MM-YYYY - HH:mm:ss'} />
                        )}
                    />
                    <Table.Column
                        dataIndex={'isPresent'}
                        title={'Azione'}
                        key={'isPresent'}
                        render={(value) => (
                            <TagField value={value ? 'Inserito' : 'Prelevato'} style={{ color: value ? 'green' : 'red'}} />
                        )}
                    />
                </Table>
            </div>
            <div hidden={!showDashboard}>
                <Table size="small" {...tableProps}
                       dataSource={dataDashBoard}
                       rowKey="id"
                       sticky={false}
                       // scroll={{y: window.innerHeight - 393 + 'px'}}
                >
                    <Table.Column
                        dataIndex="description"
                        key="description"
                        title="Dispositivo"
                        align={'center'}
                        width={50}
                        // render={(value) => String.fromCharCode(value + 65)}
                        render={(value) => <TagField color={'orange'} value={value} />}
                    />
                    <Table.Column
                        dataIndex="deviceId"
                        key="deviceId"
                        title="Status"
                        align={'center'}
                        width={50}
                        // render={(value) => String.fromCharCode(value + 65)}
                        render={(value, record: any) => <span>
                            <div className={device?.[record.deviceId]?.value ? 'blink_me' : 'blink_me_red'} ></div>
                            <DateField style={{
                                fontSize: 10
                            }} value={device?.[record.deviceId]?.data} format={'HH:mm:ss'}/>
                        </span>}
                    />
                    <Table.Column
                        dataIndex="device"
                        key="device"
                        title="Master"
                        align={'center'}
                        width={40}
                        // render={(value) => String.fromCharCode(value + 65)}
                        render={(value, record: any) => <TagField color={'blue'} value={value} />}
                    />
                    <Table.Column
                        dataIndex="module"
                        key="module"
                        title="Modulo"
                        align={'center'}
                        width={40}
                        // render={(value) => String.fromCharCode(value + 65)}
                        render={(value) => <TagField color={'yellow'} value={String.fromCharCode(value + 65)} />}
                    />
                    <Table.Column
                        dataIndex="col1"
                        key="col1"
                        align={'center'}
                        title={''}
                        width={10}
                        render={(value, record: any) => <>
                            <Popover content={<div></div>}
                                     title={<Title level={2}>{(record.col1 && record.desc_1) ? record.desc_1 : (record.col1 && !record.desc_1) ? 'Abuse' : 'None'}</Title>}
                                     trigger="click"
                                     onOpenChange={() => viewProduct(record, value, record.device.deviceNumber + String.fromCharCode(record.module + 65) + '0')}>
                                <Button type="primary" style={{
                                    backgroundColor: (record.col1 && record.desc_1) ? 'green' : (record.col1 && !record.desc_1) ? 'orange' : 'red',
                                    borderRadius: 10,
                                    boxShadow: '2px 2px 3px black'}}>0</Button>
                            </Popover>
                        </>}
                    />
                    <Table.Column
                        dataIndex="col2"
                        key="col2"
                        align={'center'}
                        title={''}
                        width={10}
                        render={(value, record: any) => <>
                            <Popover content={<div></div>}
                                     title={<Title level={2}>{(record.col2 && record.desc_2) ? record.desc_2 : (record.col2 && !record.desc_2) ? 'Abuse' : 'None'}</Title>}
                                     trigger="click"
                                     onOpenChange={() => viewProduct(record, value, record.device.deviceNumber + String.fromCharCode(record.module + 65) + '1')}>
                                <Button type="primary" style={{
                                    backgroundColor: (record.col2 && record.desc_2) ? 'green' : (record.col2 && !record.desc_2) ? 'orange' : 'red',
                                    borderRadius: 10,
                                    boxShadow: '2px 2px 3px black'}}>1</Button>
                            </Popover>
                        </>}
                    />
                    <Table.Column
                        dataIndex="col3"
                        key="col3"
                        align={'center'}
                        title={''}
                        width={10}
                        render={(value, record: any) => <>
                            <Popover content={<div></div>}
                                     title={<Title level={2}>{(record.col3 && record.desc_3) ? record.desc_3 : (record.col3 && !record.desc_3) ? 'Abuse' : 'None'}</Title>}
                                     trigger="click"
                                     onOpenChange={() => viewProduct(record, value, record.device.deviceNumber + String.fromCharCode(record.module + 65) + '2')}>
                                <Button type="primary" style={{
                                    backgroundColor: (record.col3 && record.desc_3) ? 'green' : (record.col3 && !record.desc_3) ? 'orange' : 'red',
                                    borderRadius: 10,
                                    boxShadow: '2px 2px 3px black'}}>2</Button>
                            </Popover>
                        </>}
                    />
                    <Table.Column
                        dataIndex="col4"
                        key="col4"
                        align={'center'}
                        title={''}
                        width={10}
                        render={(value, record: any) => <>
                            <Popover content={<div></div>}
                                     title={<Title level={2}>{(record.col4 && record.desc_4) ? record.desc_4 : (record.col4 && !record.desc_4) ? 'Abuse' : 'None'}</Title>}
                                     trigger="click"
                                     onOpenChange={() => viewProduct(record, value, record.device.deviceNumber + String.fromCharCode(record.module + 65) + '3')}>
                                <Button type="primary" style={{
                                    backgroundColor: (record.col4 && record.desc_4) ? 'green' : (record.col4 && !record.desc_4) ? 'orange' : 'red',
                                    borderRadius: 10,
                                    boxShadow: '2px 2px 3px black'}}>3</Button>
                            </Popover>
                        </>}
                    />
                    <Table.Column
                        dataIndex="col5"
                        key="col5"
                        align={'center'}
                        title={''}
                        width={10}
                        render={(value, record: any) => <>
                            <Popover content={<div></div>}
                                     title={<Title level={2}>{(record.col5 && record.desc_5) ? record.desc_5 : (record.col5 && !record.desc_5) ? 'Abuse' : 'None'}</Title>}
                                     trigger="click"
                                     onOpenChange={() => viewProduct(record, value, record.device.deviceNumber + String.fromCharCode(record.module + 65) + '4')}>
                                <Button type="primary" style={{
                                    backgroundColor: (record.col5 && record.desc_5) ? 'green' : (record.col5 && !record.desc_5) ? 'orange' : 'red',
                                    borderRadius: 10,
                                    boxShadow: '2px 2px 3px black'}}>4</Button>
                            </Popover>
                        </>}
                    />
                    <Table.Column
                        dataIndex="col6"
                        key="col6"
                        align={'center'}
                        title={''}
                        width={10}
                        render={(value, record: any) => <>
                            <Popover content={<div></div>}
                                     title={<Title level={2}>{(record.col6 && record.desc_6) ? record.desc_6 : (record.col6 && !record.desc_6) ? 'Abuse' : 'None'}</Title>}
                                     trigger="click"
                                     onOpenChange={() => viewProduct(record, value, record.device.deviceNumber + String.fromCharCode(record.module + 65) + '5')}>
                                <Button type="primary" style={{
                                    backgroundColor: (record.col6 && record.desc_6) ? 'green' : (record.col6 && !record.desc_6) ? 'orange' : 'red',
                                    borderRadius: 10,
                                    boxShadow: '2px 2px 3px black'}}>5</Button>
                            </Popover>
                        </>}
                    />
                    <Table.Column
                        dataIndex="col7"
                        key="col7"
                        align={'center'}
                        title={''}
                        width={10}
                        render={(value, record: any) => <>
                            <Popover content={<div></div>}
                                     title={<Title level={2}>{(record.col7 && record.desc_7) ? record.desc_7 : (record.col7 && !record.desc_7) ? 'Abuse' : 'None'}</Title>}
                                     trigger="click"
                                     onOpenChange={() => viewProduct(record, value, record.device.deviceNumber + String.fromCharCode(record.module + 65) + '6')}>
                                <Button type="primary" style={{
                                    backgroundColor: (record.col7 && record.desc_7) ? 'green' : (record.col7 && !record.desc_7) ? 'orange' : 'red',
                                    borderRadius: 10,
                                    boxShadow: '2px 2px 3px black'}}>6</Button>
                            </Popover>
                        </>}
                    />
                    <Table.Column
                        dataIndex="col8"
                        key="col8"
                        align={'center'}
                        title={''}
                        width={10}
                        render={(value, record: any) => <>
                            <Popover content={<div></div>}
                                     title={<Title level={2}>{(record.col8 && record.desc_8) ? record.desc_8 : (record.col8 && !record.desc_8) ? 'Abuse' : 'None'}</Title>}
                                     trigger="click"
                                     onOpenChange={() => viewProduct(record, value, record.device.deviceNumber + String.fromCharCode(record.module + 65) + '7')}>
                                <Button type="primary" style={{
                                    backgroundColor: (record.col8 && record.desc_8) ? 'green' : (record.col8 && !record.desc_8) ? 'orange' : 'red',
                                    borderRadius: 10,
                                    boxShadow: '2px 2px 3px black'}}>7</Button>
                            </Popover>
                        </>}
                    />
                    <Table.Column
                        dataIndex="col9"
                        key="col9"
                        align={'center'}
                        title={''}
                        width={10}
                        render={(value, record: any) => <>
                            <Popover content={<div></div>}
                                     title={<Title level={2}>{(record.col9 && record.desc_9) ? record.desc_9 : (record.col9 && !record.desc_9) ? 'Abuse' : 'None'}</Title>}
                                     trigger="click"
                                     onOpenChange={() => viewProduct(record, value, record.device.deviceNumber + String.fromCharCode(record.module + 65) + '8')}>
                                <Button type="primary" style={{
                                    backgroundColor: (record.col9 && record.desc_9) ? 'green' : (record.col9 && !record.desc_9) ? 'orange' : 'red',
                                    borderRadius: 10,
                                    boxShadow: '2px 2px 3px black'}}>8</Button>
                            </Popover>
                        </>}
                    />
                    <Table.Column
                        dataIndex="col10"
                        key="col10"
                        align={'center'}
                        title={''}
                        width={10}
                        render={(value, record: any) => <>
                            <Popover content={<div></div>}
                                     title={<Title level={2}>{(record.col10 && record.desc_10) ? record.desc_10 : (record.col10 && !record.desc_10) ? 'Abuse' : 'None'}</Title>}
                                     trigger="click"
                                     onOpenChange={() => viewProduct(record, value, record.device.deviceNumber + String.fromCharCode(record.module + 65) + '9')}>
                                <Button type="primary" style={{
                                    backgroundColor: (record.col10 && record.desc_10) ? 'green' : (record.col10 && !record.desc_10) ? 'orange' : 'red',
                                    borderRadius: 10,
                                    boxShadow: '2px 2px 3px black'}}>9</Button>
                            </Popover>
                        </>}
                    />
                    <Table.Column
                        dataIndex="col11"
                        key="col11"
                        align={'center'}
                        title={''}
                        width={10}
                        render={(value, record: any) => <>
                            <Popover content={<div></div>}
                                     title={<Title level={2}>{(record.col11 && record.desc_11) ? record.desc_11 : (record.col11 && !record.desc_11) ? 'Abuse' : 'None'}</Title>}
                                     trigger="click"
                                     onOpenChange={() => viewProduct(record, value, record.device.deviceNumber + String.fromCharCode(record.module + 65) + '10')}>
                                <Button type="primary" style={{
                                    backgroundColor: (record.col11 && record.desc_11) ? 'green' : (record.col11 && !record.desc_11) ? 'orange' : 'red',
                                    borderRadius: 10,
                                    boxShadow: '2px 2px 3px black'}}>10</Button>
                            </Popover>
                        </>}
                    />
                    <Table.Column
                        dataIndex="col12"
                        key="col12"
                        align={'center'}
                        title={''}
                        width={10}
                        render={(value, record: any) => <>
                            <Popover content={<div></div>}
                                     title={<Title level={2}>{(record.col12 && record.desc_12) ? record.desc_12 : (record.col12 && !record.desc_12) ? 'Abuse' : 'None'}</Title>}
                                     trigger="click"
                                     onOpenChange={() => viewProduct(record, value, record.device.deviceNumber + String.fromCharCode(record.module + 65) + '11')}>
                                <Button type="primary" style={{
                                    backgroundColor: (record.col12 && record.desc_12) ? 'green' : (record.col12 && !record.desc_12) ? 'orange' : 'red',
                                    borderRadius: 10,
                                    boxShadow: '2px 2px 3px black'}}>11</Button>
                            </Popover>
                        </>}
                    />
                    <Table.Column
                        dataIndex="col13"
                        key="col13"
                        align={'center'}
                        title={''}
                        width={10}
                        render={(value, record: any) => <>
                            <Popover content={<div></div>}
                                     title={<Title level={2}>{(record.col13 && record.desc_13) ? record.desc_13 : (record.col13 && !record.desc_13) ? 'Abuse' : 'None'}</Title>}
                                     trigger="click"
                                     onOpenChange={() => viewProduct(record, value, record.device.deviceNumber + String.fromCharCode(record.module + 65) + '12')}>
                                <Button type="primary" style={{
                                    backgroundColor: (record.col13 && record.desc_13) ? 'green' : (record.col13 && !record.desc_13) ? 'orange' : 'red',
                                    borderRadius: 10,
                                    boxShadow: '2px 2px 3px black'}}>12</Button>
                            </Popover>
                        </>}
                    />
                    <Table.Column
                        dataIndex="col14"
                        key="col14"
                        align={'center'}
                        title={''}
                        width={10}
                        render={(value, record: any) => <>
                            <Popover content={<div></div>}
                                     title={<Title level={2}>{(record.col14 && record.desc_14) ? record.desc_14 : (record.col14 && !record.desc_14) ? 'Abuse' : 'None'}</Title>}
                                     trigger="click"
                                     onOpenChange={() => viewProduct(record, value, record.device.deviceNumber + String.fromCharCode(record.module + 65) + '13')}>
                                <Button type="primary" style={{
                                    backgroundColor: (record.col14 && record.desc_14) ? 'green' : (record.col14 && !record.desc_14) ? 'orange' : 'red',
                                    borderRadius: 10,
                                    boxShadow: '2px 2px 3px black'}}>13</Button>
                            </Popover>
                        </>}
                    />
                    <Table.Column
                        dataIndex="col15"
                        key="col15"
                        align={'center'}
                        title={''}
                        width={10}
                        render={(value, record: any) => <>
                            <Popover content={<div></div>}
                                     title={<Title level={2}>{(record.col15 && record.desc_15) ? record.desc_15 : (record.col15 && !record.desc_15) ? 'Abuse' : 'None'}</Title>}
                                     trigger="click"
                                     onOpenChange={() => viewProduct(record, value, record.device.deviceNumber + String.fromCharCode(record.module + 65) + '14')}>
                                <Button type="primary" style={{
                                    backgroundColor: (record.col15 && record.desc_15) ? 'green' : (record.col15 && !record.desc_15) ? 'orange' : 'red',
                                    borderRadius: 10,
                                    boxShadow: '2px 2px 3px black'}}>14</Button>
                            </Popover>
                        </>}
                    />
                    <Table.Column
                        dataIndex="col16"
                        key="col16"
                        align={'center'}
                        title={''}
                        width={10}
                        render={(value, record: any) => <>
                            <Popover content={<div></div>}
                                     title={<Title level={2}>{(record.col16 && record.desc_16) ? record.desc_16 : (record.col16 && !record.desc_16) ? 'Abuse' : 'None'}</Title>}
                                     trigger="click"
                                     onOpenChange={() => viewProduct(record, value, record.device.deviceNumber + String.fromCharCode(record.module + 65) + '15')}>
                                <Button type="primary" style={{
                                    backgroundColor: (record.col16 && record.desc_16) ? 'green' : (record.col16 && !record.desc_16) ? 'orange' : 'red',
                                    borderRadius: 10,
                                    boxShadow: '2px 2px 3px black'}}>15</Button>
                            </Popover>
                        </>}
                    />
                </Table>
            </div>
            <div hidden={!showUpateLog} style={{
                overflow: 'auto',
                maxHeight: window.innerHeight - 350
            }}>
                <div className="example" hidden={!loadLog}>
                    <Spin />
                </div>
                { [...updateLog].reverse().map( (r: any) => (
                    <Title level={5}>{r?.description} - IP: {r?.ip} - MODULI: {r?.deviceCount} - SERIALE: {r?.serial} - DATA: {dayjs(r?.data).format('DD-MM-YYYY HH:mm:ss')}</Title>
                ))}
            </div>
            <div hidden={!showUpateEsp}
                 style={{
                overflow: 'auto',
                maxHeight: window.innerHeight - 350
            }}>
                <div className="example" hidden={!loadLogEsp}>
                    <Spin />
                </div>
                <Select style={{width: '100%'}} {...deviceSelectProps} onSelect={filterLog} />
                <hr />
                { [...updateEsp].reverse().filter((d: any) => d.device?.id === deviceId).map( (r: any) => {
                    return (
                        <>
                            <Title level={5}>{r?.device?.description} - IP: {r?.device?.ip} - MODULI: {r?.device?.deviceCount} - SERIALE: {r?.device?.serial} - DATA: {dayjs(new Date()).format('DD-MM-YYYY HH:mm:ss')}</Title>
                            { r.data?.devices?.map((d: any) => {
                                return (<Title level={5}>ID: {d?.id} - VALUE: {d?.values}</Title>)
                            })}
                        </>
                )})}
            </div>
        </List>
        );
};
