import React, {useContext, useEffect} from 'react';

import {ILoginForm, LayoutProps, useLogin, useNavigation} from '@refinedev/core';
import {AuthPage, ThemedTitle} from '@refinedev/antd';
import 'react-toastify/dist/ReactToastify.css';
import {    Row,
    Col,
    Layout as AntdLayout,
    Card,
    Typography,
    Form,
    Input,
    Button,
    Checkbox,} from 'antd';
import {useNavigate} from 'react-router-dom';
import {UtilityHelper} from '../../../helper/utility';
import {API_URL} from '../../../constants';
import {socket} from '../../../contexts/socket';
const { Text, Title } = Typography;

function useWindowDimensions() {
    const [width, setWidth] = React.useState(window.innerWidth);
    const [height, setHeight] = React.useState(window.innerHeight);

    const updateWidthAndHeight = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    };

    React.useEffect(() => {
        window.addEventListener("resize", updateWidthAndHeight);
        return () => window.removeEventListener("resize", updateWidthAndHeight);
    });

    return {
        width,
        height,
    }
}
export const Login: React.FC<LayoutProps> = ({
                                                  children,
                                                  Sider,
                                                  Header,
                                                  Footer,
                                                  OffLayoutArea,
                                              }) => {
    const [form] = Form.useForm<ILoginForm>();
    const { mutate: login, isSuccess } = useLogin<ILoginForm>();
    const { width, height } = useWindowDimensions();
    const navigation = useNavigate();
    const CardTitle = (
        <Title level={2} className="title" style={{
            textAlign: 'center',
            textShadow: '3px 3px 2px rgba(0,0,0,0.24)',
            color: 'black'}}>
            Area Riservata
        </Title>
    );
    const utilityHelper = UtilityHelper(API_URL + '/api');
    const doLogin = (values: any) => {
        login(
            values,
            {
                onSuccess: (data) => {
                    if (!data.success) {
                        // handle error
                        console.log(data.success);
                        navigation('/login')
                    } else {
                        console.log('OK LOGIN');
                        utilityHelper.setSocketId(socket.id).then();
                    }
                },
                onError: (error) => {
                    console.log('ERRORE: ', error)
                }
            },
        );
    }

    //let CardTitle;
    return (
        <AntdLayout
            style={{
                background: 'radial-gradient(circle, rgba(40,124,161,1) 16%, rgba(253,232,29,1) 76%, rgba(252,139,69,1) 100%)'
            }}
            className="layout">
            <Row gutter={24}
                justify="center"
                align="middle"
                style={{
                    height: height,
                    width: width
                }}
            >
                <Col xs={24} md={12} lg={12} sm={12} xl={6}>
                    {/*<div className="container">*/}
                        {/*<div className="imageContainer">*/}
                        {/*    <img src="/images/logo.png" alt="EspoChic" />*/}
                        {/*</div>*/}
                        <Card title={CardTitle}  headStyle={{ borderBottom: 0 }}
                              style={{ background: 'transparent', border: 'none'}}>
                            <Form<ILoginForm>
                                layout="vertical"
                                form={form}
                                onFinish={(values) => {
                                    doLogin(values);
                                }}
                                requiredMark={false}
                                initialValues={{
                                    remember: false,
                                }}
                            >
                                <Form.Item
                                    name="email"
                                    label="Username"
                                    rules={[{ required: true }]}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Username"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="password"
                                    label="Password"
                                    rules={[{ required: true }]}
                                    style={{ marginBottom: "12px" }}
                                >
                                    <Input.Password
                                        type="password"
                                        placeholder="●●●●●●●●"
                                        size="large"
                                    />
                                </Form.Item>
                                <div style={{ marginBottom: "12px" }}>
                                    <Form.Item
                                        name="remember"
                                        valuePropName="checked"
                                        noStyle
                                    >
                                        <Checkbox
                                            style={{
                                                fontSize: "12px",
                                            }}
                                        >
                                            Remember me
                                        </Checkbox>
                                    </Form.Item>

                                    <a
                                        style={{
                                            float: "right",
                                            fontSize: "12px",
                                            color: 'white'
                                        }}
                                        href="/forgot-password"
                                    >
                                        Password Dimenticata?
                                    </a>
                                </div>
                                <Button
                                    type="primary"
                                    size="large"
                                    htmlType="submit"
                                    block
                                >
                                    Entra
                                </Button>
                            </Form>
                        </Card>
                    {/*</div>*/}
                </Col>
            </Row>
        </AntdLayout>
    );
};
