import {IResourceComponentsProps, useCreate, useList, useTranslate} from '@refinedev/core';
import {List, useTable} from '@refinedev/antd';
import {Button, Col, Input, message, Row, Table} from 'antd';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {ISaleMovement, IStatusWareHouse} from '../../interfaces';
import {StoreContext} from '../../contexts/StoreContext';
import dayjs from 'dayjs';
import {CSVLink} from 'react-csv';
import * as XLSX from 'xlsx';
export const StockRiordinoLuminaria: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const {value} = useContext(StoreContext);
    const [store] = value;
    const d = new Date();
    const d1 = new Date();
    const fabbisogno = useRef(1);
    d.setHours(0,0,0,0);
    d1.setHours(0,0,0,0);
    const dOggi = d.setDate(d.getDate() + 1);
    const d10 = d1.setDate(d1.getDate() - 11);
    const {tableQueryResult} = useTable<ISaleMovement>({
        resource: "sale-movements",
        queryOptions: {
            enabled: false,
        },

        meta: {populate: 'seller, product, product.category'},

        pagination: {
            pageSize: 999999999,
            mode: "server"
        },

        filters: {
            mode: 'server',
            defaultBehavior: 'replace',
            permanent: [
                {
                    field: "seller.id",
                    operator: "eq",
                    value: store.id,
                },
                {
                    field: "createdAt",
                    operator: "between",
                    value: [dayjs(d10).format('YYYY-MM-DD'), dayjs(dOggi).format('YYYY-MM-DD')]
                },
            ]
        },

        sorters: {
            permanent: [
                {
                    field: 'product.description',
                    order: 'asc'
                }
            ]
        }
    });

    const {data} = useList({
        resource: 'stocks',

        meta: {
            populate: 'product, seller'
        },

        queryOptions: {
            enabled: true
        },

        pagination: {
          pageSize: 99999999,
            mode: 'server'
        },

        filters: [
            {
                field: 'seller][id]',
                operator: 'eq',
                value: store.id
            },
            {
                operator: "or",
                value: [
                    {
                        field: "isRadiato",
                        operator: "null",
                        value: true,
                    },
                    {
                        field: "isRadiato",
                        operator: "eq",
                        value: 0,
                    },
                ]
            },
        ]
    });

    const {refetch} = useList({
        resource: 'ware-house-positions',

        meta: {
            populate: 'stock, stock.product, seller'
        },

        queryOptions: {
            enabled: false
        },

        pagination: {
            pageSize: 99999999,
            mode: 'server'
        },

        filters: [
            {
                field: 'seller][id]',
                operator: 'eq',
                value: store.id
            },
            {
                field: 'dataOut',
                operator: 'null',
                value: true
            }
        ],
        sorters: [
            {
                field: 'position',
                order: 'asc'
            }
        ]
    });

    const [dataArray, setDataArray] = useState<any>([]);

    // SOMA QTA VENDUTE ULTIMI 10GG / 10 * 7gg / pzConf - giacenza

    const calcolo = () => {
        console.log("Ricalcolo...");
        refetch().then( (result: any) => {
            // let q = 0;
            const tempGroup: any = result.data?.data.reduce((r: any, a: any) => {
                r[a.stock.product.id] = [...(r[a.stock.product.id] || []), a];
                return r;
            }, {})

            let pos: any = [];
            for (let g in tempGroup) {
                pos.push({
                    position: tempGroup[g].map((a: any) => a.position),
                    qta: tempGroup[g].length,
                    id: Number(g)
                })
            }
            if (tableQueryResult && tableQueryResult.data?.data && tableQueryResult.data.data.length > 0) {
                const result: any = [];
                let index = 0;
                let gIndex = 0;
                tableQueryResult.data.data.reduce( (res: any, value: any) => {
                    if (data && data.data) {
                        index = data.data.findIndex((x: any) => x.product.id === value.product.id);
                        gIndex = pos.findIndex((x: any) => x.id === value.product.id);
                    }
                    // console.log(index, data?.data[index]?.isRadiato, data?.data[index]?.description, data?.data[index]?.id)
                    if (index > -1 ) {
                        if (!res[value.product.id]) {
                            res[value.product.id] = {
                                id: value.product.id,
                                codice: value.product.codice,
                                description: value.product.description,
                                price: value.product.price,
                                priceKg: value.product.priceKg,
                                giacenza: ((gIndex !== -1) ? pos[gIndex].qta: 0),
                                nrPezziStecca: data?.data[index]?.nrPezziStecca,
                                stockMinKg: data?.data[index]?.stockMinKg,
                                pesoFiscaleConf: data?.data[index]?.pesoFiscaleConf,
                                isGeneric: value.product.category.isGeneric,
                                isTabacco: value.product.category.isTabacco,
                                position: ((gIndex !== -1) ? pos[gIndex].position: null),
                                espositore: data?.data[index].scansiaPz,
                                qta: 0 };
                            result.push(res[value.product.id])
                        }
                        res[value.product.id].qta += value.qta;
                        const val = (data?.data[index].scansiaPz - (res[value.product.id].qta/10)*fabbisogno.current);
                        res[value.product.id].nRecord = (val <= -5) ? Math.ceil(Math.abs(val/data?.data[index]?.nrPezziStecca)) : 0;
                    }
                    return res;
                }, {});
                result.map((r: any) => {
                    // console.log(r);
                    const qtaR = Math.max(Math.ceil(((r['qta']/10)*fabbisogno.current)/r['nrPezziStecca']) - r['giacenza'], 0);
                    const mlp = (r['isGeneric']) ? 1 : r['pesoFiscaleConf'];
                    // console.log(r['isGeneric'], qtaR, mlp, r['pesoFiscaleConf'])
                    r.qtaRiordinare = Number(qtaR * mlp).toFixed(2);
                    r.totale = (r['isGeneric']) ? Number(r['pesoFiscaleConf']) * r.qtaRiordinare : Number(r['priceKg']) / (1 / r.qtaRiordinare);
                })
                // console.log(result);
                setDataArray(result)
            }
        })
    }
    useEffect(() => {
        calcolo();
    }, [tableQueryResult.data?.data])

    useEffect(() => {
        tableQueryResult.refetch().then();
    }, [store.id])

    const {data: statusWareHouse} = useList<IStatusWareHouse>({
        resource: "status-ware-houses",
        meta: {populate: 'seller, device, led'},

        queryOptions: {
            enabled: true
        },

        pagination: {
            mode: 'server',
            pageSize: 9999999
        },
        filters: [
            {
                field: "seller.id",
                operator: "eq",
                value: store.id,
            },
            {
                field: 'led][device_type][isLuminaria]',
                operator: 'eq',
                value: true
            }
        ]
    });

    const callLuminara = useCreate();
    const accendiLuminaria = () => {
        const data = dataArray.filter((x: any) => x.nRecord > 0);
        // console.log(data);
        for (let p of data) {
            console.log("Position: ", p.position);
            if (p.position && p.position.length > 0) {
                for (let c of p.position.slice(0, p.nRecord)) {
                    const position = c;
                    console.log("Position Split: ", position.split(/[a-zA-Z]/g));
                    const getModule = position.split(/[a-zA-Z]/g);
                    const getColumn = position.match(/[a-zA-Z]/g);
                    const nModulo = getModule[0];
                    const nPosition = getModule[1];
                    const nColumn = getColumn[0];
                    const findDevice: any = statusWareHouse?.data.find((d: any) => (d.device.deviceNumber === Number(nModulo) && String.fromCharCode(d.module + 65) === nColumn));
                    // const findDevice: any = statusWareHouse?.data.find((d: any) => (d.device.deviceNumber === Number(position.substring(0, 1)) && String.fromCharCode(d.module + 65) === position.substring(1, 2)));
                    console.log("Dispositivo Trovato: ", findDevice);
                    console.log("Dispositivo Number: ", nPosition);
                    console.log("Dispositivo Module: ", nModulo);
                    console.log("Dispositivo Colonna: ", nColumn);
                    if (findDevice) {
                        // const numeroCella = position.length <= 3 ? position.substring(2, 3) : position.substring(2, 4);
                        const numeroCella = nPosition;
                        console.log(numeroCella);
                        const serialeLed = findDevice?.led?.serial;
                        console.log('Seriale LED: ', serialeLed);
                        const findRiga = Math.floor(numeroCella/2) + 1;
                        let led = '';
                        setTimeout(() => {
                            for(let r of findDevice["riga" + findRiga].split(',')) {
                                console.log(String(r).padStart(4, '0'));
                                led += String(r).padStart(4, '0') + "," + (numeroCella % 2 === 0 ? 'S' : 'D') + ",1;";
                            }
                            console.log(led);
                            const nLed = String(findDevice["riga" + findRiga].split(',').length).padStart(6, '0');
                            console.log(nLed);
                            callLuminara.mutateAsync({
                                resource: 'custom/controlloLuminaria',
                                successNotification: false,
                                values: {
                                    comando: `[${serialeLed}|${nLed}|${led}]`,
                                    seller: store.id
                                }
                            }).then((res: any) => {
                                message.info("Dati inviati al Master LED!")
                            })
                        }, 1000)
                    }
                }
            }
        }
    }

    const changeFabbisogno = (value: any) => {
        // console.log(value);
        fabbisogno.current = value;
        setDataArray([])
        setTimeout(() => {
            calcolo();
        },600)
    }
    return (
        <>
                <List title={'Riordino IntelliLed'}>
                    <Table
                        size="small" dataSource={dataArray}
                        loading={dataArray && dataArray.length === 0}
                        key="id"
                        rowKey="id"
                        sticky={false}
                        scroll={{y: window.innerHeight - 293 + 'px'}}
                        pagination={false}
                        footer={(value) => {
                            const tot = (value && value.length > 0) ? value?.map((item: any) => item?.totale).reduce((prev, next) => prev + next).toFixed(2) : 0;
                            return <Row gutter={24}>
                                <Col span={12}>

                                </Col>
                                <Col span={12}>
                                    <div style={{
                                        textAlign: 'right',
                                        fontSize: 20
                                    }}>
                                        <Button
                                            type={'primary'}
                                            htmlType={'button'}
                                            onClick={() => accendiLuminaria()}>
                                            Accendi Led
                                        </Button>
                                    </div>

                                </Col>
                            </Row>
                        }}
                    >
                        <Table.Column
                            dataIndex="codice"
                            key="codice"
                            title="Codice"
                            responsive={['sm']}
                        />
                        <Table.Column
                            dataIndex="description"
                            key="description"
                            title={translate('pages.stock.description', 'Descrizione')}
                            responsive={['sm']}
                            filters={[
                                {
                                    text: 'Tabacchi',
                                    value: 'isTabacco',
                                },
                                {
                                    text: 'Gratta e Vinci',
                                    value: 'isGeneric',
                                }
                            ]}
                            onFilter={(value: any, record: any) => {
                                // setCategory(value);
                                return record[value]
                            }}
                        />
                        <Table.Column
                            dataIndex="nrPezziStecca"
                            key="nrPezziStecca"
                            title={translate('pages.stock.nrPezziStecca', 'Pezzo Stecca/Cf')}
                        />
                        <Table.Column
                            dataIndex="qta"
                            key="qta"
                            title={translate('pages.stock.stockMinKg', 'Qta Vendute')}
                            render={(value, record: any) => (
                                value
                            )}
                        />
                        <Table.Column
                            dataIndex="qta"
                            key="qta"
                            title={translate('pages.stock.stockMinKg', 'Media Qta Vendute')}
                            render={(value, record: any) => (
                                value/10
                            )}
                        />
                        <Table.Column
                            dataIndex="qta"
                            key="qta"
                            title={<><span>{translate('pages.stock.stockMinKg', 'Fabbisogno')}</span><Input style={{marginLeft: 10, width: 50}} defaultValue={fabbisogno.current}
                                                                                                             onChange={(e) => changeFabbisogno(e.target.value)}></Input><span> gg</span></>}
                            render={(value, record: any) => (
                                ((value/10)*fabbisogno.current).toFixed(1)
                            )}
                        />
                        <Table.Column
                            dataIndex="espositore"
                            key="espositore"
                            title={translate('pages.stock.espositore', 'Giacenza Espositore')}
                        />
                        <Table.Column
                            dataIndex="qta"
                            key="qta"
                            title={translate('pages.stock.qta', 'Cf/St da Riordinare')}
                            render={(value, record: any) =>
                                {
                                    const val = ((value/10)*fabbisogno.current);
                                    // // const val = (record.espositore - (Math.ceil((value/10))*fabbisogno.current)) / record['nrPezziStecca'];
                                    // if (val >= 5) {
                                    //     record.nRecord = 0;
                                    //     return(0)
                                    // } else {
                                    //     record.nRecord = Math.ceil(Math.abs(val)/record['nrPezziStecca']);
                                    //     return(Math.ceil(Math.abs(val)/record['nrPezziStecca']))
                                    // }
                                    const daOrd = (record.espositore - val);
                                    return((daOrd < -5) ? daOrd.toFixed(1) : 0)

                                }
                                // Math.max(Math.ceil(((value/10)*fabbisogno.current)/record['nrPezziStecca']) - record['giacenza'], 0)
                            }
                        />
                        <Table.Column
                            dataIndex="position"
                            key="position"
                            title={translate('pages.stock.posizione', 'Posizione')}
                            render={(value, record: any) => {
                                const fab = (record.qta/10)*fabbisogno.current;
                                const val = (record.espositore - (record.qta/10)*fabbisogno.current);
                                let nRecord;
                                if (val <= -5) {
                                    nRecord = Math.ceil(Math.abs(val/record['nrPezziStecca']));
                                } else {
                                    nRecord = 0;
                                }
                                return(
                                <>
                                    <Row>
                                        {value && value.slice(0, nRecord).map((p: any, index: number) => {
                                                return(
                                                    <>
                                                        <Col span={8}>
                                                            <div style={{
                                                                backgroundColor: (p) ? 'green' : 'red',
                                                                borderRadius: 5,
                                                                textAlign: 'center',
                                                                verticalAlign: 'middle',
                                                                height: 23,
                                                                width: 50,
                                                                boxShadow: '2px 2px 3px black'
                                                            }}>{p}
                                                            </div>
                                                        </Col>
                                                    </>
                                                )
                                        })}
                                    </Row>
                                </>)
                            }}
                        />
                    </Table>
                </List>

        </>
    );
};
