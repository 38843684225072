import {CrudFilters, IResourceComponentsProps, useCreate, useList, useTranslate} from '@refinedev/core';

import {
    DateField,
    List, TagField,
    useTable,
} from '@refinedev/antd';

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import {Button, Col, message, Row, Space, Table} from 'antd';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {StoreContext} from '../../contexts/StoreContext';
import "@ant-design/charts/dist/index.css";
import {Area} from '@ant-design/charts';

export const CheckRestockList: React.FC<IResourceComponentsProps> = () => {
    const {value, value2} = useContext(StoreContext);
    const [store, setStore] = value;
    const {tableProps, tableQueryResult} = useTable<any>({
        resource: "custom/checkRestocks",
        queryOptions: {
            enabled: true
        },
        filters: {
            initial: [
                {
                    field: 'seller][id]',
                    operator: 'eq',
                    value: store.id
                }
            ]
        }
    });

    const createRestock = useCreate();
    const handleCreate = (record: any) => {
        console.log(record);
        createRestock.mutateAsync({
            resource: 'restocks',
            values: {
                isRiassortito: false,
                description: record.description,
                qtaRiassortimento: 0,
                qtaRiassortita: 0,
                product: record.productId,
                stock: record.stockId,
                seller: store.id,
            }
        }).then( (res: any) => {
            //message.success('Restock Creato Correttamente')
            tableQueryResult.refetch();
        })
    }

    return (
            <List
                title={'Controllo Riassortimento'}
            >
                {/*<Row gutter={24}>*/}
                {/*    <Col span={24}>*/}
                {/*        { isSuccess &&*/}
                {/*            <Area {...config} />*/}
                {/*        }*/}
                {/*    </Col>*/}
                {/*</Row>*/}
                <Row gutter={24}>
                    <Col span={24}>
                        <Table {...tableProps}>
                            <Table.Column
                                dataIndex={'codice'}
                                title={'Codice'}
                                key={'codice'}
                            />
                            <Table.Column
                                dataIndex={'description'}
                                title={'Descrizione'}
                                key={'description'}
                            />
                            <Table.Column<{ id: string }>
                                title={'Actions'}
                                dataIndex="actions"
                                key="actions"
                                render={(_, record) => (
                                    <Space>
                                        <Button
                                            type={'primary'}
                                            size="small"
                                            htmlType={'button'}
                                            onClick={() => handleCreate(record)}
                                        >Crea Restock</Button>
                                    </Space>
                                )}
                            />
                        </Table>
                    </Col>
                </Row>
            </List>
    );
};
