import {stringify} from 'qs';
import { MeOptions } from "@refinedev/strapi-v4/src/helpers/auth";
import {IUser} from '../interfaces';
import {axiosInstance} from '../authProvider';
import {API_URL, TOKEN_KEY} from '../constants';
import jwt_decode from 'jwt-decode';

export const UtilityHelper = (apiUrl: string) => ({
    permission: async (options?: MeOptions) => {
        if (localStorage.getItem(TOKEN_KEY)) {
            const decoded: any = jwt_decode(localStorage.getItem(TOKEN_KEY) || '');
            return (decoded && decoded.group?.isAdmin) ? 'admin' : decoded.group?.isUser ? 'user' : decoded.group?.isOperator ? 'operator' : 'user'
        } else {
            return 'user';
        }

    },
    userDevice: () => {
        if (localStorage.getItem(TOKEN_KEY)) {
            const decoded: any = jwt_decode(localStorage.getItem(TOKEN_KEY) || '');
            return (decoded && decoded.device) ? decoded.device : []
        } else {
            return [];
        }

    },
    seller: async (options?: MeOptions) => {
        if (localStorage.getItem(TOKEN_KEY)) {
            const decoded: any = jwt_decode(localStorage.getItem(TOKEN_KEY) || '');
            return (decoded && decoded.seller) ? decoded.seller : []
        } else {
            return [];
        }

    },
    sellerId: (options?: MeOptions) => {
        if (localStorage.getItem(TOKEN_KEY)) {
            const decoded: any = jwt_decode(localStorage.getItem(TOKEN_KEY) || '');
            return (decoded && decoded.seller) ? decoded.seller.id : []
        } else {
            return [];
        }

    },
    logout: async (options?: MeOptions) => {
        const decoded: any = jwt_decode(localStorage.getItem(TOKEN_KEY) || '');
        await axiosInstance.put(API_URL + '/api/users/' + decoded.id, {
            isLogged: false,
            socketId: null
        });
        localStorage.removeItem(TOKEN_KEY);

    },
    setSocketId: async (socketId?: string) => {
        if (localStorage.getItem(TOKEN_KEY)) {
            const decoded: any = jwt_decode(localStorage.getItem(TOKEN_KEY) || '');
            if (decoded.isLogged) {
                console.log('Utente Gia Loggato: ', decoded.isLogged);
                await axiosInstance.put(API_URL + '/api/users/' + decoded.id, {
                    socketId: socketId,
                    isLogged: true
                });
            } else {
                await axiosInstance.put(API_URL + '/api/users/' + decoded.id, {
                    socketId: socketId,
                    isLogged: true
                });
                console.log("Update User Socket: ", socketId);
            }
            return true;
        }
    },
    getListino: async () => {
        let d: any = '';
        if (localStorage.getItem(TOKEN_KEY)) {
            const decoded: any = jwt_decode(localStorage.getItem(TOKEN_KEY) || '');
            const listino = await axiosInstance.get(API_URL + '/api/crons?filters[isImported][$eq]=1&pagination[pageSize]=500');
            if (listino && listino.data && listino.data.data.length > 0) {
                const i = Math.max(...listino.data.data.map((o: any) => o.id));
                d = listino.data.data.filter((x: any) => x.id === i);
            }
            console.log("Ultimo Listino: ", d[0].attributes.updatedAt);
            return d[0].attributes.updatedAt;
        }
    },
    checkUpdate: async () => {
        if (localStorage.getItem(TOKEN_KEY)) {
            const decoded: any = jwt_decode(localStorage.getItem(TOKEN_KEY) || '');
            const update = await axiosInstance.get(API_URL + '/api/users/' + decoded.id);
            if (update && update.status === 200) {
                return {isUpdate: update.data.isUpdate, id: update.data.id};

            }
        }
    },
    getAutoTurn: async () => {
        if (localStorage.getItem(TOKEN_KEY)) {
            const decoded: any = jwt_decode(localStorage.getItem(TOKEN_KEY) || '');
            const user = await axiosInstance.get(API_URL + '/api/users/' + decoded.id);
            if (user && user.status === 200) {
                return {autoTurn: user.data?.autoTurn};

            }
        }
    },
    switchOffUpdate: async () => {
        if (localStorage.getItem(TOKEN_KEY)) {
            const decoded: any = jwt_decode(localStorage.getItem(TOKEN_KEY) || '');
            const updateUser = await axiosInstance.put(API_URL + '/api/users/' + decoded.id, {
                isUpdate: false
            });
            return true;
        }
    },
});
