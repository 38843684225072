import {
    IResourceComponentsProps,
    useCustom,
    useCustomMutation,
    useExport,
    useList,
    useTranslate
} from '@refinedev/core';

import {
    BooleanField,
    CreateButton,
    DeleteButton,
    EditButton,
    ExportButton,
    List,
    TagField, useModalForm,
    useTable,
} from '@refinedev/antd';

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import {Button, Col, Form, Input, Modal, Row, Space, Switch, Table} from 'antd';
import React from "react";
import {IDeviceType, IEsl} from '../../interfaces';
import {API_URL} from '../../constants';


export const EslSettingList: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const {tableProps} = useTable<IEsl>({
        resource: "esl-settings",
    });

    const {
        modalProps: createModalProps,
        formProps: createFormProps,
        show: createModalShow,
    } = useModalForm<IEsl>({
        action: "create",
    });

    const {
        modalProps: editModalProps,
        formProps: editFormProps,
        show: editModalShow,
    } = useModalForm<IEsl>({
        action: "edit",
        warnWhenUnsavedChanges: true,
    });

    const {refetch} = useList({
        resource: 'custom/updateProductEsl',
        queryOptions: {
            enabled: false
        }
    });
    const updateEsl = () => {
        refetch().then((res) => {
            alert('Aggiornamento Inviato con Successo!')
        });
    }

    return (
        <>
            <List
                title={translate('pages.eslSetting.title', 'ESL Setting')}
                headerButtons={() => (<>
                    <Space>
                        <Button type={'primary'} style={{
                            backgroundColor: '#1e93e5'
                        }} icon={<Icons.ReconciliationOutlined />} onClick={() => {
                            updateEsl();
                        }}>Update Els Paper</Button>
                        <Button type={'primary'} icon={<Icons.PlusOutlined />} onClick={() => {
                        createModalShow();
                    }}>Create</Button>
                    </Space>
                </>)}
            >
                <Table size="small" {...tableProps} rowKey="id"
                       onRow={(record) => {
                           return {
                               onClick: () => {
                                   // console.log("deviceType", record);
                               },
                           };
                       }}
                       sticky={true}

                       pagination={{
                           ...tableProps.pagination,
                           showSizeChanger: true,
                       }}>
                    {/*<Table.Column dataIndex="id" key="id" title="ID"/>*/}
                    <Table.Column
                        dataIndex="url"
                        key="url"
                        title={translate('pages.eslSetting.url', 'Indirizzo Url API')}
                    />
                    <Table.Column
                        dataIndex="user"
                        key="user"
                        title={translate('pages.eslSetting.user', 'Nome Utente')}
                    />
                    <Table.Column
                        dataIndex="password"
                        key="password"
                        title={translate('pages.eslSetting.password', 'Password')}
                    />
                    <Table.Column
                        dataIndex="agencyId"
                        key="agencyId"
                        title={translate('pages.eslSetting.agencyId', 'Codice Agenzia')}
                    />
                    <Table.Column
                        dataIndex="merchantId"
                        key="merchantId"
                        title={translate('pages.eslSetting.merchantId', 'Codice Venditore')}
                    />
                    <Table.Column
                        dataIndex="isEnabled"
                        key="isEnabled"
                        title={translate('pages.eslSetting.isEnabled', 'Abilitato')}
                        render={(value) => (
                            <TagField value={value ? 'SI' : 'NO'} />
                        )}
                    />
                    <Table.Column<IEsl>
                        title={translate('pages.eslSetting.action', 'Action')}
                        dataIndex="actions"
                        render={(_, record) => (
                            <Space>
                                <EditButton
                                    hideText
                                    size="small"
                                    recordItemId={record.id}
                                    onClick={() => editModalShow(record.id)}
                                />
                                <DeleteButton
                                    mutationMode={'undoable'}
                                    hideText
                                    size="small"
                                    recordItemId={record.id}
                                />
                            </Space>
                        )}
                    />

                </Table>
            </List>
            <Modal {...createModalProps}>
                <Form {...createFormProps} layout="vertical">
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                label="Indirizzo Server API"
                                name="url"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="User Name"
                                name="user"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Password"
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item
                                label="Codice Agenzia"
                                name="agencyId"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Codice Venditore"
                                name="merchantId"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Abilitato"
                                name="isEnabled"
                                valuePropName={'checked'}
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Switch />
                            </Form.Item>
                        </Col>
                    </Row>

                </Form>
            </Modal>
            <Modal {...editFormProps}>
                <Form {...editFormProps} layout="vertical">
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                label="Indirizzo Server API"
                                name="url"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="User Name"
                                name="user"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Password"
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item
                                label="Codice Agenzia"
                                name="agencyId"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Codice Venditore"
                                name="merchantId"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Abilitato"
                                name="isEnabled"
                                valuePropName={'checked'}
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Switch />
                            </Form.Item>
                        </Col>
                    </Row>

                </Form>
            </Modal>
        </>

    );
};
