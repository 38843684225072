import {
    CrudFilters,
    IResourceComponentsProps,
    useCreate,
    useExport,
    useList,
    useNotification,
    useTranslate,
} from "@refinedev/core";

import {
    DeleteButton,
    EditButton,
    List,
    ShowButton,
    TagField,
    useModal,
    useModalForm,
    useSelect,
    useTable,
} from "@refinedev/antd";

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import {
    Button,
    Col,
    Dropdown,
    Form,
    Input,
    InputNumber, InputRef,
    MenuProps,
    Modal,
    Popover,
    Row,
    Select,
    Space,
    Table,
} from 'antd';

import React, {useContext, useEffect, useRef, useState} from 'react';
import {ICategory, IProduct, IRestock, IStock, IWareHousePosition} from '../../interfaces';
import {StoreContext} from '../../contexts/StoreContext';
import { useLocation, useNavigate } from "react-router-dom";
import {toast} from 'react-toastify';
import dismiss = toast.dismiss;
import {UtilityHelper} from '../../helper/utility';
import {API_URL} from '../../constants';
export const StockList: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const [isMounted, setIsMounted] = useState(false);
    const state: any = useLocation();
    const {value, value2} = useContext(StoreContext);
    const [store, setStore] = value;
    const {tableProps, searchFormProps, setFilters, filters, tableQueryResult, setSorters} = useTable<IStock>({
        resource: "stocks",
        meta: {populate: 'seller, product, product.category'},

        onSearch: (params: any) => {
            const filters: CrudFilters = [];

            filters.push(
                {
                    operator: "or",
                    value: [
                        {
                            field: "product][barcodes][code]",
                            operator: "eq",
                            value: (params?.description) ? params?.description : ''
                        },
                        {
                            field: "product][barcode_packages][code]",
                            operator: "eq",
                            value: (params?.description) ? params?.description : ''
                        },
                        {
                            field: "product][description]",
                            operator: "contains",
                            value: (params?.description) ? params?.description : ''
                        },
                        {
                            field: "product][codice]",
                            operator: "contains",
                            value: (params?.description) ? params?.description : ''
                        },
                        {
                            field: "product][category][description]",
                            operator: "contains",
                            value: (params?.description) ? params?.description : ''
                        },
                    ]
                },
                {
                    field: "product][category][id]",
                    operator: "contains",
                    value: (params?.category) ? params?.category : ''
                }
            );

            return filters;
        },
        pagination: {
            pageSize: 999,
            mode: "server"
        },

        filters: {
            permanent: [
                {
                    field: "seller.id",
                    operator: "eq",
                    value: store.id,
                },
            ]
        },

        sorters: {
            permanent: [
                {
                    field: 'product.description',
                    order: 'asc'
                }
            ]
        }
    });

    // SEARCH SIA PER DESCRIZIONE CHE PER BARCODE
    const [textSearch, setTextSearch] = useState<any>();
    const [tempSearch, setTempSearch] = useState<any>();
    const { selectProps, queryResult: productResult } = useSelect<IProduct>({
        resource: "products",
        optionLabel: "description",
        optionValue: "id",

        meta: {
            populate: "barcodes, barcode_packages"
        },

        queryOptions: {
            enabled: false
        },
        filters: [
            {
                operator: "or",
                value: [
                    {
                        field: "barcodes][code]",
                        operator: "eq",
                        value: textSearch
                    },
                    {
                        field: "barcode_packages][code]",
                        operator: "eq",
                        value: textSearch
                    },
                    {
                        field: "description]",
                        operator: "contains",
                        value: textSearch
                    }
                ]
            },
        ],

        pagination: {
            pageSize: 9999,
            mode: "server"
        },
    });

    const {
        modalProps: editModalProps,
        formProps: editFormProps,
        show: editModalShow,
    } = useModalForm<IStock>({
        action: "edit",
        meta: {populate: '*'},
        warnWhenUnsavedChanges: true,
    });

    const {triggerExport, isLoading: exportLoading} = useExport<IStock>();

    const [form] = Form.useForm();
    const [visible, setVisible] = useState(false);
    const handleVisibleChange = (newVisible: boolean) => {
        setVisible(newVisible);
    };

    const resetFilter = () => {
        searchFormProps.form?.resetFields()
        // console.log('Reset:', )
    };

    const { selectProps: categorySelectProps } = useSelect<ICategory>({
        resource: "categories",
        optionValue: 'id',
        optionLabel: 'description',

        pagination: {
            pageSize: 999999,
            mode: "server"
        }
    });

    const wareHousePosition = useList<IWareHousePosition>({
        resource: "ware-house-positions",
        meta: {populate: 'seller, stock, stock.product, stock.product.category'},

        queryOptions: {
            enabled: false
        },

        pagination: {
            pageSize: 9999,
            mode: "server"
        },

        filters: [
            {
                field: "seller.id",
                operator: "eq",
                value: store.id,
            },
            {
                field: "stock][giacenza]",
                operator: 'gt',
                value: 0
            },
            {
                field: "dataOut",
                operator: 'null',
                value: undefined
            }
        ],

        sorters: [
            {
                field: 'stock.description',
                order: 'asc'
            }
        ]
    });

    const [espositore, setEspositore] = useState(false);
    const [magazzino, setMagazzino] = useState(false);
    const [allineamento, setAllineamento] = useState(false);
    const inputElement: any = useRef(null) || '';
    const all = () => {
        setAllineamento(false);
        setMagazzino(false);
        setEspositore(false);
        setRiordinoStock(false);
        setRiordinoScientifico(false);
        setRiordinoMagazzino(false);
        if (state && state.state && state.state.filter) {
            state.state.filter = false;
        }
        setFilters(prevFilters => [...prevFilters.slice(0, -2)])
    }
    const nuovoArticolo = () => {
        setAllineamento(false);
        show();
    }
    const handleStock = () => {
        if (localStorage.getItem('pass') === 'Espo2023') {
            setMagazzino(false);
            setEspositore(false);
            setAllineamento(true);
            setRiordinoStock(false);
            setRiordinoScientifico(false);
            setRiordinoMagazzino(false);
            // console.log(filters)
            setFilters(prevFilters => [...prevFilters.slice(0, -2)])
            setTimeout(() => {
                inputElement?.current?.focus();
            }, 300)
        } else {
            showConfirm()
        }

    };
    const handleEspositore = () => {
        setEspositore(true);
        setMagazzino(false);
        setAllineamento(false);
        setRiordinoStock(false);
        setRiordinoScientifico(false);
        setRiordinoMagazzino(false);
        if (state && state.state && state.state.filter) {
            state.state.filter = false;
        }
        // console.log(filters)
        setFilters(prevFilters => [...prevFilters.slice(0, -2)])
        setFilters([{
            field: "scansiaPz",
            operator: "gt",
            value: 0
        }
        ])
        // searchFormProps.form?.submit();
    };

    const handleMagazzino = () => {
        wareHousePosition.refetch().then( res => {
            setMagazzino(true);
            setEspositore(false);
            setAllineamento(false);
            setRiordinoStock(false);
            setRiordinoScientifico(false);
            setRiordinoMagazzino(false);
            if (state && state.state && state.state.filter) {
                state.state.filter = false;
            }
            // console.log(filters)
            setFilters(prevFilters => [...prevFilters.slice(0, -1)])
            setFilters([{
                field: "giacenza",
                operator: "gt",
                value: 0
            }
            ])
        });
        // searchFormProps.form?.submit();
    };

    const [totEspositore, setTotEspositore] = useState<number>(0);
    const [totMagazzino, setTotMagazzino] = useState<number>(0);

    useEffect(() => {
        if (tableQueryResult && tableQueryResult.data && tableQueryResult.data.data.length > 0) {
            setTotEspositore(parseFloat(Number(tableQueryResult.data?.data.map(item => item.product?.price * item?.scansiaPz).reduce((prev, next) => prev + next)).toFixed(2)));
            if (wareHousePosition && wareHousePosition.data && wareHousePosition.data?.data?.length > 0) {
                setTotMagazzino(parseFloat(Number(wareHousePosition.data?.data.map(item => item?.stock?.product?.price * item?.stock?.nrPezziStecca).reduce((prev, next) => prev + next)).toFixed(2)));
            }
        } else {
            setTotEspositore(0);
            setTotMagazzino(0);
        }
        setAllineamento(state?.state?.filter ? true : allineamento ? allineamento : false);
        if (state && state.state && state.state.type === 3) {
            // handleStock();
        }
    }, [tableQueryResult.data?.data?.length]);

    const [riordinoScientifico, setRiordinoScientifico] = useState(false);
    const [riordinoStock, setRiordinoStock] = useState(false);
    const [riordinoMagazzino, setRiordinoMagazzino] = useState(false);
    const handleRiordinoScientifico = () => {
        setEspositore(false);
        setMagazzino(false);
        setAllineamento(false);
        setRiordinoScientifico(true);
        setRiordinoStock(false);
        setRiordinoMagazzino(false);
        // console.log(filters)
    };

    const handleRiordinoStock = () => {
        setEspositore(false);
        setMagazzino(false);
        setAllineamento(false);
        setRiordinoScientifico(false);
        setRiordinoStock(true);
        setRiordinoMagazzino(false);
        // console.log(filters)
    };

    const handleRiordinoMagazzino = () => {
        setEspositore(false);
        setMagazzino(false);
        setAllineamento(false);
        setRiordinoScientifico(false);
        setRiordinoStock(false);
        setRiordinoMagazzino(true);
        // console.log(filters)
    };

    const {
            modalProps: editModalAllineamentoProps,
            formProps: editFormAllineamentoProps,
            show: editModalAllineamentoShow,
    } = useModalForm<IStock>({
        action: "edit",
        meta: {populate: 'seller, product'},
    });

    const barcodeSearch = () => {
        searchFormProps.form?.submit();
        // console.log('Reset Barcode:', tableQueryResult.isSuccess);
        setTimeout(() => {
            tableQueryResult.refetch().then((res: any) => {
                // console.log(res);
                if (res && res.data && res.data?.data?.length === 1) {
                    // showConfirm(null, true, res.data?.data[0].id);
                    editModalAllineamentoShow(res.data?.data[0].id)
                }

            })
        }, 600)

    };

    const handleFocus = (event: any) => {
        event.target.select();
    };

    const { show, close, modalProps } = useModal();

    const [tempArticolo, setTempArticolo] = useState<any>([]);

    const { open, close: closeNotification } = useNotification();
    const handleSearch = (e: any) => {
            setTextSearch(tempSearch);
            setTimeout(() => {
                productResult.refetch().then( (res: any) => {
                    if (res && res.data && res.data.data.length === 1) {
                        // console.log(res.data.data);
                        const checkExists = tempArticolo.findIndex((x: any) => x.id === res.data?.data[0]?.id);
                        if (checkExists === -1) {
                            // console.log(checkExists);
                            // console.log(tempArticolo);
                            res.data.data[0].key = new Date().getTime();
                            //tempArticolo.sort((a: any, b: any) => Number(a.key) > Number(b.key));
                            setTempArticolo([...tempArticolo, res.data?.data[0]])
                        } else {
                            open?.({
                                type: "error",
                                message: "Articolo già presente nella lista",
                                description: "Attenzione!",
                            });
                        }

                    }
                })
                setTempSearch('')
            }, 500)
    };

    const navigate = useNavigate();
    const createStock = useCreate<IStock>();
    const {data: restockList} = useList<IRestock>({
        resource: 'restocks',

        queryOptions: {
            enabled: false
        },
        pagination: {
            pageSize: 99999,
            mode: "server"
        },
        meta: {
            populate: 'product, seller, stock'
        },

        filters: [
            {
                field: 'seller][id]',
                operator: 'eq',
                value: store.id
            }
        ]
    });

    const createRestock = useCreate<IRestock>();
    const saveAddArticolo = () => {
        tempArticolo.map((art: any) => {
            // console.log("CODICE:", art.codice)
            // console.log("ID: ", art.id)
            // console.log("TABLE: ", tableQueryResult)
            const idArticolo = tableQueryResult.data?.data.findIndex(x => x.product.id === art.id);
            // console.log('YYY: ', idArticolo);
            const idRestock = restockList?.data?.findIndex(x => x.product.id === art.id);
            // console.log('XXX: ', idRestock);
            if (idArticolo === -1) {
                createStock.mutateAsync({
                    resource: 'stocks',
                    successNotification: false,
                    values: {
                        description: art.description,
                        price: art.price,
                        nrPezziStecca: art.nrPezziStecca,
                        pesoFiscaleConf: art.pesoFiscaleConf,
                        product: art.id,
                        seller: store.id
                    }
                }).then( (res: any) => {
                    // console.log(res.data.data.id);
                    if (idRestock === -1) {
                        createRestock.mutateAsync({
                            resource: 'restocks',
                            successNotification: false,
                            values: {
                                description: art.description,
                                isRiassortito: false,
                                qtaRiassortimento: 0,
                                qtaRiassortita: 0,
                                product: art.id,
                                stock: res.data.data.id,
                                seller: store.id,
                            }
                        }).then( res => {
                            tableQueryResult.refetch()
                        })
                    }
                })
            }
        })
        setTempArticolo([]);
        close();
    }

    const delTempArticolo = (record: any) => {
        // console.log(record, tempArticolo);
        const newIndex = tempArticolo.findIndex((i: any) => i.codice === record.codice);
        tempArticolo.splice(newIndex, 1);
        setTempArticolo([...tempArticolo]);
    }

    const [detailCategoryEspositore, setDetailCategoryEspositore] = useState<any>([]);
    const [detailCategoryMagazzino, setDetailCategoryEspositoreMagazzino] = useState<any>([]);
    const updateTot = () => {
        // GROUP BY CATEGORY
        const tempGroup: any = tableQueryResult.data?.data.reduce((r: any, a: any) => {
            // console.log("a", a);
            // console.log("r", r);
            r[a.product.category.description] = [...(r[a.product.category.description] || []), a];
            return r;
        }, {})

        // console.log(tempGroup)
        let priceCategoryEspositore = [];
        let priceCategoryMagazzino = [];

        for (let g in tempGroup) {
            priceCategoryEspositore.push({
                qta: Number(tempGroup[g].map((item: any) => item.scansiaPz).reduce((prev: number, next: number) => prev + next)).toFixed(0),
                category: g,
                tot: parseFloat(Number(tempGroup[g].map((item: any) => item.product.price * item.scansiaPz).reduce((prev: number, next: number) => prev + next)).toFixed(2))
            })
            priceCategoryMagazzino.push({
                qta: Number(tempGroup[g].map((item: any) => item.giacenza).reduce((prev: number, next: number) => prev + next)).toFixed(0),
                category: g,
                tot: parseFloat(Number(tempGroup[g].map((item: any) => (item.product.price * item.nrPezziStecca) * item.giacenza).reduce((prev: number, next: number) => prev + next)).toFixed(2))
            })
        }
        setDetailCategoryEspositore(priceCategoryEspositore);
        setDetailCategoryEspositoreMagazzino(priceCategoryMagazzino);
    }

    useEffect(() => {
        updateTot();
    }, [tableQueryResult.isFetching])

    useEffect(() => {
        setIsMounted(true);
        return () => setIsMounted(false);
    }, [])


    const items: MenuProps['items'] = [
        {
            label: 'Referenze',
            key: '1',
            icon: <Icons.StockOutlined />,
        },
        {
            label: 'Nuovo Articolo',
            key: '2',
            icon: <Icons.StockOutlined />,
        },
        {
            label: 'Variazione',
            key: '3',
            icon: <Icons.StockOutlined />,
            danger: false,
        },
        {
            label: 'Magazzino',
            key: '4',
            icon: <Icons.StockOutlined />,
            danger: false,
        },
        {
            label: 'Espositore',
            key: '5',
            icon: <Icons.StockOutlined />,
            danger: false,
        },
    ];

    const handleMenuClick: MenuProps['onClick'] = (e) => {
        // console.log('click', e);
        switch (e.key) {
            case "1":
                all();
                break;
            case "2":
                nuovoArticolo();
                break;
            case "3":
                handleStock();
                // setAllineamento(true)
                break;
            case "4":
                handleMagazzino();
                break;
            case "5":
                handleEspositore();
                break;
            default:
                all();
                break;
        }
    };

    const menuProps = {
        items,
        onClick: handleMenuClick,
    };

    const { confirm } = Modal;
    const [password, setPassword] = useState<any>();
    const inputRef: any = useRef<InputRef>(null);
    const showConfirm = () => {
        confirm({
            title: 'Per effettuare la variazione è richiesta una password...',
            icon: <Icons.ExclamationCircleFilled />,
            content: <>
                <Input type={'password'} ref={inputRef} placeholder={'Password...'}></Input>
            </>,
            onOk() {
                if (inputRef.current.input.value === 'Espo2023') {
                    localStorage.setItem('pass', inputRef.current.input.value);
                    setMagazzino(false);
                    setEspositore(false);
                    setAllineamento(true);
                    setRiordinoStock(false);
                    setRiordinoScientifico(false);
                    setRiordinoMagazzino(false);
                    // console.log(filters)
                    setFilters(prevFilters => [...prevFilters.slice(0, -2)])
                    // setTimeout(() => {
                    //     inputElement?.current?.focus();
                    //     navigate('edit/' + record.id + '?allineamento=' + !allineamento)
                    // }, 300)
                };
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    return (
        <>
            <Modal {...modalProps}
                    width={'40%'}
                   title={'Inserimento Articolo'}
                   cancelText={'Annulla'}
                   okText={'Conferma'}
                   onOk={saveAddArticolo}>
                    <Row gutter={24}>
                        <Col span={24} style={{ marginBottom: 20}}>
                            <Input style={{
                                    width: "100%"
                                }}
                                       placeholder={"Codice a barre..."}
                                       autoFocus={true}
                                       onFocus={(e) => handleFocus(e)}
                                       onChange={event => setTempSearch(event.target.value)}
                                       onPressEnter={(e) => handleSearch(e)}
                                       allowClear={true}
                                       value={tempSearch}
                                />
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Table dataSource={tempArticolo}
                                   size={'small'}
                                   scroll={{y: '300px'}}
                                   pagination={false}>
                                <Table.Column
                                    dataIndex={'description'}
                                    key={'description'}
                                    title={'Articolo'}
                                    sorter={(a: any, b: any) => {
                                        return Number(a.key) - Number(b.key)}}
                                    defaultSortOrder={'descend'}
                                    />
                                <Table.Column
                                    dataIndex={'codice'}
                                    key={'codice'}
                                    title={'Codice'}
                                />
                                <Table.Column
                                    dataIndex={'actions'}
                                    key={'actions'}
                                    title={'Azioni'}
                                    render={(_, record, index) => (
                                        <Space>
                                            <Button
                                                icon={<Icons.DeleteOutlined />}
                                                size="small"
                                                onClick={() => delTempArticolo(record)}
                                            />
                                        </Space>
                                    )}
                                />
                            </Table>
                        </Col>
                    </Row>
            </Modal>
            <Modal {...editModalAllineamentoProps} width={'30vh'} title={''} cancelText={'Annulla'} okText={'Conferma'}>
                <Form {...editFormAllineamentoProps} layout="vertical">
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="scansiaPz"
                                label={'Pezzi Espositore'}
                            >
                                <InputNumber autoFocus={true} onFocus={(e) => handleFocus(e)} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
            <List
                title={translate('pages.stock.title', (espositore ? 'Espositore - Importo Totale:  € ' +
                    Number(parseFloat(totEspositore.toString()).toFixed(2)).toLocaleString('de', {
                        minimumFractionDigits: 2
                    }): allineamento ? 'Variazione' : magazzino ? 'Magazzino - Importo Totale:  € ' +
                    Number(parseFloat(totMagazzino.toString()).toFixed(2)).toLocaleString('de', {
                    minimumFractionDigits: 2
                }) : riordinoStock ? 'Riordino Merce in Stock' : riordinoScientifico ? 'Riordino Scientifico' : riordinoMagazzino ? 'Riordino Magazzino' : 'Referenze'))}
                headerProps={{
                    extra: [
                        <>
                            <Row>
                                <Col hidden={!allineamento}>
                                    <Form {...searchFormProps} layout="inline">
                                        <Form.Item name="description">
                                            <Input
                                                ref={inputElement}
                                                onFocus={(e) => handleFocus(e)}
                                                allowClear={true}
                                                placeholder="Barcode..."
                                                onPressEnter={barcodeSearch}/>
                                        </Form.Item>
                                    </Form>
                                </Col>
                                <Col style={{ marginRight: 10}}>
                                    <Dropdown menu={menuProps}>
                                        <Button type={'primary'} danger>
                                            <Space>
                                                Menu Merci
                                                <Icons.DownOutlined />
                                            </Space>
                                        </Button>
                                    </Dropdown>
                                </Col>

                                <Col style={{ marginRight: 10}} >
                                    <Popover
                                        placement="leftTop"
                                        style={{
                                            float: "right"
                                        }}
                                        content={
                                            <Form form={form} layout="horizontal" {...searchFormProps} labelCol={{ span: 8 }}
                                                  wrapperCol={{ span: 16 }}>
                                                <Form.Item label="Cerca" name="description">
                                                    <Input
                                                        allowClear={true}
                                                        placeholder="Cerca"
                                                        prefix={<Icons.SearchOutlined />}
                                                    />
                                                </Form.Item>
                                                <Form.Item label="Categoria" name="category">
                                                    <Select
                                                        {...categorySelectProps}
                                                        allowClear={true}
                                                        placeholder="Categoria"

                                                    />
                                                </Form.Item>
                                                <Form.Item>
                                                    <Space>
                                                        <Button htmlType="submit" type="primary" onClick={() => handleVisibleChange(false)}>
                                                            Filter
                                                        </Button>
                                                        <Button htmlType="button" type="primary" onClick={resetFilter}>
                                                            Reset
                                                        </Button>
                                                    </Space>

                                                </Form.Item>
                                            </Form>}
                                        title="Custom Filter"
                                        trigger="click"
                                        open={visible}
                                        onOpenChange={handleVisibleChange}
                                    >
                                        <Button type="primary" style={{marginBottom: 15}} hidden={magazzino}>Filter</Button>
                                    </Popover>
                                </Col>
                                {/*<Col style={{ marginRight: 10}}>*/}
                                {/*    <ExportButton*/}
                                {/*        onClick={triggerExport}*/}
                                {/*        loading={exportLoading}*/}
                                {/*    />*/}
                                {/*</Col>*/}
                            </Row>
                        </>
                    ],
                }}
            >
                { !magazzino && !riordinoStock && !riordinoScientifico && !riordinoMagazzino &&
                <Table size="small" {...tableProps}
                       key="id"
                       rowKey="id"
                       sticky={false}
                       scroll={{y: window.innerHeight - 293 + 'px'}}
                       pagination={false}
                       rowClassName={(record, index) => (record.isRadiato ? "red" : "")}
                       footer={data =>
                           detailCategoryEspositore.map((x: any) => {
                               return ( !magazzino && espositore && <>
                                   <TagField value={x?.category + ': ' + x.qta} color={'gold'}/>
                                   <span style={{
                                       marginRight: 15,
                                       color: 'coral'
                                   }}>{Number(parseFloat(x?.tot).toFixed(2)).toLocaleString('de', {
                                       minimumFractionDigits: 2
                                   })} €</span>
                               </>)
                           })
                       }
                >
                    {/*<Table.Column dataIndex="id" key="id" title="ID"/>*/}
                    <Table.Column
                        dataIndex="product"
                        key="product.codice"
                        title="Codice"
                        render={(value) => value?.codice}
                    />
                    <Table.Column
                        dataIndex="product"
                        key="product"
                        title={translate('pages.stock.product', 'Descrizione')}
                        render={(value) => value?.description}
                    />
                    { !magazzino && !espositore && !riordinoStock && !riordinoScientifico && !riordinoMagazzino &&
                    <Table.Column
                        dataIndex="product"
                        key="product"
                        title={translate('pages.stock.price', 'Prezzo')}
                        render={(value) => (
                            Number(parseFloat(value?.price).toFixed(2)).toLocaleString('de', {
                                minimumFractionDigits: 2
                            }) + " €"
                        )}
                    />
                    }
                    { !magazzino && !espositore && allineamento && !riordinoStock && !riordinoScientifico && !riordinoMagazzino &&
                        <Table.Column<IStock>
                            dataIndex="scansiaPz"
                            key="scansiaPz"
                            title={translate('pages.stock.scansiaPz', 'Pezzi')}
                            render={(value, data: any) => {
                                return value;
                            }}
                        />
                    }
                    { magazzino && !riordinoStock && !riordinoScientifico && !riordinoMagazzino &&
                        <>
                            <Table.Column
                                dataIndex="giacenza"
                                key="giacenza"
                                title={translate('pages.stock.giacenza', 'Magazzino')}
                                render={(value) => value}
                            />
                            <Table.Column
                                dataIndex="position"
                                key="position"
                                title={translate('pages.stock.position', 'Posizione')}
                                render={(value, record: any) => {
                                    const position: any = wareHousePosition.data?.data.find(w => w.stock.id === record.id);
                                    return position?.position
                                }
                                }
                            />
                            <Table.Column
                                dataIndex="totale"
                                key="totale"
                                title={translate('pages.stock.totale', 'Totale')}
                                render={(value, record: any) => {
                                    const totalPrice = record?.product?.price * record?.nrPezziStecca;
                                    return Number(parseFloat(totalPrice.toString()).toFixed(2)).toLocaleString('de', {
                                        minimumFractionDigits: 2
                                    }) + " €"
                                }
                                }
                            />
                        </>

                    }
                    { espositore && !riordinoStock && !riordinoScientifico && !riordinoMagazzino &&
                        <>
                            <Table.Column<IStock>
                                dataIndex="scansiaPz"
                                key="scansiaPz"
                                title={translate('pages.stock.scansiaPz', 'Pezzi')}
                                render={(value, data: any) => {
                                    return value;
                                }}
                            />
                            <Table.Column
                                dataIndex="product"
                                key="product"
                                title={translate('pages.stock.price', 'Prezzo')}
                                render={(value) => (
                                    Number(parseFloat(value?.price).toFixed(2)).toLocaleString('de', {
                                        minimumFractionDigits: 2
                                    }) + " €"
                                )}
                            />
                            <Table.Column
                                dataIndex="totale"
                                key="totale"
                                title={translate('pages.stock.totale', 'Totale')}
                                render={(value, record: any) => {
                                    const totalPrice = record?.product?.price * record?.scansiaPz;
                                    return Number(parseFloat(totalPrice.toString()).toFixed(2)).toLocaleString('de', {
                                        minimumFractionDigits: 2
                                    }) + " €"
                                }
                                }
                            />
                        </>
                    }
                    <Table.Column<{ id: string }>
                        title={translate('pages.users.action', 'Azioni')}
                        dataIndex="actions"
                        render={(_, record) => (
                            <Space>
                                <ShowButton
                                    hideText
                                    size="small"
                                    recordItemId={record.id}
                                    onClick={() => editModalShow(record.id)}
                                />
                                <EditButton hidden={!allineamento}
                                    hideText
                                    size="small"
                                    onClick={() => navigate('edit/' + record.id + '?allineamento=' + !allineamento)}
                                    recordItemId={record.id + '?allineamento=' + !allineamento}
                                />
                                <DeleteButton hidden={!allineamento}
                                    mutationMode={'undoable'}
                                    hideText
                                    size="small"
                                    recordItemId={record.id}
                                />
                            </Space>
                        )}
                    />
                </Table>
                }
                { magazzino && !riordinoStock && !riordinoScientifico && !riordinoMagazzino &&
                <Table size="small" dataSource={wareHousePosition.data?.data}
                       key="id"
                       rowKey="id"
                       sticky={false}
                       scroll={{y: window.innerHeight - 293 + 'px'}}
                       pagination={false}
                       footer={data =>
                           detailCategoryMagazzino.map((x: any) => {
                               return (<>
                                   <TagField value={x?.category + ': ' + x.qta} color={'gold'}/>
                                   <span style={{
                                       marginRight: 15,
                                       color: 'coral'
                                   }}>{Number(parseFloat(x?.tot).toFixed(2)).toLocaleString('de', {
                                       minimumFractionDigits: 2
                                   })} €</span>
                               </>)
                           })
                       }
                >
                    <Table.Column
                        dataIndex="stock"
                        key="stock.product.codice"
                        title="Codice"
                        render={(value) => {
                            return value?.product?.codice}}
                    />
                    <Table.Column
                        dataIndex="stock"
                        key="stock.product.description"
                        title={translate('pages.stock.product', 'Descrizione')}
                        render={(value) => value?.product?.description}
                        filters={[
                            {
                                text: 'Tabacchi',
                                value: 'isTabacco',
                            },
                            {
                                text: 'Gratta e Vinci',
                                value: 'isGeneric',
                            }
                        ]}
                        onFilter={(value: any, record: any) => {
                            return record?.stock?.product?.category[value];
                        }}
                    />
                    <Table.Column
                        dataIndex="position"
                        key="position"
                        title={translate('pages.stock.position', 'Posizione')}
                        render={(value, record: any) => {
                            return value
                        }
                        }
                    />
                    <Table.Column
                        dataIndex="stock"
                        key="totale"
                        title={translate('pages.stock.totale', 'Totale')}
                        render={(value, record: any) => {
                            const totalPrice = value?.product?.price * value?.nrPezziStecca;
                            return Number(parseFloat(totalPrice.toString()).toFixed(2)).toLocaleString('de', {
                                minimumFractionDigits: 2
                            }) + " €"
                        }
                        }
                    />
                </Table>
                }
            </List>
            <Modal>

            </Modal>
            <Modal {...editModalProps} footer={<></>} title={'Dettaglio'}>
                <Form {...editFormProps} layout="vertical">
                    <Row gutter={24}>
                        <Col span={16}>
                            <Form.Item
                                label="Prodotto"
                                name={["product","description"]}
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input readOnly={true}/>
                            </Form.Item>
                        </Col>
                        <Col span={6} hidden={true}>
                            <Form.Item
                                label="Qta"
                                name="qta"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input readOnly={true}/>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                label="Magazzino"
                                name="giacenza"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input readOnly={true}/>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                label="Espositore"
                                name="scansiaPz"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input readOnly={true}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6} hidden={true}>
                            <Form.Item
                                label="Scansia N.ro"
                                name="scansiaNr"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input readOnly={true}/>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                label="Min. Espositore"
                                name="scansiaStock"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input readOnly={true}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Stock Min. Riordino Kg/Conf"
                                name="stockMinKg"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input readOnly={true}/>
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Form.Item
                                label="Aggio %"
                                name="aggio"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input readOnly={true}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Pezzi per Confezione"
                                name="nrPezziStecca"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input readOnly={true}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Peso Fisc. Kg/€ Cf."
                                name="pesoFiscaleConf"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input readOnly={true}/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
            {/*<div hidden={!riordinoStock}>*/}
            {/*    <StockRiordino></StockRiordino>*/}
            {/*</div>*/}
            {/*<div hidden={!riordinoScientifico}>*/}
            {/*    <StockRiordinoScientifico></StockRiordinoScientifico>*/}
            {/*</div>*/}
            {/*<div hidden={!riordinoMagazzino}>*/}
            {/*    <StockMagazzino></StockMagazzino>*/}
            {/*</div>*/}

        </>
    );
};
