// export const FRONT_URL = "http://localhost:3000";
export const FRONT_URL = "https://dics.espochic.com/";
// export const FRONT_URL = "http://192.168.1.26/";
// export const FRONT_URL = "http://192.168.100.100/";
// export const API_URL = "http://localhost:1337";
export const API_URL = "https://dics.espochic.com/api";
// export const API_URL = "http://192.168.1.26/api";
// export const API_URL = "http://192.168.100.100/api";
// export const SOCKET_URL = "http://localhost:1337";
export const SOCKET_URL = "https://dics.espochic.com";
// export const SOCKET_URL = "http://192.168.1.26";
// export const SOCKET_URL = "http://192.168.100.100";
export const TOKEN_KEY = "strapi-jwt-token";
export const VERSION = "1.0.68"

