import React, {useEffect, useState} from 'react';
import { IResourceComponentsProps, useCreate, useMany, useModal, useUpdate } from "@refinedev/core";
import { Edit, useForm, useSelect, EditButton, DeleteButton, useModalForm } from "@refinedev/antd";

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import {
    Form,
    Input,
    Select,
    Row,
    Col,
    Table,
    Space,
    Button,
    Modal,
    AutoComplete,
    Typography,
    Popover, Switch,
} from 'antd';

import {IDevice, IGroup, ISeller, IUser} from 'interfaces';
const { Title } = Typography;
export const SellerEdit: React.FC<IResourceComponentsProps> = () => {
    const {formProps, saveButtonProps, queryResult, formLoading, id} = useForm<ISeller>({
        meta: {populate: 'devices, users_permissions_users, users_permissions_users.group'},
    });

    const [usersTable, setUsersTable] = useState<any>([]);
    const [devicesConnect, setDevicesConnect] = useState<any>([]);
    const [selectedUSer, setSelectedUser] = useState<any>([]);
    const [userDisconnect, setUserDisconnect] = useState<any>([]);
    const [deviceDisconnect, setDeviceDisconnect] = useState<any>([]);

    const sellerCreate = useUpdate<ISeller>();

    const { visible, show, close } = useModal();
    const showUsers = () => {
        if (visible) {
            close()
        } else {
            show()
        }

    };

    const { selectProps: usersSelectProps, queryResult: userSelectResult } = useSelect<IUser>({
        resource: "users",
        meta: {populate: 'group'},
        optionValue: 'id',
        optionLabel: 'email',

        pagination: {
            mode: "server"
        }
    });

    // Create Modal
    const {
        modalProps: createModalProps,
        formProps: createFormProps,
        show: createModalShow,
        queryResult: userCreated,
    } = useModalForm<IUser>({
        resource: "users",
        action: "create",
        redirect: false,
        onMutationSuccess: (data, variables, context) => {
            setUsersTable((usersTable: any) => [...usersTable, data.data])
            // console.log(usersTable);
        }
    });

    // Edit Modal
    const {
        modalProps: editModalProps,
        formProps: editFormProps,
        show: editModalShow,
    } = useModalForm<IUser>({
        resource: "users",
        meta: {populate: 'group'},
        action: "edit",
        warnWhenUnsavedChanges: true
    });

    const { selectProps: groupSelectProps } = useSelect<IGroup>({
        resource: "groups",
        optionValue: 'id',
        optionLabel: 'description',

        pagination: {
            mode: "server"
        }
    });

    const onDeleteUser = (data: any) => {
        const d = queryResult?.data?.data.users_permissions_users.filter(item => item.id !== data);
        setUsersTable(d);
        setUserDisconnect([queryResult?.data?.data.users_permissions_users.find((obj) => obj.id === data)]);

    }

    useEffect(() => {
        setUsersTable(queryResult?.data?.data.users_permissions_users);
        setDevicesConnect(queryResult?.data?.data.devices);
        const sed: any = [];
        const t = queryResult?.data?.data?.devices?.map(({ id }: { id: number }) => id)
        console.log(t)
        formProps.form?.setFieldValue(["devices"],t)

    }, [formLoading])

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
        const u = userSelectResult.data?.data.find((u: any) => u.id === Number(selectedUSer))
        setUsersTable((usersTable: any) => [...usersTable, userSelectResult.data?.data.find((u: any) => u.id === Number(selectedUSer))])
    };

    const handleDevices = (e: any) => {
        setDevicesConnect([...devicesConnect, listDevices.data?.data.find(x => x.id === e)]);
        setDeviceDisconnect(deviceDisconnect.filter((el: any) => { return el.id != e}))
    }

    const deselectDevices = (e: any, d: any) => {
        setDeviceDisconnect([...deviceDisconnect, listDevices.data?.data.find(x => x.id === e)]);
        setDevicesConnect(devicesConnect.filter((el: any) => { return el.id != e}))
    }

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const { selectProps: deviceSelectProps, queryResult: listDevices } = useSelect<IDevice>({
        resource: "devices",
        optionValue: 'id',
        optionLabel: 'description',

        pagination: {
            pageSize: 99999,
            mode: "server"
        }
    });

    return (
        <Edit saveButtonProps={saveButtonProps}>
            <Form
                {...formProps}
                layout="vertical"
                onFinish={(values: any) => {
                    values.description = values.company;
                    values['users_permissions_users'] = {
                        connect: usersTable,
                        disconnect: userDisconnect
                    };
                    // values['devices'] = {
                    //     connect: devicesConnect,
                    //     disconnect: deviceDisconnect
                    // };
                    // console.log(values);
                    sellerCreate.mutate({
                        resource: 'sellers',
                        id: Number(queryResult?.data?.data.id),
                        values: values
                    })
                    }
                }
            >
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col span={8}>
                        <Form.Item
                            label="Company"
                            name="company"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Name"
                            name="name"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Surname"
                            name="surname"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col span={4}>
                        <Form.Item
                            label="City"
                            name="city"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Address"
                            name="address"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Zip"
                            name="zip"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Country"
                            name="country"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col span={8}>
                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Phone"
                            name="phone"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Mobile"
                            name="mobile"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col span={4}>
                        <Form.Item
                            label="Piva"
                            name="piva"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Cf"
                            name="cf"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item
                            label="Store ID"
                            name="storeId"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Luminaria"
                            name="isLuminaria"
                            valuePropName={'checked'}
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Switch />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Luminaria Auto Off"
                            name="isLuminariaAutoOff"
                            valuePropName={'checked'}
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Switch />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Default Timer Auto Off"
                            name="defaultTimerLuminariaOff"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Select options={[
                                {label: '1m', value: 1},
                                {label: '2m', value: 2},
                                {label: '3m', value: 3},
                                {label: '4m', value: 4},
                                {label: '5m', value: 5},
                                {label: '6m', value: 6},
                                {label: '7m', value: 7},
                                {label: '8m', value: 8},
                                {label: '9m', value: 9},
                                {label: '10m', value: 10},
                                {label: '13m', value: 13},
                                {label: '15m', value: 15},
                                {label: '20m', value: 20}
                            ]} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={4}>
                        <Form.Item
                            label="Alert Scarico"
                            name="isAlertScarico"
                            valuePropName={'checked'}
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Switch />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Alert Crichi Contemporanei"
                            name="isAlertCarichiContemporanei"
                            valuePropName={'checked'}
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Switch />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Alert Riassortimento"
                            name="isAlertRiassortimento"
                            valuePropName={'checked'}
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Switch />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Alert Carico"
                            name="isAlertCarico"
                            valuePropName={'checked'}
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Switch />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Alert Carico Abuse"
                            name="isAlertCaricoAbuse"
                            valuePropName={'checked'}
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Switch />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Alert Carico Patentino"
                            name="isAlertCaricoPatentino"
                            valuePropName={'checked'}
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Switch />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={4}>
                        <Form.Item
                            label="Alert Carico Non Permesso"
                            name="isAlertCaricoNotLoad"
                            valuePropName={'checked'}
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Switch />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Alert Carico Temp"
                            name="isAlertCaricoTemp"
                            valuePropName={'checked'}
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Switch />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Alert Articolo Finito"
                            name="isSendArticoloFinito"
                            valuePropName={'checked'}
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Switch />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Alert Riassortimento"
                            name="isSendRiassortimento"
                            valuePropName={'checked'}
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Switch />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Alert Sottoscorta"
                            name="isSendSottoscorta"
                            valuePropName={'checked'}
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Switch />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item
                            label="Alert Multiplo 10"
                            name="isSendMultiplo10"
                            valuePropName={'checked'}
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Switch />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item
                            label="Alert Articolo Mancante"
                            name="isSendArticoloMancante"
                            valuePropName={'checked'}
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Switch />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={18}>
                        <Form.Item
                            label="Device"
                            name={"devices"}
                            // getValueProps={(ids) => {
                            //     return ids?.map(({ id }: { id: number }) => id);
                            // }}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select {...deviceSelectProps} allowClear={true} showSearch={true} mode={'multiple'} />
                        </Form.Item>
                    </Col>
                    <Col span={3}>
                        <Form.Item
                            label="Telegram ID"
                            name="telegramId"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={3}>
                        <Form.Item
                            label="Password Page"
                            name="password"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Row gutter={24}>
                <Col span={24}>
                        <Popover title="Show Users">
                            <Button type={'primary'} icon={<Icons.OrderedListOutlined/>} onClick={showUsers} style={{ marginBottom: 10, marginRight: 10}} />
                        </Popover>
                        <Popover title="Create Users">
                            <Button type={'default'} icon={<Icons.PlusOutlined/>} onClick={() => createModalShow()} style={{ marginBottom: 10, marginRight: 10}}/>
                        </Popover>
                        <Popover title="Add Users">
                            <Button type={'default'} icon={<Icons.UserOutlined/>} onClick={showModal} style={{ marginBottom: 10}}/>
                        </Popover>
                    <Modal {...createModalProps}>
                        <Form {...createFormProps} layout="vertical">
                            <Row gutter={24}>
                                    <Col span={8}>
                                        <Form.Item hidden={true}
                                                   label="Role"
                                                   name="role"
                                                   initialValue={1}
                                        >
                                            <Input value={1}/>
                                        </Form.Item>
                                        <Form.Item
                                            label="Email"
                                            name="email"
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            label="Nome"
                                            name="name"
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            label="Cognome"
                                            name="surname"
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={6}>
                                    <Form.Item
                                        label="UserName"
                                        name="username"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Input/>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item
                                        // wrapperCol={{span: 14}}
                                        label="Password"
                                        name="password"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Input/>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    {/*<Form.Item*/}
                                    {/*    label="Stato Utente"*/}
                                    {/*    name="confirmed"*/}
                                    {/*>*/}
                                    {/*    <Select*/}
                                    {/*        options={[*/}
                                    {/*            {*/}
                                    {/*                label: "ABILITATO",*/}
                                    {/*                value: true,*/}
                                    {/*            },*/}
                                    {/*            {*/}
                                    {/*                label: "DISABILITATO",*/}
                                    {/*                value: false,*/}
                                    {/*            },*/}
                                    {/*        ]}*/}
                                    {/*        defaultValue={true}*/}
                                    {/*    />*/}
                                    {/*</Form.Item>*/}
                                    <Form.Item hidden={true}
                                               label="Confirmed"
                                               name="confirmed"
                                               initialValue={1}
                                    >
                                        <Input/>
                                    </Form.Item>
                                    <Form.Item
                                        label="Telegram ID"
                                        name="telegramId"
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                    >
                                        <Input/>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item
                                        label="Group"
                                        name="group"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Select {...groupSelectProps}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Modal>
                    <Modal {...editModalProps}>
                        <Form {...editFormProps} layout="vertical">
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        label="Email"
                                        name="email"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Input/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={6}>
                                    <Form.Item
                                        label="UserName"
                                        name="username"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Input/>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item
                                        // wrapperCol={{span: 14}}
                                        label="Password"
                                        name="password"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Input/>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item
                                        label="Stato Utente"
                                        name="confirmed"
                                    >
                                        <Select
                                            options={[
                                                {
                                                    label: "ABILITATO",
                                                    value: true,
                                                },
                                                {
                                                    label: "DISABILITATO",
                                                    value: false,
                                                },
                                            ]}
                                            defaultValue={true}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item
                                        label="Group"
                                        name="group"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Select {...groupSelectProps}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Modal>
                    <Modal title="Select User" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Select
                                    allowClear={true}
                                    placeholder="Select User"
                                    onSelect={(u) => setSelectedUser(u)}
                                    style={{ width: '100%' }}
                                    {...usersSelectProps} />
                            </Col>
                        </Row>
                    </Modal>
                    {visible && (
                        <Table
                            dataSource={usersTable}
                            key={Math.random()}>
                            <Table.Column
                                dataIndex="id"
                                title="ID"
                                render={(value) => {
                                    return (
                                        value
                                    );
                                }}
                            />
                            <Table.Column
                                dataIndex="email"
                                title="Email"
                                render={(value) => {
                                    return (
                                        value
                                    );
                                }}
                            />
                            <Table.Column
                                dataIndex="username"
                                title="Username"
                                render={(value) => {
                                    return (
                                        value
                                    );
                                }}
                            />
                            <Table.Column<{ id: string }>
                                title="Actions"
                                render={(_, record) => (
                                    <Space>
                                        <EditButton
                                            hideText
                                            size="small"
                                            recordItemId={record.id}
                                            onClick={() => editModalShow(record.id)}
                                        />
                                        <Button
                                            icon={<Icons.DeleteOutlined />}
                                            size="small"
                                            onClick={() => onDeleteUser(record.id)}
                                        />
                                    </Space>
                                )}
                            />
                        </Table>
                    )}
                </Col>
            </Row>
        </Edit>
    );
};
