import {
    IResourceComponentsProps,
    useCreate,
    useCustom, useDelete,
    useGetIdentity,
    useList,
    useNotification,
    useOne,
    useUpdate,
} from '@refinedev/core';

import { Create, DateField, DeleteButton, useForm, useSelect, useTable } from "@refinedev/antd";

import {
    Button,
    Col,
    DatePicker,
    Divider,
    Form,
    Input,
    InputNumber,
    notification,
    Row,
    Select,
    Space,
    Spin,
    Switch,
    Table,
    Typography,
} from 'antd';

import {
    ICausal,
    IDocType,
    IGroup,
    IParameter, IPatent, IPatentMovement, IPatentMovementRow,
    IProduct,
    ISaleMovement, IStock, IWareHousePosition,
    IWhareHouseMovement,
    IWhareHouseMovementRow
} from 'interfaces';
import React, {useContext, useEffect, useRef, useState} from 'react';
import dayjs from 'dayjs';
import {StoreContext} from '../../contexts/StoreContext';
import { useNavigate } from "react-router-dom";
import {socket, SocketContext} from '../../contexts/socket';

export const PatentMovementCreate: React.FC<IResourceComponentsProps> = () => {
    const inputElement: any = useRef(null) || '';
    const {value, value2} = useContext(StoreContext);
    const [store, setStore] = value;
    const socket = useContext(SocketContext);
    const {open, close} = useNotification();
    const navigate = useNavigate();
    const [openRow, setOpenRow] = useState<boolean>(false);
    const [showSave, setShowSave] = useState<boolean>(false);
    const [totale, setTotale] = useState(0);
    const [totalExt, setTotalExt] = useState(-1);
    const [textSearch, setTextSearch] = useState<any>();
    const {data: userIdentity} = useGetIdentity<any>();
    const {formProps, saveButtonProps} = useForm<IWhareHouseMovement>({});
    const [docType, setDocType] = useState();
    const [idProgressive, setIdProgressive] = useState<any>();
    const [idMovement, setIdMovement] = useState<any>();
    const [tempSearch, setTempSearch] = useState<any>();
    const [autoFocus, setAutoFocus] = useState(false);
    const [stop, setStop] = useState(false);
    const getProgressiveNumberDoc = useList<IParameter>({
        resource: 'parameters',

        queryOptions: {
            enabled: false
        },

        filters: [
            {
                field: 'doc_type][id]',
                operator: 'eq',
                value: docType
            },
            {
                field: 'seller][id]',
                operator: 'eq',
                value: store.id
            }
        ]
    })

    const {selectProps: docTypeSelectProps, queryResult} = useSelect<IDocType>({
        resource: 'doc-types',
        optionValue: 'id',
        optionLabel: 'description',

        filters: [
            {
                field: 'seller][id]',
                operator: 'eq',
                value: store.id
            }
        ],

        pagination: {
            mode: "server"
        }
    });

    const {selectProps: patentSelectProps} = useSelect<IPatent>({
        resource: 'patents',
        optionValue: 'id',
        optionLabel: 'company',

        filters: [
            {
                field: 'seller][id]',
                operator: 'eq',
                value: store.id
            }
        ],

        pagination: {
            mode: "server"
        }
    });

    const onSelect = (e: any) => {
        setDocType(e);
        setTimeout(() => {
            getProgressiveNumberDoc.refetch().then(result => {
                // console.log(result);
                setIdProgressive(Number(result.data?.data[0].id));
                if (result.isSuccess && result.data.data.length > 0)
                    formProps.form?.setFieldValue('docNumber', Number(result.data?.data[0]?.progressive) + 1);
            })
        }, 1000)

    }

    const savePatentMovement = useCreate<IPatentMovement>();
    const updatePatentMovement = useUpdate<IPatentMovement>();
    const updateProgressive = useUpdate<IParameter>();
    const onConfirm = (data: any) => {
        // console.log(data);
                    savePatentMovement.mutateAsync({
                        resource: 'patent-movements',
                        successNotification: false,
                        values: {
                            description: 'Vendita Patentino',
                            doc_type: data.doc_type,
                            loadDate: data.loadDate,
                            docNumber: data.docNumber,
                            patent: data.patent,
                            isPatent: true,
                            seller: store.id,
                            user: userIdentity.id
                        }
                    }).then((r: any) => {
                        // console.log('Movement: ', r);
                        setIdMovement(r.data.data.id);
                        updateProgressive.mutate({
                            successNotification: false,
                            resource: 'parameters',
                            id: idProgressive,
                            values: {
                                progressive: formProps.form?.getFieldValue('docNumber')
                            }
                        })
                        setTimeout(() => {
                            setOpenRow(true);
                            setTimeout(() => {
                                inputElement?.current?.focus();
                            }, 600)
                            setTotale(0);
                        }, 1000);

                    });

    }

    const onSave = () => {
                updatePatentMovement.mutateAsync({
                    resource: 'patent-movements',
                    successNotification: false,
                    id: idMovement,
                    values: {
                        isPatent: false,
                        total: totale
                    }
                }).then(rrr => {
                    navigate('/ptn/patent-movements');
                });
    }

    const updateStock = useUpdate<IStock>();
    const deletePatentMovementRow = useDelete<IPatentMovementRow>();
    const onDelete = (record: IPatentMovementRow) => {
        deletePatentMovementRow.mutateAsync({
            resource: 'patent-movement-rows',
            successNotification: false,
            id: Number(record.id),
        }).then(rrr => {
            updateStock.mutateAsync({
                successNotification: false,
                resource: 'stocks',
                id: record.stock.id,
                values: {
                    scansiaPz: record.stock.scansiaPz + record.stock.nrPezziStecca
                }
            }).then(r => {
                open?.({
                    type: 'success',
                    message: 'Il prodotto eliminato viene automaticamente caricato in Scansia...',
                    description: 'Attenzione!',
                });
            })
        });
    }

    const {tableProps, tableQueryResult} = useTable<IPatentMovementRow>({
        resource: 'patent-movement-rows',
        queryOptions: {
            enabled: false
        },
        meta: {
            populate: 'seller, product, stock, patent-movement'
        },
        pagination: {
            pageSize: 1000,
            mode: 'server'
        },
        filters: {
            permanent: [
                {
                    field: 'patent_movement][id]',
                    operator: 'eq',
                    value: idMovement
                },
                {
                    field: 'seller][id]',
                    operator: 'eq',
                    value: store.id
                }
            ]
        },
        sorters: {
            initial: [
                {
                    field: 'createdAt',
                    order: 'desc'
                }
            ]
        }
    });

    useEffect(() => {
        if (idMovement) {
            tableQueryResult.refetch();
        }
     }, [idMovement]);

    useEffect(() => {
        const d: any = queryResult.data?.data.find((x: any) => x.description.includes('U88'));
        formProps.form?.setFieldValue('doc_type', d?.id);
        onSelect(d?.id);
    }, [queryResult.data?.data])


    const updateTot = () => {
        if (tableQueryResult.isSuccess && tableQueryResult.data.data.length > 0) {
            // setTotale(parseFloat(Number(tableQueryResult.data?.data.map(item => item?.product?.price * (Math.round(1000 / (item?.product?.priceKg / item?.product?.price)) / 2)).reduce((prev, next) => prev + next)).toFixed(2)));
            setTotale(parseFloat(Number(tableQueryResult.data?.data.map(item => ((item?.price) - ((item?.price) / 100) * item?.aggio)).reduce((prev, next) => prev + next)).toFixed(2)));

        } else {
            setTotale(0);
        }
        if (formProps.form?.getFieldValue('totalExt') === totale) {
            setShowSave(true);
        } else {
            setShowSave(false);
        }
    }

    useEffect(() => {
        updateTot();
    })

    useEffect(() => {
        socket.on('alertRigaPatentino', async (data: any, error: any) => {
            console.log('PATENTINO:', data);
            tableQueryResult.refetch();
        });
        return () => {
            socket.off('alertRigaPatentino');
        };
    }, [])

    const {Title} = Typography;

    const handleFocus = (event: any) => {
        event.target.select();
    };

    return (
        <Create
            footerButtons={
            <Space>
                <Button type={'primary'} htmlType={'submit'} onClick={onSave} >Salva</Button>
            </Space>

        }>
            <Form
                {...formProps}
                layout="vertical"
                onFinish={onConfirm}
            >
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            label="Patentino"
                            name="patent"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select {...patentSelectProps}/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Tipo"
                            name="doc_type"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select {...docTypeSelectProps} onSelect={onSelect}/>
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item
                            label="N. Interno"
                            name="docNumber"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Data"
                            name="loadDate"
                            getValueProps={(value) => ({
                                value: value ? dayjs(value) : '',
                            })}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <DatePicker style={{width: '100%'}} format={'DD-MM-YYYY'}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Action"
                            name="buttonAction"
                        >
                            <Button style={{width: '100%'}} type={'primary'} htmlType={'submit'}
                                    disabled={idMovement > 0}>Conferma</Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <div hidden={!openRow}>
                <Divider/>
                <Row gutter={24}>
                    <Col span={24}>
                        <Table {...tableProps}
                               pagination={false}
                               scroll={{y: 400}}
                        >
                            <Table.Column
                                dataIndex="product"
                                key="product.codice"
                                title="Codice"
                                render={(value) => value?.codice}
                            />
                            <Table.Column
                                dataIndex="product"
                                key="product_desc"
                                title="Descrizione"
                                render={(value) => value?.description}
                            />
                            <Table.Column
                                dataIndex="price"
                                key="price"
                                title="Price"
                                render={(value, record: IPatentMovementRow) => {
                                    return Number(parseFloat(value).toFixed(2)).toLocaleString('de', {
                                        minimumFractionDigits: 2
                                    }) + ' €'
                                }}
                            />
                            <Table.Column
                                dataIndex="aggio"
                                key="aggio"
                                title="Aggio %"
                            />
                            <Table.Column
                                title={'Actions'}
                                dataIndex="actions"
                                render={(_, record: IPatentMovementRow) => (
                                    <Space>
                                        <DeleteButton
                                            onClick={() => onDelete(record) }
                                            size="small"
                                        />
                                    </Space>
                                )}
                            />
                        </Table>
                    </Col>
                </Row>
            </div>
            <div hidden={!openRow}>
                <Divider/>
                <Title level={3} style={{textAlign: 'right'}}>Stecche Prelevate: {tableQueryResult.data?.data?.length} - Totale: {totale} €</Title>
            </div>
        </Create>
    );
};
