import {CrudFilters, IResourceComponentsProps, useExport, useList, useTranslate} from '@refinedev/core';

import {
    DateField,
    List,
    useTable,
} from '@refinedev/antd';

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import {Button, Col, Form, Input, InputRef, Modal, Popover, Row, Select, Space, Spin, Table, Typography} from 'antd';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {IProduct, IProductUpdate, IStock, IWhareHouseMovement} from '../../interfaces';
import dayjs from 'dayjs';
import {StoreContext} from '../../contexts/StoreContext';
import { jsPDF } from "jspdf";
import {UtilityHelper} from '../../helper/utility';
import {API_URL} from '../../constants';
const {Title} = Typography;

export const VeryPaperList: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const [veryPaper, setVeryPaper] = useState<any>([]);
    const {value, value2} = useContext(StoreContext);
    const [store, setStore] = value;
    const {tableProps, tableQueryResult} = useTable<IProductUpdate>({
        resource: "product-updates",
        pagination: {
            pageSize: 9999999,
            mode: 'server'
        },
    });

    const {data: dataStock, refetch} = useList<IStock>({
        resource: "stocks",
        meta: {populate: 'seller, product, product.category'},
        queryOptions: {
          enabled: false
        },
        pagination: {
            pageSize: 9999999,
            mode: "server"
        },
        filters: [
                {
                    field: "seller.id",
                    operator: "eq",
                    value: store.id,
                },
            ]

    });

    const { confirm } = Modal;
    const inputRef: any = useRef<InputRef>(null);
    const [showVeryPaper, setShowVeryPaper] = useState(false);
    const utilityHelper = UtilityHelper(API_URL + "/api");

    useEffect(() => {
        utilityHelper.seller().then( (res: any) => {
            if (localStorage.getItem('pass') === res.password) {
                handleVeryPaper().then();
            } else {
                showConfirm(res.password);
            }
        });
    }, []);

    const print = (data: any, isAll: boolean, onlyPrice: boolean) => {
        console.log(data);
        let doc = new jsPDF("p", "mm", "a4");
        const dataPrint = isAll ? dataStock?.data.filter((c: any) => c.product.category.description !== 'Gratta e Vinci') : data.children;
        if (isAll) {
            data = { print : true};
        }
            if (data.print) {
                console.log("Stampa Tutto: ", dataPrint);
                let pageNumber = 1;
                let index = 0;
                let x = 0;
                let y = 0;
                let s = 1;
                for (let d of dataPrint) {
                    const price = isAll ? d.product.price : d.newPrice;
                    const codice = isAll ? d.product.codice : d.codice;
                    const dataUpdate = isAll ? d.product.updatedAt : d.dataUpdate;
                    if (index === 0) {
                        x = 20;
                        y = 21;
                        doc.setFontSize(8);
                        doc.text('pag. ' + pageNumber, 160,285);
                    } else {
                        if (index % 2 === 0) {
                            // console.log("Modulo 2: ", s, " index: ", index)
                            x = x + 57.7;
                            s = s + 2;
                        } else {
                            x = x + 57.7;

                        }

                        if (index % 3 === 0) {
                            // console.log("Modulo 3: ", s, " index: ", index)
                            x = 20;
                            y = y + 42.2;
                            s = s + 2;
                        }

                        if (index % 18 === 0) {
                            // NEW PAGE
                            doc.addPage();
                            pageNumber++;
                            doc.text('pag. ' + pageNumber, 160,285);
                            x = 20;
                            y = 21;
                            // s = 1;
                        }
                    }

                    // doc.setDrawColor(0);
                    // doc.rect(x, y, 57, 42, "F");
                    // doc.setDrawColor(0);
                    // doc.setFillColor(255, 0, 0);
                    // doc.rect(x+6.5, y + 6.5, 44, 14.5, "F");
                    // doc.setTextColor(255);
                    // doc.setFontSize(14);
                    // doc.setFont('Arial', 'bold');
                    // doc.text(d.description.toString().slice(0, 13), x+7, y + 11.5);
                    // doc.text(d.description.toString().slice(13, 26), x+7, y + 18);
                    // doc.rect(x+6.5, y + 21.1, 44, 14.5, "F");
                    // doc.setTextColor(0);
                    // doc.setFontSize(28);
                    // doc.text("€", x+9, y + 30);
                    // doc.text(parseFloat(price.toString()).toFixed(2).replace(/\$\s?|(\.)/g, ','), x+24, y + 30);
                    // doc.setFontSize(8);
                    // doc.text(codice.toString(), x+9, y + 34.7);
                    // doc.text(dayjs(dataUpdate).format("DD/MM/YYYY").toString(), x+34, y + 34.7);
                    // doc.line(x+8, y + 32, x+50, y + 32);

                    doc.setDrawColor(0);
                    doc.rect(x, y, 57, 40, "F");
                    doc.setDrawColor(0);
                    doc.setFillColor(255, 0, 0);
                    doc.rect(x+3.5, y + 4.5, 49, 14.5, "F");
                    doc.setTextColor(255);
                    doc.setFontSize(14);
                    doc.text(onlyPrice ? '' : d.description.toString().slice(0, 15), x+5, y + 10.5);
                    doc.text(onlyPrice ? '' : d.description.toString().slice(15, 28), x+5, y + 18);
                    doc.rect(x+3.5, y + 19.1, 49, 15.5, "F");
                    doc.setTextColor(0);
                    doc.setFontSize(28);
                    doc.text(onlyPrice ? '' : "€", x+8, y + 28);
                    doc.text(parseFloat(price.toString()).toFixed(2).replace(/\$\s?|(\.)/g, ','), x+23, y + 28);
                    doc.setFontSize(8);
                    doc.text(onlyPrice ? '' : codice.toString(), x+7, y + 32.7);
                    doc.text(onlyPrice ? '' : dayjs(dataUpdate).format("DD/MM/YYYY").toString(), x+33, y + 32.7);
                    index++;
                }

                doc.save("very_paper_" + dayjs(data.dataUpdate).format("DD/MM/YYYY").toString() + ".pdf");
            } else {
                // console.log("Stampa: ", data);
                // doc.setDrawColor(0);
                // doc.rect(20, 20, 57, 42, "F");
                // doc.setDrawColor(0);
                // doc.setFillColor(255, 0, 0);
                // doc.rect(27, 26.5, 44, 14.5, "F");
                // doc.setTextColor(255);
                // doc.setFontSize(14);
                // doc.text(data.description.toString().slice(0, 13), 27, 31.5);
                // doc.text(data.description.toString().slice(13, 26), 27, 38);
                // doc.rect(27, 41.1, 44, 14.5, "F");
                // doc.setTextColor(0);
                // doc.setFontSize(28);
                // doc.text("€", 29, 50);
                // doc.text(parseFloat(data.newPrice.toString()).toFixed(2).replace(/\$\s?|(\.)/g, ','), 44, 50);
                // doc.setFontSize(8);
                // doc.text(data.codice.toString(), 29, 54.7);
                // doc.text(dayjs(data.dataUpdate).format("DD/MM/YYYY").toString(), 54, 54.7);
                // doc.line(28, 52, 70, 52);

                // OK
                doc.setDrawColor(0);
                doc.rect(20, 21, 57, 40, "F");
                doc.setDrawColor(0);
                doc.setFillColor(255, 0, 0);
                doc.rect(24, 25.5, 49, 14.5, "F");
                doc.setTextColor(255);
                doc.setFontSize(14);
                doc.text(onlyPrice ? '' : data.description.toString().slice(0, 15), 25, 32.5);
                doc.text(onlyPrice ? '' : data.description.toString().slice(15, 28), 28, 38);
                doc.rect(24, 40.1, 49, 15.5, "F");
                doc.setTextColor(0);
                doc.setFontSize(28);
                doc.text(onlyPrice ? '' : "€", 29, 49);
                doc.text(parseFloat(data.newPrice.toString()).toFixed(2).replace(/\$\s?|(\.)/g, ','), 44, 49);
                doc.setFontSize(8);
                doc.text(onlyPrice ? '' : data.codice.toString(), 29, 53.7);
                doc.text(onlyPrice ? '' : dayjs(data.dataUpdate).format("DD/MM/YYYY").toString(), 54, 53.7);
                doc.save("very_paper_" + dayjs(data.dataUpdate).format("DD/MM/YYYY").toString() + ".pdf");
            }
    }

    const getData = async (data: any) => {
        console.log("Ciclo: ", data);
        return new Promise(resolve => {
            let dataVeryPaper = [];
            for (let g in data) {
                console.log("Data: ", g);
                let productUpdate: any = [];
                data[g].map((r: any) => {
                    productUpdate.push({description: r.description, codice: r.codice, price: r.price, newPrice: r.newPrice, category: r.category, dataUpdate: r.dataUpdate})
                });
                dataVeryPaper.push({
                    id: Math.random(),
                    print: true,
                    description: 'Nuovo Listino del: ' + dayjs(productUpdate[0].dataUpdate).format("DD/MM/YYYY"),
                    dataUpdate: productUpdate[0].dataUpdate,
                    children: productUpdate
                });
            }
            resolve(dataVeryPaper);
        })
    }
    const handleVeryPaper = async () => {
        const resData = await tableQueryResult.refetch();
        const stock: any = await refetch();
        console.log(stock);
        const tempGroup: any = resData.data?.data.filter((c: any) => c.category !== 'Gratta e Vinci').reduce((r: any, a: any) => {
            const indexP: any = stock.data?.data.findIndex((p: any) => p.product.codice === a.codice);
            if (indexP > -1) {
                r[a.dataUpdate] = [...(r[a.dataUpdate] || []), a];
            }
            return r;
        }, {});
        setVeryPaper(await getData(tempGroup));
    }
    const showConfirm = (pass: any) => {
        confirm({
            title: 'Per effettuare la variazione è richiesta una password...',
            icon: <Icons.ExclamationCircleFilled />,
            content: <>
                <Input type={'password'} ref={inputRef} placeholder={'Password...'}></Input>
            </>,
            onOk() {
                // console.log("PASS:" ,pass + "INPUT: ", inputRef.current.input.value)
                if (inputRef.current.input.value === pass) {
                    localStorage.setItem('pass', inputRef.current.input.value);
                    handleVeryPaper().then()
                } else {
                    showConfirm(pass);
                }
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    return (
        <>
            <List
                title={translate('pages.product.title', 'VeryPaper')}
                headerProps={{
                    extra: [
                        <>
                            <Button type={'primary'} style={{
                                backgroundColor: 'green'
                            }} onClick={() => print(null, true, false)}>Stampa Tutto</Button>
                        </>
                    ],
                }}
            >
                <Table size="small"
                       {...tableProps}
                       dataSource={veryPaper}
                       rowKey="id"
                       scroll={{y: window.innerHeight - 293 + 'px'}}
                       loading={veryPaper.length === 0}
                       onRow={(record) => {
                           return {
                               onClick: () => {
                                   // console.log("group", record);
                               },
                           };
                       }}
                       sticky={true}

                       pagination={false}>
                    {/*<Table.Column dataIndex="id" key="id" title="ID"/>*/}
                    <Table.Column
                        width={350}
                        dataIndex="description"
                        key="description"
                        title={translate('pages.group.description', 'Description')}
                    />
                    <Table.Column
                        width={100}
                        dataIndex="codice"
                        key="codice"
                        title={translate('pages.group.codice', 'Codice AMS')}
                        render={(value) => (
                            value
                        )}
                    />
                    <Table.Column
                        width={100}
                        dataIndex="price"
                        key="price"
                        title={translate('pages.group.price', 'Prezzo')}
                        render={(value) => (
                            value
                        )}
                    />
                    <Table.Column
                        width={150}
                        dataIndex="newPrice"
                        key="newPrice"
                        title={translate('pages.group.newPrice', 'Nuovo Prezzo')}
                        render={(value) => (
                            value
                        )}
                    />
                    <Table.Column
                        width={100}
                        dataIndex="category"
                        key="category"
                        title={translate('pages.group.category', 'Categoria')}
                        render={(value) => (
                            value
                        )}
                    />
                    <Table.Column
                        width={100}
                        dataIndex="dataUpdate"
                        key="dataUpdate"
                        title={translate('pages.group.dataUpdate', 'Data Listino')}
                        render={(value, record: IProductUpdate, index) => {
                            return (
                                <DateField value={record?.dataUpdate} format={'DD/MM/YYYY'}></DateField>
                            )
                        }}
                    />
                    <Table.Column<{ id: string }>
                        width={100}
                        title={translate('pages.users.action', 'Azioni')}
                        dataIndex="actions"
                        render={(_, record) => (
                            <Button
                                size="small"
                                htmlType={'button'}
                                type={'primary'}
                                onClick={() => {print(record, false, false)}}
                            >Stampa Variazione</Button>
                        )}
                    />

                </Table>
            </List>
        </>
    );
};
