import React, {useContext, useEffect, useState} from 'react';

import {Authenticated, Refine} from '@refinedev/core';
import {ErrorComponent, ThemedLayout, RefineThemes} from '@refinedev/antd';

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from '@ant-design/icons';

import '@refinedev/antd/dist/reset.css';
// import 'react-toastify/dist/ReactToastify.css';
import './styles.css';
import {useTranslation} from 'react-i18next';
import {
    Header,
    CustomSider
} from 'components/layout';
import {authProvider, axiosInstance} from './authProvider';
import {API_URL, SOCKET_URL, TOKEN_KEY} from './constants';
import {Login} from './components/layout/login';
import {CreateUser, UserEdit, UsersList} from './pages/users';
import {GroupCreate, GroupEdit, GroupList} from './pages/groups';
import {SellerCreate, SellerEdit, SellerList} from './pages/sellers';
import {adapter, model} from './accessControl';
import {newEnforcer} from 'casbin';
import {ProductCreate, ProductEdit, ProductList} from './pages/products';
import {UtilityHelper} from './helper/utility';
import {notificationProvider} from './notificationProvider';

import {DataProvider} from './dataProvider';
import {StoreContext, StoreProvider} from './contexts/StoreContext';
import {DashboardList} from './pages/dashboard';
import {VenditaList} from './pages/venditas';
import {DeviceCreate, DeviceEdit, DeviceList} from './pages/devices';
import {DeviceTypeCreate, DeviceTypeEdit, DeviceTypeList} from './pages/device-types';
import {CausalCreate, CausalEdit, CausalList} from './pages/causals';
import {WhareHouseMovementCreate, WhareHouseMovementEdit, WhareHouseMovementList} from './pages/whare-house-movements';
import {DocTypeCreate, DocTypeEdit, DocTypeList} from './pages/doc-types';
import {ParameterCreate, ParameterEdit, ParameterList} from './pages/parameters';
import {socket, SocketContext} from './contexts/socket';
import {DeviceProvider} from './contexts/DeviceContext';
import {ToastContainer} from 'react-toastify';
import {CategoryCreate, CategoryEdit, CategoryList} from './pages/categories';
import {TurnContext, TurnProvider} from './contexts/TurnContext';
import {TurnCreate, TurnEdit, TurnList, TurnShow} from './pages/turns';
import {toast} from 'react-toastify';
import {
    CheckRemotoList,
    ReportGraficoUlpList,
    ReportList,
    ReportMovimentazioniList,
    ReportSnapshotList
} from './pages/report';
import {StockRiordino} from './pages/stocks/riordino';
import {StockRiordinoScientifico} from './pages/stocks/riordino-scientifico';
import {PatentCreate, PatentEdit, PatentList} from './pages/patents';
import {PatentMovementList} from './pages/patent-movements';
import {PatentMovementCreate} from './pages/patent-movements';
import {PatentMovementEdit} from './pages/patent-movements';
import {BrowserRouter, Outlet, Route, Routes} from 'react-router-dom';
import routerProvider, {CatchAllNavigate, NavigateToResource, UnsavedChangesNotifier} from '@refinedev/react-router-v6';
import {ConfigProvider, message, theme} from 'antd';
import {StockMagazzino} from './pages/stocks/riordino-mag';
import {CreateStock} from './pages/stocks/create-stock';
import {EslSettingList} from './pages/esl-settings';
import {ForgotPasswordPage, UpdatePasswordPage} from './components/pages/auth/components';
import jwt_decode from 'jwt-decode';
import {ReferenzeStock} from './pages/stocks';
import {MagazzinoStock} from './pages/stocks/magazzino';
import {EspositoreStock} from './pages/stocks/espositore';
import {RestockEdit, RestockList} from './pages/restocks';
import {ReportGraficoList} from './pages/report';
import {CheckRestockList} from './pages/restocks/check-restock';
import io from 'socket.io-client';
import {VeryPaperList} from './pages/stocks/very-paper';
import {VeryPaperAdminList} from './pages/stocks/very-paper-admin';
import {StockRiordinoLuminaria} from './pages/stocks/riordino-luminaria';

function App() {
    // const socket = io(SOCKET_URL);
    // const socket = io(SOCKET_URL, {
    //     'reconnection': true,
    //     'reconnectionDelay': 500,
    //     'reconnectionAttempts': 10
    // });
    const [isConnected, setIsConnected] = useState(socket.connected);
    const {t, i18n} = useTranslation();
    const utilityHelper = UtilityHelper(API_URL + '/api');
    // const {open, close} = useNotification();
    const [currentTheme, setCurrentTheme] = useState<'light' | 'dark'>('dark');
    const i18nProvider = {
        translate: (key: string, params: object) => t(key, params),
        changeLocale: (lang: string) => i18n.changeLanguage(lang),
        getLocale: () => i18n.language,
    };

    setInterval( () => {
        utilityHelper.checkUpdate().then((data: any) => {
            if (data) {
                if (data.isUpdate) {
                    message.info("Rilevato Aggiornamento...Cancellazione Cache in Corso!!!")
                    setTimeout(() => {
                        utilityHelper.switchOffUpdate().then((r: any) => {
                            caches.keys().then((names) => {
                                names.forEach((name) => {
                                    caches.delete(name);
                                });
                            });
                            localStorage.clear();
                            window.location.reload()
                        })
                    }, 3000);
                }
            }
        });
    }, 30000)

    useEffect(() => {
        if (socket.id) {
            utilityHelper.setSocketId(socket.id).then();
        }
        socket.on('connect', () => {
            console.log('Connesso: ', socket.id);
            // setIsConnected(true);
            utilityHelper.setSocketId(socket.id).then();
        });

        socket.on('disconnect', () => {
            console.log('Disconnesso', isConnected)
            setIsConnected(false);
        });

        // socket.on('sendResponse', async (data: any) => {
        //     console.log('Risposta Controllo: ', data.data);
        //     if (!data.data) {
        //         console.log("Aggiorno Socket: ", socket.id);
        //         utilityHelper.setSocketId(socket.id).then();
        //     }
        // });

        socket.on('alertScarico', async (data: any, error: any) => {
            console.log(data);
            toast('E\' stato prelevato un prodotto dal Magazzino...');
        });

        socket.on('alertCarichiContemporanei', async (data: any, error: any) => {
            console.log(data);
            if (data?.seller?.isAlertCarichiContemporanei) {
                toast('Sono Stati Rilevati carichi contemporanei...');
            }
        });

        socket.on('alertRiassortimento', async (data: any, error: any) => {
            console.log(data);
            if (data?.seller?.isAlertRiassortimento) {
                toast('E stata prelevata una stecca per riassortimento dalla posizione: ' + data?.position);
            }
        });

        socket.on('alertCarico', async (data: any, error: any) => {
            console.log(data);
            if (data?.seller?.isAlertCarico) {
                toast.error('E stata prelevata una stecca dalla posizione: ' + data?.position + ' Attenzione - Riassortimento Anticipato!', {
                    position: "top-right",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    theme: 'colored'
                });
            }

        });
        socket.on('alertCaricoAbuse', async (data: any, error: any) => {
            console.log(data);
            if (data?.seller?.isAlertCaricoAbuse) {
                toast('E stata prelevata una stecca dalla posizione: ' + data?.position + ' il Box era occupato Abusivamente - Nessun movimento è stato generato!');
            }
        });
        socket.on('alertCaricoPatentino', async (data: any, error: any) => {
            console.log(data);
            if (data?.seller?.isAlertCaricoPatentino) {
                toast('E stata prelevata una stecca dalla posizione: ' + data?.position + ' per Vendita Patentino!');
            }
        });
        socket.on('alertCaricoNotLoad', async (data: any, error: any) => {
            console.log(data);
            if (data?.seller?.isAlertCaricoNotLoad) {
                toast('Inserita una Stecca nel box: \'' + data.position + '\' non in fase di carico')
            }
        });

        socket.on('alertCaricoTemp', async (data: any, error: any) => {
            console.log(data);
            if (data?.seller?.isAlertCaricoTemp) {
                toast('Prelevata una Stecca dal Box: \'' + data.position + ' DDT Salvato Temporaneamente - Riposizionare la stecca!')
            }
        });

        socket.on('sendArticoloFinito', async (data: any, error: any) => {
            console.log(data);
            if (data?.seller?.isSendArticoloFinito) {
                toast('Articolo non presente in Scansia \nQuantità disponibile in Magazzino: ' + data.giacenza + ' Stecca ' + data.product + ' Barcode: ' + data.barcode)
            }
        });

        socket.on('sendRiassortimento', async (data: any, error: any) => {
            console.log(data);
            if (data?.seller?.isSendRiassortimento) {
                toast('Devi Effettuare il Riassortimento! - Articolo: \'' + data.product + ' Barcode: ' + data.barcode)
            }
        });

        socket.on('sendSottoscorta', async (data: any, error: any) => {
            console.log(data);
            if (data?.seller?.isSendSottoscorta) {
                toast('Attenzione stai andando sottoscorta ' + data.pz + ' Pacchetti! Consiglio il Riassortimento: \'' + data.product + ' Barcode: ' + data.barcode)
            }
        });

        socket.on('sendMultiplo10', async (data: any, error: any) => {
            console.log(data);
            if (data?.seller?.isSendMultiplo10) {
                toast('Attenzione hai Venduto altri ' + data.pz + ' Pacchetti! Consiglio il Riassortimento: \'' + data.product + ' Barcode: ' + data.barcode)
            }
        });

        socket.on('sendArticoloMancante', async (data: any, error: any) => {
            console.log(data);
            if (data?.seller?.isSendArticoloMancante) {
                toast('Prodotto non Trovato in Magazzino: \'' + data.product + ' Barcode: ' + data.barcode)
            }
        });

        if (localStorage.getItem(TOKEN_KEY)) {
            const decoded: any = jwt_decode(localStorage.getItem(TOKEN_KEY) || '');
            console.log(decoded.exp);
            console.log(Math.floor(Date.now() / 1000));
            if (decoded.exp < Math.floor(Date.now() / 1000)) {
                localStorage.clear();
            }
        }
        return () => {
            socket.off('connect');
            socket.off('disconnect');
            // socket.off('sendResponse');
            socket.off('alertScarico');
            socket.off('alertCarico');
            socket.off('alertCaricoAbuse');
            socket.off('alertCaricoPatentino');
            socket.off('alertCaricoNotLoad');
            socket.off('alertCarichiContemporanei');
            socket.off('alertRiassortimento');
            socket.off('alertCaricoTemp');
            socket.off('sendArticoloFinito');
            socket.off('sendRiassortimento');
            socket.off('sendSottoscorta');
            socket.off('sendMultiplo10');
            socket.off('sendArticoloMancante');
        };
    }, [])

    return (
        <SocketContext.Provider value={socket}>
            <StoreProvider>
                <DeviceProvider>
                    <TurnProvider>
                        <ToastContainer/>
                        <BrowserRouter>
                            <ConfigProvider theme={{
                                ...RefineThemes.Orange,
                                algorithm:
                                    currentTheme === 'light'
                                        ? theme.defaultAlgorithm
                                        : theme.darkAlgorithm,
                                components: {
                                    Button: {
                                        borderRadius: 10,
                                    },
                                    Typography: {
                                        colorTextHeading: '#1890ff',
                                    },
                                },
                                token: {
                                    colorPrimary: '#ffae00',
                                    colorBgElevated: currentTheme === 'light' ? 'white' : '#141414',
                                    colorTextHeading: currentTheme === 'light' ? 'black' : 'yellow'
                                },
                            }}>
                                <Refine
                                    authProvider={authProvider}
                                    dataProvider={DataProvider(API_URL + `/api`, axiosInstance)}
                                    notificationProvider={notificationProvider}
                                    options={{
                                        breadcrumb: false,
                                        syncWithLocation: true,
                                        warnWhenUnsavedChanges: true,
                                        disableTelemetry: true
                                    }}
                                    accessControlProvider={{
                                        can: async ({action, params, resource}) => {
                                            const role = await utilityHelper.permission();
                                            const enforcer = await newEnforcer(model, adapter);
                                            const can = await enforcer.enforce(
                                                role,
                                                resource,
                                                action,
                                            );
                                            return Promise.resolve({can});
                                        },
                                    }}
                                    routerProvider={routerProvider}
                                    i18nProvider={i18nProvider}
                                    resources={[
                                        {
                                            name: 'dashboard',
                                            meta: {label: 'Dashboard', icon: <Icons.DashboardOutlined />},
                                            list: '/dashboard',
                                        },
                                        {
                                            name: 'venditas',
                                            meta: {label: 'Vendita', icon: <Icons.ShoppingCartOutlined />},
                                            list: '/venditas',
                                        },
                                        {
                                            name: 'products',
                                            meta: {label: 'Articoli', icon: <Icons.AppstoreOutlined />},
                                            list: '/products',
                                            edit: '/products/edit/:id',
                                            create: '/products/create'
                                        },
                                        {
                                            name: 'stk',
                                            meta: {
                                                label: 'Merci',
                                                icon: <Icons.AppstoreOutlined />
                                            },
                                        },
                                        {
                                            name: 'referenze',
                                            identifier: 'referenze',
                                            meta: {
                                                label: 'Referenze',
                                                icon: <Icons.AppstoreOutlined />,
                                                parent: 'stk',
                                            },
                                            list: '/stk/referenze',
                                        },
                                        {
                                            name: 'magazzino',
                                            identifier: 'magazzino',
                                            meta: {
                                                label: 'Magazzino',
                                                icon: <Icons.AppstoreOutlined />,
                                                parent: 'stk',
                                            },
                                            list: '/stk/magazzino',
                                        },
                                        {
                                            name: 'espositore',
                                            identifier: 'espositore',
                                            meta: {
                                                label: 'Espositore',
                                                icon: <Icons.AppstoreOutlined />,
                                                parent: 'stk',
                                            },
                                            list: '/stk/espositore',
                                        },
                                        {
                                            name: 'verypaper',
                                            identifier: 'verypaper',
                                            meta: {
                                                label: 'Very Paper',
                                                icon: <Icons.PrinterOutlined />,
                                                parent: 'stk',
                                            },
                                            list: '/stk/verypaper',
                                        },
                                        {
                                            name: 'verypaperadmin',
                                            identifier: 'verypaperadmin',
                                            meta: {
                                                label: 'Very Paper Admin',
                                                icon: <Icons.PrinterOutlined />,
                                                parent: 'stk',
                                            },
                                            list: '/stk/verypaperadmin',
                                        },
                                        // {
                                        //     name: 'stocks',
                                        //     identifier: 'stocks',
                                        //     meta: {
                                        //         label: 'Merci',
                                        //         icon: <Icons.AppstoreOutlined />,
                                        //         parent: 'stk'
                                        //     },
                                        //     list: '/stk/stocks',
                                        //     edit: '/stk/stocks/edit/:id',
                                        //     create: '/stk/stocks/create'
                                        // },
                                        {
                                            name: 'rdr',
                                            meta: {label: 'Riordino', icon: <Icons.SettingOutlined />}
                                        },
                                        {
                                            name: 'riordinoStock',
                                            identifier: 'riordinoStock',
                                            meta: {
                                                label: 'Stock',
                                                parent: 'rdr',
                                                icon: <Icons.OrderedListOutlined />
                                            },
                                            list: '/rdr/riordinoStock',
                                        },
                                        {
                                            name: 'riordinoScientifico',
                                            identifier: 'riordinoScientifico',
                                            meta: {
                                                label: 'Scientifico',
                                                parent: 'rdr',
                                                icon: <Icons.ClusterOutlined />
                                            },
                                            list: '/rdr/riordinoScientifico',
                                        },
                                        {
                                            name: 'riordinoMagazzino',
                                            identifier: 'riordinoMagazzino',
                                            meta: {
                                                label: 'Magazzino',
                                                parent: 'rdr',
                                                icon: <Icons.NodeIndexOutlined />
                                            },
                                            list: '/rdr/riordinoMagazzino',
                                        },
                                        {
                                            name: 'riordinoLuminaria',
                                            identifier: 'riordinoLuminaria',
                                            meta: {
                                                label: 'IntelliLed',
                                                parent: 'rdr',
                                                icon: <Icons.BulbOutlined />
                                            },
                                            list: '/rdr/riordinoLuminaria',
                                        },
                                        {
                                            name: 'whare-house-movements',
                                            meta: {label: 'Documenti', icon: <Icons.DatabaseOutlined />},
                                            list: '/whare-house-movements',
                                            edit: '/whare-house-movements/edit/:id',
                                            create: '/whare-house-movements/create'
                                        },
                                        {
                                            name: 'turns',
                                            meta: {label: 'Turni', icon: <Icons.ClockCircleOutlined />},
                                            list: '/turns',
                                            edit: '/turns/edit/:id',
                                            create: '/turns/create',
                                            show: '/turns/show/:id',
                                        },
                                        {
                                            name: 'users',
                                            meta: {label: 'Utenti', icon: <Icons.UserOutlined />},
                                            list: '/users',
                                            edit: '/users/edit/:id',
                                            create: '/users/create',
                                            show: '/users/show/:id',
                                        },
                                        {
                                            name: 'devices',
                                            identifier: 'devices',
                                            meta: {label: 'Dispositivi', icon: <Icons.ApiOutlined />},
                                            list: '/devices',
                                            edit: '/devices/edit/:id',
                                            create: '/devices/create',
                                            show: '/devices/show/:id',
                                        },
                                        {
                                            name: 'device-types',
                                            meta: {label: 'Tipo Dispositivi', icon: <Icons.BlockOutlined />},
                                            list: '/device-types',
                                            edit: '/device-types/edit/:id',
                                            create: '/device-types/create',
                                            show: '/device-types/show/:id',
                                        },
                                        {
                                            name: 'categories',
                                            meta: {label: 'Categorie', icon: <Icons.BuildOutlined />},
                                            list: '/categories',
                                            edit: '/categories/edit/:id',
                                            create: '/categories/create',
                                            show: '/categories/show/:id',
                                        },
                                        {
                                            name: 'groups',
                                            meta: {label: 'Gruppi', icon: <Icons.GroupOutlined />},
                                            list: '/groups',
                                            edit: '/groups/edit/:id',
                                            create: '/groups/create',
                                            show: '/groups/show/:id',
                                        },
                                        {
                                            name: 'rpt',
                                            meta: {label: 'Statistiche', icon: <Icons.CarOutlined />}
                                        },
                                        {
                                            name: 'report-grafico',
                                            meta: {
                                                label: 'Grafico',
                                                icon: <Icons.BarChartOutlined />,
                                                parent: 'rpt',
                                            },
                                            list: '/rpt/report-grafico',
                                        },
                                        {
                                            name: 'report-grafico-ulp',
                                            meta: {
                                                label: 'Grafico U.L.P.',
                                                icon: <Icons.BarChartOutlined />,
                                                parent: 'rpt',
                                            },
                                            list: '/rpt/report-grafico-ulp',
                                        },
                                        {
                                            name: 'reports',
                                            meta: {
                                                label: 'Report',
                                                icon: <Icons.StockOutlined />,
                                                parent: 'rpt',
                                            },
                                            list: '/rpt/reports',
                                        },
                                        {
                                            name: 'snapshot',
                                            meta: {
                                                label: 'Inventario Instantaneo',
                                                icon: <Icons.StockOutlined />,
                                                parent: 'rpt',
                                            },
                                            list: '/rpt/snapshot',
                                        },
                                        {
                                            name: 'report-movimentazionis',
                                            meta: {
                                                label: 'Report Barcode',
                                                icon: <Icons.StockOutlined />,
                                                parent: 'rpt',
                                            },
                                            list: '/rpt/report-movimentazionis',
                                        },
                                        {
                                            name: 'remote-checks',
                                            meta: {
                                                label: 'Check Remoto',
                                                icon: <Icons.CheckCircleOutlined />,
                                                parent: 'rpt',
                                            },
                                            list: '/rpt/remote-checks',
                                        },
                                        {
                                            name: 'sellers',
                                            meta: {label: 'Punti Vendita', icon: <Icons.ApartmentOutlined />},
                                            list: '/sellers',
                                            edit: '/sellers/edit/:id',
                                            create: '/sellers/create',
                                            show: '/sellers/show/:id',
                                        },
                                        {
                                            name: 'ptn',
                                            meta: {label: 'Patentini', icon: <Icons.CarOutlined />}
                                        },
                                        {
                                            name: 'patents',
                                            list: '/ptn/patents',
                                            create: '/ptn/patents/create',
                                            edit: '/ptn/patents/edit/:id',
                                            meta: {
                                                parent: 'ptn', label: 'Anagrafiche', icon: <Icons.UsergroupAddOutlined />
                                            },
                                        },
                                        {
                                            name: 'patent-movements',
                                            list: '/ptn/patent-movements',
                                            create: '/ptn/patent-movements/create',
                                            edit: '/ptn/patent-movements/edit/:id',
                                            meta: {
                                                parent: 'ptn', label: 'Documenti', icon: <Icons.ContainerOutlined />
                                            },
                                        },
                                        {
                                            name: 'prm',
                                            meta: {label: 'Parametri', icon: <Icons.SettingOutlined />}
                                        },
                                        {
                                            name: 'check-restocks',
                                            identifier: 'check-restocks',
                                            meta: {
                                                label: 'Check Riassortimento',
                                                parent: 'prm',
                                                icon: <Icons.ClusterOutlined />
                                            },
                                            list: '/prm/check-restocks',
                                        },
                                        {
                                            name: 'alert-restocks',
                                            identifier: 'alert-restocks',
                                            meta: {
                                                label: 'Alert Stock',
                                                parent: 'prm',
                                                icon: <Icons.ClusterOutlined />
                                            },
                                            list: '/prm/alert-restocks',
                                            edit: '/prm/alert-restocks/edit/:id',
                                        },
                                        {
                                            name: 'default-stocks',
                                            identifier: 'default-stocks',
                                            meta: {
                                                label: 'Default Stock',
                                                parent: 'prm',
                                                icon: <Icons.OrderedListOutlined />
                                            },
                                            list: '/prm/default-stocks',
                                        },
                                        {
                                            name: 'esl-settings',
                                            identifier: 'esl-settings',
                                            meta: {
                                                label: 'Esl Setting',
                                                parent: 'prm',
                                                icon: <Icons.OrderedListOutlined />
                                            },
                                            list: '/prm/esl-settings',
                                        },
                                        {
                                            name: 'parameters',
                                            identifier: 'parameters',
                                            meta: {
                                                label: 'Progressivi',
                                                parent: 'prm',
                                                icon: <Icons.OrderedListOutlined />
                                            },
                                            list: '/prm/parameters',
                                            create: '/prm/parameters/create',
                                            edit: '/prm/parameters/edit/:id',
                                        },
                                        {
                                            name: 'doc-types',
                                            identifier: 'doc-types',
                                            meta: {
                                                label: 'Tipo Documenti',
                                                parent: 'prm',
                                                icon: <Icons.ClusterOutlined />
                                            },
                                            list: '/prm/doc-types',
                                            create: '/prm/doc-types/create',
                                            edit: '/prm/doc-types/edit/:id',
                                        },
                                        {
                                            name: 'causals',
                                            identifier: 'causals',
                                            meta: {
                                                label: 'Causali',
                                                parent: 'prm',
                                                icon: <Icons.NodeIndexOutlined />
                                            },
                                            list: '/prm/causals',
                                            create: '/prm/causals/create',
                                            edit: '/prm/causals/edit/:id',
                                        }
                                    ]}
                                >
                                    <Routes>
                                        <Route
                                            element={
                                                <Authenticated
                                                    fallback={<CatchAllNavigate to="/login"/>}
                                                >
                                                    <ThemedLayout
                                                        Header={() => {
                                                            return (<Header theme={currentTheme}
                                                                            setTheme={setCurrentTheme}/>)
                                                        }}
                                                        Sider={() => {
                                                            return (<CustomSider/>)
                                                        }}
                                                    >
                                                        <Outlet/>
                                                    </ThemedLayout>
                                                </Authenticated>
                                            }
                                        >
                                            <Route index element={<NavigateToResource resource="dashboard"/>} />

                                            <Route path="/dashboard">
                                                <Route index element={<DashboardList/>}/>
                                            </Route>
                                            <Route path="/venditas">
                                                <Route index element={<VenditaList/>}/>
                                            </Route>
                                            <Route path="/products">
                                                <Route index element={<ProductList/>}/>
                                                <Route path="create" element={<ProductCreate/>}/>
                                                <Route path="edit/:id" element={<ProductEdit/>}/>
                                            </Route>
                                            <Route path="stk">
                                                <Route path="referenze">
                                                    <Route index element={<ReferenzeStock/>}/>
                                                </Route>
                                                <Route path="magazzino">
                                                    <Route index element={<MagazzinoStock/>}/>
                                                </Route>
                                                <Route path="espositore">
                                                    <Route index element={<EspositoreStock/>}/>
                                                </Route>
                                                <Route path="verypaper">
                                                    <Route index element={<VeryPaperList/>}/>
                                                </Route>
                                                <Route path="verypaperadmin">
                                                    <Route index element={<VeryPaperAdminList/>}/>
                                                </Route>
                                                {/*<Route path="stocks">*/}
                                                {/*    <Route index element={<StockList/>}/>*/}
                                                {/*    <Route path="create" element={<StockCreate/>}/>*/}
                                                {/*    <Route path="edit/:id" element={<StockEdit/>}/>*/}
                                                {/*</Route>*/}
                                            </Route>
                                            <Route path="rdr">
                                                <Route path="riordinoStock">
                                                    <Route index element={<StockRiordino/>}/>
                                                </Route>
                                                <Route path="riordinoScientifico">
                                                    <Route index element={<StockRiordinoScientifico/>}/>
                                                </Route>
                                                <Route path="riordinoMagazzino">
                                                    <Route index element={<StockMagazzino/>}/>
                                                </Route>
                                                <Route path="riordinoLuminaria">
                                                    <Route index element={<StockRiordinoLuminaria/>}/>
                                                </Route>
                                            </Route>
                                            <Route path="/whare-house-movements">
                                                <Route index element={<WhareHouseMovementList/>}/>
                                                <Route path="create" element={<WhareHouseMovementCreate/>}/>
                                                <Route path="edit/:id" element={<WhareHouseMovementEdit/>}/>
                                            </Route>
                                            <Route path="/turns">
                                                <Route index element={<TurnList/>}/>
                                                <Route path="create" element={<TurnCreate/>}/>
                                                <Route path="edit/:id" element={<TurnEdit/>}/>
                                                <Route path="show/:id" element={<TurnShow/>}/>
                                            </Route>
                                            <Route path="/users">
                                                <Route index element={<UsersList/>}/>
                                                <Route path="create" element={<CreateUser/>}/>
                                                <Route path="edit/:id" element={<UserEdit/>}/>
                                            </Route>
                                            <Route path="/devices">
                                                <Route index element={<DeviceList/>}/>
                                                <Route path="create" element={<DeviceCreate/>}/>
                                                <Route path="edit/:id" element={<DeviceEdit/>}/>
                                            </Route>
                                            <Route path="/device-types">
                                                <Route index element={<DeviceTypeList/>}/>
                                                <Route path="create" element={<DeviceTypeCreate/>}/>
                                                <Route path="edit/:id" element={<DeviceTypeEdit/>}/>
                                            </Route>
                                            <Route path="/categories">
                                                <Route index element={<CategoryList/>}/>
                                                <Route path="create" element={<CategoryCreate/>}/>
                                                <Route path="edit/:id" element={<CategoryEdit/>}/>
                                            </Route>
                                            <Route path="/groups">
                                                <Route index element={<GroupList/>}/>
                                                <Route path="create" element={<GroupCreate/>}/>
                                                <Route path="edit/:id" element={<GroupEdit/>}/>
                                            </Route>
                                            <Route path="rpt">
                                                <Route path="report-grafico">
                                                    <Route index element={<ReportGraficoList/>}/>
                                                </Route>
                                                <Route path="report-grafico-ulp">
                                                    <Route index element={<ReportGraficoUlpList/>}/>
                                                </Route>
                                                <Route path="reports">
                                                    <Route index element={<ReportList/>}/>
                                                </Route>
                                                <Route path="snapshot">
                                                    <Route index element={<ReportSnapshotList />}/>
                                                </Route>
                                                <Route path="report-movimentazionis">
                                                    <Route index element={<ReportMovimentazioniList/>}/>
                                                </Route>
                                                <Route path="remote-checks">
                                                    <Route index element={<CheckRemotoList/>}/>
                                                </Route>
                                            </Route>
                                            <Route path="/sellers">
                                                <Route index element={<SellerList/>}/>
                                                <Route path="create" element={<SellerCreate/>}/>
                                                <Route path="edit/:id" element={<SellerEdit/>}/>
                                            </Route>
                                            <Route path="ptn">
                                                <Route path="patents">
                                                    <Route index element={<PatentList/>}/>
                                                    <Route path="create" element={<PatentCreate/>}/>
                                                    <Route path="edit/:id" element={<PatentEdit/>}/>
                                                </Route>
                                                <Route path="patent-movements">
                                                    <Route index element={<PatentMovementList/>}/>
                                                    <Route path="create" element={<PatentMovementCreate/>}/>
                                                    <Route path="edit/:id" element={<PatentMovementEdit/>}/>
                                                </Route>
                                            </Route>
                                            <Route path="prm">
                                                <Route path="default-stocks">
                                                    <Route index element={<CreateStock/>}/>
                                                </Route>
                                                <Route path="esl-settings">
                                                    <Route index element={<EslSettingList/>}/>
                                                </Route>
                                                <Route path="check-restocks">
                                                    <Route index element={<CheckRestockList/>}/>
                                                </Route>
                                                <Route path="alert-restocks">
                                                    <Route index element={<RestockList/>}/>
                                                    <Route path="edit/:id" element={<RestockEdit/>}/>
                                                </Route>
                                                <Route path="parameters">
                                                    <Route index element={<ParameterList/>}/>
                                                    <Route path="create" element={<ParameterCreate/>}/>
                                                    <Route path="edit/:id" element={<ParameterEdit/>}/>
                                                </Route>
                                                <Route path="doc-types">
                                                    <Route index element={<DocTypeList/>}/>
                                                    <Route path="create" element={<DocTypeCreate/>}/>
                                                    <Route path="edit/:id" element={<DocTypeEdit/>}/>
                                                </Route>
                                                <Route path="causals">
                                                    <Route index element={<CausalList/>}/>
                                                    <Route path="create" element={<CausalCreate/>}/>
                                                    <Route path="edit/:id" element={<CausalEdit/>}/>
                                                </Route>
                                            </Route>
                                        </Route>
                                            <Route
                                                element={
                                                    <Authenticated fallback={<Outlet/>}>
                                                        <NavigateToResource resource="dashboard"/>
                                                    </Authenticated>
                                                }
                                            >
                                                <Route path="/login" element={<Login/>}/>
                                                <Route path="/forgot-password"
                                                       element={<ForgotPasswordPage title={false} />}/>
                                                <Route path="/update-password"
                                                       element={<UpdatePasswordPage title={false} />}/>
                                            </Route>
                                        <Route
                                            element={
                                                <Authenticated>
                                                    <ThemedLayout>
                                                        <Outlet />
                                                    </ThemedLayout>
                                                </Authenticated>
                                            }
                                        >
                                            <Route path="*" element={<ErrorComponent/>}/>
                                        </Route>
                                    </Routes>
                                    <UnsavedChangesNotifier />
                                </Refine>
                            </ConfigProvider>
                        </BrowserRouter>
                    </TurnProvider>
                </DeviceProvider>
            </StoreProvider>
        </SocketContext.Provider>
    );
}

export default App;
