import {IResourceComponentsProps, useList, useTranslate} from '@refinedev/core';
import { List, useTable } from "@refinedev/antd";
import {Button, Col, Input, Row, Table} from 'antd';
import React, {useContext, useEffect, useState} from 'react';
import {IStock} from '../../interfaces';
import {StoreContext} from '../../contexts/StoreContext';
import { CSVLink } from "react-csv";
import dayjs from 'dayjs';
import * as XLSX from 'xlsx';
export const StockRiordino: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const {value} = useContext(StoreContext);
    const [store] = value;
    const [tempData, setTempData] = useState<any>([]);
    const {tableQueryResult} = useTable<IStock>({
        resource: "stocks",

        queryOptions: {
            enabled: true,
        },

        meta: {populate: 'seller, product, product.category'},

        pagination: {
            pageSize: 9999999,
            mode: "server"
        },

        filters: {
            permanent: [
                {
                    field: "seller.id",
                    operator: "eq",
                    value: store.id,
                },
                {
                    field: "giacenza",
                    operator: "gte",
                    value: 0,
                },
                {
                    field: "isRadiato",
                    operator: "null",
                    value: true,
                },
            ]
        },

        sorters: {
            permanent: [
                {
                    field: 'product.description',
                    order: 'asc'
                }
            ]
        }
    });

    const {refetch} = useList({
        resource: 'ware-house-positions',

        meta: {
            populate: 'stock, stock.product, seller'
        },

        queryOptions: {
            enabled: false
        },

        pagination: {
            pageSize: 99999999,
            mode: 'server'
        },

        filters: [
            {
                field: 'seller][id]',
                operator: 'eq',
                value: store.id
            },
            {
                field: 'stock][isRadiato]',
                operator: 'null',
                value: true
            },
            {
                field: 'dataOut',
                operator: 'null',
                value: true
            }
        ],
        sorters: [
            {
                field: 'position',
                order: 'asc'
            }
        ]
    });

    // const [riordinoStock, setRiordinoStock] = useState(false);

    const [activeFilter, setActiveFilter] = useState('');
    // const handleRiordinoStock = () => {
    //     setRiordinoStock(true);
    // };

    const [dataExp, setDataExp] = useState<any>([]);
    const change = (e: any, record: any, index: number) => {
        setDataExp([]);
        let datExp: any = [];
            if (index === -1) {
                if (tempData && tempData.length > 0) {
                    // let gIndex = 0;
                    for (let d of tempData) {
                        // console.log(activeFilter)
                        if ((d.consigliato > 0 || d.calcolo > 0) && d.product.category[activeFilter]) {
                            // console.log(d);
                            datExp.push({
                                codice: d.product.codice,
                                qta: (d.consigliato > 0 ? d.consigliato.toFixed(2) : Number(d.calcolo).toFixed(2)),
                                // importo: d.totale
                            });
                            setDataExp((current: any) => [...current, {
                                codice: d.product.codice,
                                qta: (d.consigliato > 0 ? d.consigliato.toFixed(2) : Number(d.calcolo).toFixed(2)),
                                // importo: d.totale
                            }]);
                        }
                    }
                    // console.log(datExp);
                    setTimeout(() => {
                        let ws = XLSX.utils.json_to_sheet(datExp);
                        let wb = XLSX.utils.book_new();
                        XLSX.utils.book_append_sheet(wb, ws, "sheet");
                        let buf = XLSX.write(wb, {bookType:'xlsx', type:'buffer'}); // generate a nodejs buffer
                        let str = XLSX.write(wb, {bookType:'xlsx', type:'binary'}); // generate a binary string in web browser
                        XLSX.writeFile(wb, 'Ordine_Stock_' + dayjs().format('DD_MM_YYYY') + '.xlsx');
                    }, 500)
                }
            } else {
                // let gIndex = 0;
                if (tempData && tempData.length > 0) {
                    // console.log('Change: ', index, e.target.value, record);
                    const indexF = tempData.findIndex((x: any) => x.id === record.id);
                    tempData[indexF].consigliato = Number(e.target.value);
                    // console.log(tempData[indexF]);
                    setTempData([...tempData]);
                    // console.log(tempData);
                    let exp = [];
                    for (let d of tempData) {
                        // const consigliato = (d.product.category.isGeneric) ? Math.abs(d.giacenza - d.stockMinKg) : ((d.giacenza * d.pesoFiscaleConf) >= d.stockMinKg ? 0 : Math.abs((d.giacenza * d.pesoFiscaleConf) - d.stockMinKg ));
                        // console.log(consigliato);
                        // const tempC = d.consigliato > 0 ? d.consigliato : consigliato;
                        const tempC = d.consigliato;
                        if (Number(d.consigliato) > 0) {
                            exp.push({
                                codice: d.product.codice,
                                qta: Number(d.consigliato).toFixed(2),
                                // importo: (d.product.category.isGeneric) ? Number(tempC * d.pesoFiscaleConf).toLocaleString('de', {
                                //     minimumFractionDigits: 2
                                // }) + " €" : Number(d.product.priceKg * tempC).toLocaleString('de', {
                                //     minimumFractionDigits: 2
                                // }) + " €"
                            });
                            // console.log(exp[index]);
                            // console.log(exp);
                        }
                    }
                    setDataExp(exp);
                }
            }
    }

    useEffect(() => {
        let pos: any = [];
        refetch().then( (result: any) => {
            // let q = 0;
            const tempGroup: any = result.data?.data.reduce((r: any, a: any) => {
                r[a.stock.product.id] = [...(r[a.stock.product.id] || []), a];
                return r;
            }, {})
            for (let g in tempGroup) {
                pos.push({
                    qta: tempGroup[g].length,
                    id: Number(g)
                })
            }
            let gIndex = 0;
            if (tableQueryResult && tableQueryResult.data && tableQueryResult.data.data) {
                tableQueryResult.data.data.map((d: any) => {
                    gIndex = pos.findIndex((x: any) => x.id === d.product.id);
                    d.giacenza = ((gIndex !== -1) ? pos[gIndex].qta : 0);
                    const tot = (d.product.category.isGeneric) ?
                        Math.abs(d.giacenza - d.stockMinKg) :
                        ((d.giacenza * d.pesoFiscaleConf) >= d.stockMinKg ?
                            0 :
                            Math.abs((d.giacenza * d.pesoFiscaleConf) - d.stockMinKg)).toFixed(2);
                    d.consigliato = Number(tot);
                    setTempData([...tableQueryResult.data.data]);
                })
                // console.log(tempData);
            }
        })
    }, [tableQueryResult.isFetched])

    // const [category, setCategory] = useState('');
    return (
        <>
            <List title={'Riordino Stock'}>
                <Table size="small"
                       loading={tempData && tempData.length === 0}
                       dataSource={tempData && tempData.length > 0 ? tempData : []}
                       key="id"
                       rowKey="id"
                       sticky={false}
                       scroll={{y: window.innerHeight - 293 + 'px'}}
                       pagination={false}
                       footer={(value) => {
                           const tot = (value && value.length > 0) ?
                               value?.map((item: any) => item?.product?.priceKg * Number(item?.consigliato > 0 ?
                                   item?.consigliato : (item?.product.category.isGeneric) ?
                                       Math.abs(item?.giacenza - item?.stockMinKg) : ((item?.giacenza * item?.pesoFiscaleConf) >= item.stockMinKg ?
                                           0 : Math.abs((item?.giacenza * item?.pesoFiscaleConf) - item?.stockMinKg))))
                                   .reduce((prev, next) => prev + next).toFixed(2) : 0;
                           return <Row gutter={24}>
                               <Col span={12}>
                                   <Button
                                       type={'primary'}
                                       htmlType={'button'}
                                       onClick={() => (tempData && tempData.length < 0) ? null : change(null, null, -1)}>
                                       Genera Ordine
                                   </Button>
                                   {/*<CSVLink*/}
                                   {/*    separator={';'}*/}
                                   {/*    filename={'Ordine_Stock_' + dayjs().format('DD_MM_YYYY') + '.csv'}*/}
                                   {/*    data={dataExp}*/}
                                   {/*    asyncOnClick={true}*/}
                                   {/*    onClick={() => (tempData && tempData.length < 0) ? null : change(null, null, -1)}*/}
                                   {/*>*/}
                                   {/*    <Button type={'primary'}>Genera Ordine</Button>*/}
                                   {/*</CSVLink>*/}
                               </Col>
                               <Col span={12}>
                                   <div style={{
                                       textAlign: 'right',
                                       fontSize: 20
                                   }}>
                                       Totale: {parseFloat(tot.toLocaleString()).toLocaleString('de', {
                                       minimumFractionDigits: 2
                                   }) + ' €'}
                                   </div>

                               </Col>
                           </Row>
                       }}
                >
                    <Table.Column
                        dataIndex="product"
                        key="product.codice"
                        title="Codice"
                        render={(value) => {
                            return value?.codice}}
                    />
                    <Table.Column
                        dataIndex="description"
                        key="description"
                        title={translate('pages.stock.description', 'Descrizione')}
                        filters={[
                            {
                                text: 'Tabacchi',
                                value: 'isTabacco',
                            },
                            {
                                text: 'Gratta e Vinci',
                                value: 'isGeneric',
                            }
                        ]}
                        onFilter={(value: any, record: any) => {
                            setActiveFilter(value);
                            return record?.product?.category[value]
                        }}
                        render={(value) => value}
                    />
                    <Table.Column
                        dataIndex="product"
                        key="product"
                        title={translate('pages.stock.prezzoKg', 'Prezzo Kg/Conf.')}
                        render={(value, record: any) => {
                            return Number(parseFloat(value.priceKg).toFixed(2)).toLocaleString('de', {
                                minimumFractionDigits: 2
                            }) + " €"
                        }
                        }
                    />
                    {/*<Table.Column*/}
                    {/*    dataIndex="giacenza"*/}
                    {/*    key="giacenza"*/}
                    {/*    title={translate('pages.stock.prezzoKg', 'Giacenza')}*/}
                    {/*    render={(value, record: any) => {*/}
                    {/*        return value;*/}
                    {/*    }*/}
                    {/*    }*/}
                    {/*/>*/}
                    <Table.Column
                        dataIndex="stockMinKg"
                        key="stockMinKg"
                        title={translate('pages.stock.prezzoKg', 'stockMinKg')}
                        render={(value, record: any) => {
                            return value;
                        }
                        }
                    />
                    <Table.Column
                        dataIndex="giacenza"
                        key="giacenza"
                        title={translate('pages.stock.giacenza', 'Giacenza')}
                        render={(value, record: any) => {
                            return (value)
                        }
                        }
                    />
                    <Table.Column
                        dataIndex="giacenza"
                        key="giacenza"
                        title={translate('pages.stock.giacenza', 'Giacenza Kg/Conf.')}
                        render={(value, record: any) => {
                            return (record.product.category.isGeneric) ? value : (value * record.pesoFiscaleConf).toFixed(2)
                        }
                        }
                    />
                    <Table.Column
                        dataIndex="description"
                        key="description"
                        title={translate('pages.stock.ordineKg', 'Ordine Kg/Conf. Consigliato')}
                        render={(value, record: any) => {
                            const cons = (record.product.category.isGeneric) ?
                                Math.abs(record.giacenza - record.stockMinKg) :
                                ((record.giacenza * record.pesoFiscaleConf) >= record.stockMinKg ?
                                    0 :
                                    Math.abs((record.giacenza * record.pesoFiscaleConf) - record.stockMinKg)).toFixed(2);
                            record.calcolo = cons;
                            return cons;
                        }
                        }
                    />
                    <Table.Column
                        dataIndex="description"
                        key="description"
                        title={translate('pages.stock.ordine', 'Ordine da Effettuare')}
                        filters={[
                            {
                                text: 'Tutto',
                                value: '-1',
                            },
                            {
                                text: 'Qta > 0',
                                value: '0',
                            }
                        ]}
                        onFilter={(value: any, record: any) => {
                            // console.log(record);
                            // let tot;
                            // if (activeFilter && record.product.category[activeFilter]) {
                            //     tot = (record.product.category.isGeneric) ?
                            //         Math.abs(record.giacenza - record.stockMinKg) :
                            //         ((record.giacenza * record.pesoFiscaleConf) >= record.stockMinKg ?
                            //             0 :
                            //             Math.abs((record.giacenza * record.pesoFiscaleConf) - record.stockMinKg)).toFixed(2);
                            //     console.log('A');
                            //     record.consigliato = tot;
                                return record.consigliato > Number(value) && (activeFilter ? record.product.category[activeFilter] : true)
                            // }
                            //     tot = (record.product.category.isGeneric) ?
                            //         Math.abs(record.giacenza - record.stockMinKg) :
                            //         ((record.giacenza * record.pesoFiscaleConf) >= record.stockMinKg ?
                            //             0 :
                            //             Math.abs((record.giacenza * record.pesoFiscaleConf) - record.stockMinKg)).toFixed(2);
                            //     console.log('B');
                            //     record.consigliato = tot;
                            //     return tot > Number(value)


                        }}
                        render={(value, record: any, index) => {
                            // const tot = (record.product.category.isGeneric) ?
                            //     Math.abs(record.giacenza - record.stockMinKg) :
                            //     ((record.giacenza * record.pesoFiscaleConf) >= record.stockMinKg ?
                            //         0 :
                            //         Math.abs((record.giacenza * record.pesoFiscaleConf) - record.stockMinKg)).toFixed(2);
                            // record.consigliato = tot;
                            return <Input style={{width: '50%'}} size={'small'} placeholder={record.consigliato > 0 ? record.consigliato : 0}
                                          onPressEnter={(e) => change(e, record, index)}/>
                        }
                        }
                    />
                    <Table.Column
                        dataIndex="description"
                        key="description"
                        title={translate('pages.stock.importo', 'Importo')}
                        render={(value, record: any) => {
                            const consigliato = (record.product.category.isGeneric) ? Math.abs(record.giacenza - record.stockMinKg) : ((record.giacenza * record.pesoFiscaleConf) >= record.stockMinKg ? 0 : Math.abs((record.giacenza * record.pesoFiscaleConf) -record.stockMinKg ));
                            const tempC = record.consigliato > 0 ? record.consigliato : consigliato;
                            record.totale = (record.product.category.isGeneric) ? Number(tempC * record.pesoFiscaleConf).toLocaleString('de', {
                                minimumFractionDigits: 2
                            }) + " €" : Number(record.product.priceKg * tempC).toLocaleString('de', {
                                minimumFractionDigits: 2
                            }) + " €";
                            return (record.product.category.isGeneric) ? Number(tempC * record.pesoFiscaleConf).toLocaleString('de', {
                                minimumFractionDigits: 2
                            }) + " €" : Number(record.product.priceKg * tempC).toLocaleString('de', {
                                minimumFractionDigits: 2
                            }) + " €"
                        }}
                    />
                </Table>
            </List>
        </>
    );
};
