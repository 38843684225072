import { IResourceComponentsProps, useCreate, useList } from "@refinedev/core";
import { Create, useForm, useSelect } from "@refinedev/antd";
import { Button, Col, Form, Input, InputNumber, Row, Select, Switch } from "antd";
import {IBarcode, IBarcodePackage, ICategory, IProduct, ISeller} from 'interfaces';
import React, {useEffect, useRef, useState} from 'react';
import type { InputRef } from 'antd';

export const ProductCreate: React.FC<IResourceComponentsProps> = () => {
    const inputRefB = useRef<InputRef>(null);
    const inputRefBP = useRef<InputRef>(null);
    const [barcodes, setBarcodes] = useState<any[]>([]);
    const [barcodePackage, setBarcodePackage] = useState<any[]>([]);
    const [maxCodice, setMaxCodice] = useState<any>(0);
    const {formProps, saveButtonProps} = useForm<IProduct>({

    });

    const newBarcode = useCreate<IBarcode>();
    const newBarcodePackage = useCreate<IBarcodePackage>();
    const addBarcode = () => {
        // console.log(inputRef.current?.input?.value);
        newBarcode.mutateAsync({
            resource: "barcodes",
            values: {
                code: inputRefB.current?.input?.value,
                isPacket: true
            }
        }).then( (res: any) => {
            // console.log(res.data.data.id, res.data.data.attributes.code)
            barcodes.push({id: res.data.data.id, value: res.data.data.id, label: res.data.data.attributes.code});
            setTimeout(() => {
                // formProps.form?.setFieldsValue({'barcodes': barcodes});
                // console.log(barcodes);
            }, 800)
        })
    }

    const addBarcodePackage = () => {
        // console.log(inputRef.current?.input?.value);
        newBarcodePackage.mutateAsync({
            resource: "barcode-packages",
            values: {
                code: inputRefBP.current?.input?.value,
                isPackage: true
            }
        }).then( (res: any) => {
            // console.log(res.data.data.id, res.data.data.attributes.code)
            barcodePackage.push({id: res.data.data.id, value: res.data.data.id, label: res.data.data.attributes.code});
            setTimeout(() => {
                formProps.form?.setFieldsValue({'barcode_packages': barcodePackage});
                // console.log(barcodePackage);
            }, 800)
        })
    }

    const { data } = useList<IProduct>({
        resource: "products",

        pagination: {
            pageSize: 10000000000
        }
    });

    const { selectProps: categorySelectProps } = useSelect<ICategory>({
        resource: "categories",
        optionValue: 'id',
        optionLabel: 'description',

        pagination: {
            mode: "server"
        }
    });

    const { selectProps: barcodeSelectProps } = useSelect<IBarcode>({
        resource: "barcodes",
        optionValue: 'id',
        optionLabel: 'code',

        pagination: {
            mode: "server"
        }
    });

    const { selectProps: barcodePackageSelectProps } = useSelect<IBarcodePackage>({
        resource: "barcode-packages",
        optionValue: 'id',
        optionLabel: 'code',

        pagination: {
            mode: "server"
        }
    });

    useEffect(() => {
            if (data && data.data && data.data.length > 0) {
                const max = data.data.reduce((max, curren) => max.codice > curren.codice ? max : curren);
                setMaxCodice(max.codice);
            }
    }, [data?.data.length])

    return (
            <Create saveButtonProps={saveButtonProps}>
                <Form
                    {...formProps}
                    layout="vertical"
                >
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item
                                label="Codice AMS"
                                name="codice"
                                initialValue={maxCodice + 1}
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Description"
                                name="description"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Categoria"
                                name={["category", "id"]}
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Select {...categorySelectProps}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item
                                label="Price"
                                name="price"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <InputNumber
                                    style={{ width: '100%'}}
                                    precision={2}
                                    decimalSeparator = {','}
                                    //formatter={(value) => `€ ${parseFloat(Number(value).toString()).toFixed(2)}`.replace(/\$\s?|(\.)/g, ',')}
                                    //parser={(value) => parseFloat(Number(value).toString()).toFixed(2).replace('.', '').replace(/,/g, '.')}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Price Kg"
                                name="priceKg"
                            >
                                <InputNumber
                                    style={{ width: '100%'}}
                                    precision={2}
                                    decimalSeparator = {','}
                                    //formatter={(value) => `€ ${parseFloat(Number(value).toString()).toFixed(2)}`.replace(/\$\s?|(\.)/g, ',')}
                                    //parser={(value) => parseFloat(Number(value).toString()).toFixed(2).replace('.', '').replace(/,/g, '.')}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Note"
                                name="note"
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item
                                label="Pezzi per Confezione"
                                name="nrPezziStecca"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <InputNumber
                                    style={{ width: '100%'}}
                                    precision={2}
                                    decimalSeparator = {','}
                                    //formatter={(value) => `€ ${parseFloat(Number(value).toString()).toFixed(2)}`.replace(/\$\s?|(\.)/g, ',')}
                                    //parser={(value) => parseFloat(Number(value).toString()).toFixed(2).replace('.', '').replace(/,/g, '.')}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Peso Fisc. Kg/€ Cf."
                                name="pesoFiscaleConf"
                            >
                                <InputNumber
                                    style={{ width: '100%'}}
                                    precision={2}
                                    decimalSeparator = {','}
                                    //formatter={(value) => `€ ${parseFloat(Number(value).toString()).toFixed(2)}`.replace(/\$\s?|(\.)/g, ',')}
                                    //parser={(value) => parseFloat(Number(value).toString()).toFixed(2).replace('.', '').replace(/,/g, '.')}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Stock Min. Riordino Kg/Conf"
                                name="stockMinKg"
                            >
                                <InputNumber
                                    style={{ width: '100%'}}
                                    precision={2}
                                    decimalSeparator = {','}
                                    //formatter={(value) => `€ ${parseFloat(Number(value).toString()).toFixed(2)}`.replace(/\$\s?|(\.)/g, ',')}
                                    //parser={(value) => parseFloat(Number(value).toString()).toFixed(2).replace('.', '').replace(/,/g, '.')}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                label="Add Barcode"
                                name={"addBarcode"}
                            >
                                <Input.Group compact>
                                    <Input ref={inputRefB} style={{ width: 'calc(100% - 200px)' }} placeholder={'barcode'} />
                                    <Button type="primary" onClick={() => addBarcode()}>Add</Button>
                                </Input.Group>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Barcodes"
                                name={"barcodes"}
                                initialValue={barcodes}
                            >
                                <Select
                                    {...barcodeSelectProps}
                                    style={{ minWidth: 200 }}
                                    mode="multiple"
                                    placeholder="Select Barcodes"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                label="Add Barcode Package"
                                name={"addBarcodePackage"}
                            >
                                <Input.Group compact>
                                    <Input ref={inputRefBP} style={{ width: 'calc(100% - 200px)' }} placeholder={'barcode'} />
                                    <Button type="primary" onClick={() => addBarcodePackage()}>Add</Button>
                                </Input.Group>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Barcodes Package"
                                name={"barcode_packages"}
                                initialValue={barcodes}
                            >
                                <Select
                                    {...barcodePackageSelectProps}
                                    style={{ minWidth: 200 }}
                                    mode="multiple"
                                    placeholder="Select Barcodes"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Create>
    );
};
