import {
    CanAccess,
    CrudFilters,
    IResourceComponentsProps,
    useCreate,
    useList,
    useOne,
    useTranslate
} from '@refinedev/core';

import {
    DateField, DeleteButton, EditButton,
    List, ShowButton, TagField, useModal, useModalForm,
    useTable,
} from '@refinedev/antd';

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import {Button, Col, Form, Input, Modal, Popover, Row, Select, Space, Switch, Table, Typography} from 'antd';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {StoreContext} from '../../contexts/StoreContext';
import "@ant-design/charts/dist/index.css";
import {Area} from '@ant-design/charts';
import {IRemoteCheck, IStock} from '../../interfaces';
const {Title} = Typography;

export const CheckRemotoList: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const {value, value2} = useContext(StoreContext);
    const [store, setStore] = value;
    const {tableProps, searchFormProps, setFilters, filters, tableQueryResult, setSorters} = useTable<IRemoteCheck>({
        resource: "remote-checks",
        meta: {populate: 'seller'},
        pagination: {
            pageSize: 15,
            mode: "server"
        },
        filters: {
            permanent: [
                {
                    field: "seller.id",
                    operator: "eq",
                    value: store.id,
                },
            ]
        }
    });

    const [idShow, setIdShow] = useState(0);
    const {data: dataShow, refetch} = useOne<IRemoteCheck>({
        resource: "remote-checks",
        meta: {populate: 'seller'},
        queryOptions: {
            enabled: false
        },
        id: Number(idShow)
    });

    const {
        modalProps,
        formProps,
        show,
        close,
        submit,
        id,
        onFinish
    } = useModalForm<IRemoteCheck>({
        action: "create",
        resource: "remote-checks",
        redirect: false,
        // onMutationSuccess: ((e: any) => { handleSubmit(e) }),
        meta: {populate: '*'},
        warnWhenUnsavedChanges: false,
    });

    const {
        modalProps: editModalProps,
        formProps: editFormProps,
        show: showEditForm,
        close: closeEditForm,
        submit: submitEditForm,
        id: idEdit,
        onFinish: onFinishEditForm
    } = useModalForm<IRemoteCheck>({
        action: "edit",
        resource: "remote-checks",
        redirect: false,
        // onMutationSuccess: ((e: any) => { handleSubmit(e) }),
        meta: {populate: '*'},
        warnWhenUnsavedChanges: false,
    });

    const [form] = Form.useForm();
    const createRemoteCheck = useCreate();
    const handleSubmit = (e: any) => {
        console.log('Nuovi Valori: ', e);
        onFinish(e).then( (res: any) => {
            e.seller = store.id;
            createRemoteCheck.mutateAsync({
                resource: 'remote-checks',
                successNotification: false,
                values: e
            }).then(() => {
                console.log('Remote Check Creato Correttamente');
            })
        });
        close()
    }

    const { show: showModal, close: closeModal, modalProps: showModalProps } = useModal();
    const showModalView = (id: any) => {
        setIdShow(id);
        setTimeout(() => {
            refetch().then( r => {
                console.log(r);
                showModal();
            })
        }, 1000)

    }

    return (
        <List
            title={'Remote Check'}
            headerProps={{
                extra: [
                    <>
                        <CanAccess
                            resource="remote-checks"
                            action="create"
                        >
                            <Row>
                                <Col style={{ marginRight: 10}}>
                                    <Button type={'primary'} style={{
                                        backgroundColor: 'green'
                                    }} onClick={() => show()}>Nuovo Controllo</Button>
                                </Col>
                            </Row>
                        </CanAccess>

                    </>
                ],
            }}
        >
            <Table size="small" {...tableProps}
                   key="id"
                   rowKey="id"
                   sticky={false}
            >
                <Table.Column
                    dataIndex="descrizione"
                    key="descrizione"
                    title="Descrizione"
                    render={(value) => value}
                />
                <Table.Column
                    dataIndex="acquistoTabacchiRegolare"
                    key="acquistoTabacchiRegolare"
                    title={translate('pages.stock.acquistoTabacchiRegolare', 'Acquisto Tabacchi Regolare')}
                    render={(value) => <TagField style={{ color: value ? 'green' : 'red'}} value={value ? 'SI' : 'NO'} />}
                />

                <Table.Column
                    dataIndex="acquistoGevRegolare"
                    key="acquistoGevRegolare"
                    title={translate('pages.stock.acquistoGevRegolare', 'Acquisto GeV Regolare')}
                    render={(value) => <TagField style={{ color: value ? 'green' : 'red'}} value={value ? 'SI' : 'NO'} />}
                />
                <Table.Column
                    dataIndex="entrataPrimaNotaRegolare"
                    key="entrataPrimaNotaRegolare"
                    title={translate('pages.stock.entrataPrimaNotaRegolare', 'Entrata Prima Nota Regolare')}
                    render={(value) => <TagField style={{ color: value ? 'green' : 'red'}} value={value ? 'SI' : 'NO'} />}
                />
                <Table.Column
                    dataIndex="uscitaPrimaNotaRegolare"
                    key="uscitaPrimaNotaRegolare"
                    title={translate('pages.stock.uscitaPrimaNotaRegolare', 'Uscita Prima Nota Regolare')}
                    render={(value) => <TagField style={{ color: value ? 'green' : 'red'}} value={value ? 'SI' : 'NO'} />}
                />

                <Table.Column<{ id: string }>
                    title={translate('pages.users.action', 'Azioni')}
                    dataIndex="actions"
                    render={(_, record) => (
                        <Space>
                            <EditButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                                onClick={() => showEditForm(record.id)}
                            />
                            <ShowButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                                onClick={() => showModalView(record.id)}
                            />
                            <DeleteButton
                                mutationMode={'undoable'}
                                hideText
                                size="small"
                                recordItemId={record.id}
                            />
                        </Space>
                    )}
                />
            </Table>
            <Modal {...modalProps}
                   cancelText={'Annulla'}
                   okText={'Salva'}
                   title={''}
                   footer={[
                       <Button key="submit" type="primary" onClick={formProps.form?.submit}>
                           Submit
                       </Button>,
                   ]}
            >
                <Form {...formProps} layout="vertical" onFinish={handleSubmit}>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                label="Descrizione"
                                name="descrizione"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Title level={4}>Check Acquisto Tabacchi</Title>
                    <Row gutter={24}>
                        <Col span={4}>
                            <Form.Item
                                label="Regolare"
                                name="acquistoTabacchiRegolare"
                                valuePropName={'checked'}
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Switch/>
                            </Form.Item>
                        </Col>
                        <Col span={16}>
                            <Form.Item
                                label="Anomalia"
                                name="anomaliaAcquistoTabacchi"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input.TextArea rows={5} cols={150}/>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                label="Grave Anomalia"
                                name="graveAnomaliaAcquistoTabacchi"
                                valuePropName={'checked'}
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Switch/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <hr/>
                    <Title level={4}>Check Acquisto GeV</Title>
                    <Row gutter={24}>
                        <Col span={4}>
                            <Form.Item
                                label="Regolare"
                                name="acquistoGevRegolare"
                                valuePropName={'checked'}
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Switch/>
                            </Form.Item>
                        </Col>
                        <Col span={16}>
                            <Form.Item
                                label="Anomalia"
                                name="anomaliaAcquistoGev"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input.TextArea rows={5} cols={150}/>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                label="Grave Anomalia"
                                name="graveAnomaliaAcquistoGev"
                                valuePropName={'checked'}
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Switch/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <hr/>
                    <Title level={4}>Check Entrata Prima Nota</Title>
                    <Row gutter={24}>
                        <Col span={4}>
                            <Form.Item
                                label="Regolare"
                                name="entrataPrimaNotaRegolare"
                                valuePropName={'checked'}
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Switch/>
                            </Form.Item>
                        </Col>
                        <Col span={16}>
                            <Form.Item
                                label="Anomalia"
                                name="anomaliaEntrataPrimaNota"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input.TextArea rows={5} cols={150}/>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                label="Grave Anomalia"
                                name="graveAnomaliaEntrataPrimaNota"
                                valuePropName={'checked'}
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Switch/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <hr/>
                    <Title level={4}>Check Uscita Prima Nota</Title>
                    <Row gutter={24}>
                        <Col span={4}>
                            <Form.Item
                                label="Regolare"
                                name="uscitaPrimaNotaRegolare"
                                valuePropName={'checked'}
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Switch/>
                            </Form.Item>
                        </Col>
                        <Col span={16}>
                            <Form.Item
                                label="Anomalia"
                                name="anomaliaUscitaPrimaNota"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input.TextArea rows={5} cols={150}/>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                label="Grave Anomalia"
                                name="graveAnomaliaUscitaPrimaNota"
                                valuePropName={'checked'}
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Switch/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
            <Modal {...editModalProps}
                   cancelText={'Annulla'}
                   okText={'Salva'}
                   title={''}
                   footer={[
                       <Button key="submit" type="primary" onClick={editFormProps.form?.submit}>
                           Submit
                       </Button>,
                   ]}
            >
                <Form {...editFormProps} layout="vertical">
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                label="Descrizione"
                                name="descrizione"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Title level={4}>Check Acquisto Tabacchi</Title>
                    <Row gutter={24}>
                        <Col span={4}>
                            <Form.Item
                                label="Regolare"
                                name="acquistoTabacchiRegolare"
                                valuePropName={'checked'}
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Switch/>
                            </Form.Item>
                        </Col>
                        <Col span={16}>
                            <Form.Item
                                label="Anomalia"
                                name="anomaliaAcquistoTabacchi"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input.TextArea rows={5} cols={150}/>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                label="Grave Anomalia"
                                name="graveAnomaliaAcquistoTabacchi"
                                valuePropName={'checked'}
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Switch/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <hr/>
                    <Title level={4}>Check Acquisto GeV</Title>
                    <Row gutter={24}>
                        <Col span={4}>
                            <Form.Item
                                label="Regolare"
                                name="acquistoGevRegolare"
                                valuePropName={'checked'}
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Switch/>
                            </Form.Item>
                        </Col>
                        <Col span={16}>
                            <Form.Item
                                label="Anomalia"
                                name="anomaliaAcquistoGev"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input.TextArea rows={5} cols={150}/>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                label="Grave Anomalia"
                                name="graveAnomaliaAcquistoGev"
                                valuePropName={'checked'}
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Switch/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <hr/>
                    <Title level={4}>Check Entrata Prima Nota</Title>
                    <Row gutter={24}>
                        <Col span={4}>
                            <Form.Item
                                label="Regolare"
                                name="entrataPrimaNotaRegolare"
                                valuePropName={'checked'}
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Switch/>
                            </Form.Item>
                        </Col>
                        <Col span={16}>
                            <Form.Item
                                label="Anomalia"
                                name="anomaliaEntrataPrimaNota"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input.TextArea rows={5} cols={150}/>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                label="Grave Anomalia"
                                name="graveAnomaliaEntrataPrimaNota"
                                valuePropName={'checked'}
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Switch/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <hr/>
                    <Title level={4}>Check Uscita Prima Nota</Title>
                    <Row gutter={24}>
                        <Col span={4}>
                            <Form.Item
                                label="Regolare"
                                name="uscitaPrimaNotaRegolare"
                                valuePropName={'checked'}
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Switch/>
                            </Form.Item>
                        </Col>
                        <Col span={16}>
                            <Form.Item
                                label="Anomalia"
                                name="anomaliaUscitaPrimaNota"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input.TextArea rows={5} cols={150}/>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                label="Grave Anomalia"
                                name="graveAnomaliaUscitaPrimaNota"
                                valuePropName={'checked'}
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Switch/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
            <Modal {...showModalProps} footer={<></>}>
                <Title level={2}>Dettaglio: {dataShow?.data.descrizione}</Title>
                <hr/>
                <Row gutter={24}>
                    <Col span={24}>
                        <Title level={4}>Check Acquisto Tabacchi</Title>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <Title level={5}>Regolare: <TagField style={{ color: dataShow?.data.acquistoTabacchiRegolare ? 'green' : 'red'}}
                            value={dataShow?.data.acquistoTabacchiRegolare ? 'SI' : 'NO'}/></Title>
                    </Col>
                    <Col span={12}>
                        <Title level={5}>Anomalia Grave: <TagField style={{ color: dataShow?.data.graveAnomaliaAcquistoTabacchi ? 'green' : 'red'}}
                            value={dataShow?.data.graveAnomaliaAcquistoTabacchi ? 'SI' : 'NO'}/></Title>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Title level={4}>Note: </Title>
                        <span>{dataShow?.data.anomaliaAcquistoTabacchi}</span>
                    </Col>
                </Row>
                <hr/>
                <Row gutter={24}>
                    <Col span={24}>
                        <Title level={4}>Check Acquisto GeV</Title>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <Title level={5}>Regolare: <TagField style={{ color: dataShow?.data.acquistoGevRegolare ? 'green' : 'red'}}
                            value={dataShow?.data.acquistoGevRegolare ? 'SI' : 'NO'}/></Title>
                    </Col>
                    <Col span={12}>
                        <Title level={5}>Anomalia Grave: <TagField style={{ color: dataShow?.data.graveAnomaliaAcquistoGev ? 'green' : 'red'}}
                            value={dataShow?.data.graveAnomaliaAcquistoGev ? 'SI' : 'NO'}/></Title>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Title level={4}>Note: </Title>
                        <span>{dataShow?.data.anomaliaAcquistoGev}</span>
                    </Col>
                </Row>
                <hr/>
                <Row gutter={24}>
                    <Col span={24}>
                        <Title level={4}>Check Entrata Prima Nota</Title>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <Title level={5}>Regolare: <TagField style={{ color: dataShow?.data.entrataPrimaNotaRegolare ? 'green' : 'red'}}
                            value={dataShow?.data.entrataPrimaNotaRegolare ? 'SI' : 'NO'}/></Title>
                    </Col>
                    <Col span={12}>
                        <Title level={5}>Anomalia Grave: <TagField style={{ color: dataShow?.data.graveAnomaliaEntrataPrimaNota ? 'green' : 'red'}}
                            value={dataShow?.data.graveAnomaliaEntrataPrimaNota ? 'SI' : 'NO'}/></Title>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Title level={4}>Note: </Title>
                        <span>{dataShow?.data.anomaliaEntrataPrimaNota}</span>
                    </Col>
                </Row>
                <hr/>
                <Row gutter={24}>
                    <Col span={24}>
                        <Title level={4}>Check Uscita Prima Nota</Title>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <Title level={5}>Regolare: <TagField style={{ color: dataShow?.data.uscitaPrimaNotaRegolare ? 'green' : 'red'}}
                            value={dataShow?.data.uscitaPrimaNotaRegolare ? 'SI' : 'NO'}/></Title>
                    </Col>
                    <Col span={12}>
                        <Title level={5}>Anomalia Grave: <TagField style={{ color: dataShow?.data.uscitaPrimaNotaRegolare ? 'green' : 'red'}}
                            value={dataShow?.data.graveAnomaliaUscitaPrimaNota ? 'SI' : 'NO'}/></Title>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Title level={4}>Note: </Title>
                        <span>{dataShow?.data.anomaliaUscitaPrimaNota}</span>
                    </Col>
                </Row>
            </Modal>
        </List>
    );
};
