import React, {useState} from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import {DateField, Edit, useForm} from '@refinedev/antd';

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import { Form, Input, Select, Row, Col, Switch } from "antd";

import {IAlertRestock, IGroup, IRestock} from 'interfaces';
export const RestockEdit: React.FC<IResourceComponentsProps> = () => {
    const {formProps, saveButtonProps} = useForm<IAlertRestock>({
        meta: {
            populate: "seller, turn, stock, stock.product, stock.product.category, turn.user"
        },
    });


    return (
        <Edit saveButtonProps={saveButtonProps} >
            <Form
                {...formProps}
                layout="vertical"
            >
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            label="Description"
                            name="description"
                        >
                            <Input readOnly={true}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={16}>
                        <Form.Item
                            label="Note"
                            name="note"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Data"
                            name="createdAt"
                        >
                            <DateField value={'createdAt'} format={'DD-MM-YYYY - HH:mm:ss'} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Edit>
    );
};
