import React, {FC, useContext, useEffect, useState} from 'react';
import { Typography } from "antd";

import { ISeller } from "interfaces";
import {StoreContext} from '../../contexts/StoreContext';
import {UtilityHelper} from '../../helper/utility';
import {API_URL} from '../../constants';
import {useGetIdentity, useOne} from '@refinedev/core';
import {ColorModeContext} from '../../contexts';
const { Text } = Typography;
interface SelectProps {
    onSelect: () => void;
    theme?: "light" | "dark";
};

export const StoreSelect: FC<SelectProps> = ( props ) => {
    const {value, value2, value3} = useContext(StoreContext);
    const [store, setStore] = value;
    const [userPermission, setUserPermission] = value2;
    const [autoTurn, setAutoTurn] = value3;
    const { data: userIdentity } = useGetIdentity<any>();
    const mode = useContext(ColorModeContext);
    const utilityHelper = UtilityHelper(API_URL + "/api");

    useEffect(() => {
        utilityHelper.seller().then( (r: any) => {
            setStore(r);
        });
        utilityHelper.permission().then( (u: any) => {
            setUserPermission(u);
        });
        utilityHelper.getAutoTurn().then( (a: any) => {
            console.log(a.autoTurn);
            setAutoTurn(a.autoTurn ? a.autoTurn : false);
        });
    }, []);

    return (
        <Text style={{ color: props.theme === 'light' ? 'black' : 'whitesmoke'}}>{(userIdentity?.username)}</Text>
    );
};
