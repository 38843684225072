import {CrudFilters, getDefaultFilter, IResourceComponentsProps, useExport, useTranslate} from '@refinedev/core';
import { CreateButton, DeleteButton, EditButton, ExportButton, List, useTable } from "@refinedev/antd";

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import {Button, Col, Form, Input, Popover, Row, Select, Space, Table} from 'antd';
import React, {useState} from 'react';
import {ISeller} from '../../interfaces';

export const SellerList: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const {tableProps, searchFormProps, filters} = useTable<ISeller>({
        resource: "sellers",
        meta: {populate: '*'},
        onSearch: (params: any) => {
            console.log(params)
            const productFilters: CrudFilters = [];
            localStorage.setItem('filters', JSON.stringify(params));
            productFilters.push(
                {
                    operator: "or",
                    value: [
                        {
                            field: "description",
                            operator: "contains",
                            value: (params?.description) ? params?.description : ''
                        },
                        {
                            field: "email",
                            operator: "contains",
                            value: (params?.description) ? params?.description : ''
                        }
                    ]
                },
                {
                    field: "category][id]",
                    operator: "contains",
                    value: params?.category,
                },
            );

            return productFilters;
        }
    });

    const {triggerExport, isLoading: exportLoading} = useExport<ISeller>();
    const [visible, setVisible] = useState(false);
    const handleVisibleChange = (newVisible: boolean) => {
        setVisible(newVisible);
    };
    const [form] = Form.useForm();

    const resetFilter = () => {
        form.resetFields(['fields'])
        console.log('Reset:', )
    };

    // console.log(tableProps)
    return (
            <List
                title={translate('pages.sellers.title', 'Sellers')}
                headerProps={{
                    extra: [
                        <>
                            <Row>
                               <Col style={{ marginRight: 10}}>
                                   <Popover
                                       placement="leftTop"
                                       style={{
                                           float: "right"
                                       }}
                                       content={
                                           <Form layout="horizontal"
                                                 onLoad={() => {
                                                     searchFormProps.form?.submit();
                                                 }}
                                                 onValuesChange={() => {
                                                     searchFormProps.form?.submit();
                                                 }}
                                                 initialValues={{
                                                     description: getDefaultFilter("description", filters, "contains"),
                                                     category: getDefaultFilter("category.id", filters, "in"),
                                                 }}
                                                 {...searchFormProps}
                                                 labelCol={{ span: 8 }}
                                                 wrapperCol={{ span: 16 }}
                                           >
                                               <Form.Item
                                                   label="Cerca"
                                                   name="description"
                                               >
                                                   <Input
                                                       allowClear={true}
                                                       placeholder="Cerca"
                                                       prefix={<Icons.SearchOutlined />}
                                                   />
                                               </Form.Item>
                                               <Form.Item
                                                   label="Categoria"
                                                   name="category"
                                               >
                                               </Form.Item>
                                               <Form.Item>
                                                   <Space>
                                                       <Button htmlType="submit" type="primary" onClick={() => handleVisibleChange(false)}>
                                                           Filter
                                                       </Button>
                                                       <Button htmlType="button" type="primary" onClick={resetFilter}>
                                                           Reset
                                                       </Button>
                                                   </Space>

                                               </Form.Item>
                                           </Form>}
                                       title="Custom Filter"
                                       trigger="click"
                                       visible={visible}
                                       onVisibleChange={handleVisibleChange}
                                   >
                                       <Button type="primary" style={{marginBottom: 15}}>Filter</Button>
                                   </Popover>
                               </Col>
                                <Col>
                                    <CreateButton/>,
                                    <ExportButton
                                        onClick={triggerExport}
                                        loading={exportLoading}
                                    />
                                </Col>

                            </Row>
                        </>
                    ],
                }}
            >
                <Table size="small" {...tableProps} rowKey="id"
                       onRow={(record) => {
                           return {
                               onClick: () => {
                                   // console.log("user", record);
                               },
                           };
                       }}
                       sticky={true}

                       pagination={{
                           ...tableProps.pagination,
                           showSizeChanger: true,
                       }}>
                    {/*<Table.Column dataIndex="id" key="id" title="ID"/>*/}
                    <Table.Column
                        dataIndex="company"
                        key="company"
                        title={translate('pages.sellers.name', 'Company')}
                    />
                    <Table.Column
                        dataIndex="name"
                        key="name"
                        title={translate('pages.sellers.name', 'Name')}
                    />
                    <Table.Column
                        dataIndex="surname"
                        key="surname"
                        title={translate('pages.sellers.surname', 'Surname')}
                    />
                    <Table.Column dataIndex="email" key="email" title={translate('pages.sellers.email', 'Email')}/>
                    <Table.Column<{ id: string }>
                        title={translate('pages.sellers.action', 'Action')}
                        dataIndex="actions"
                        render={(_, record) => (
                            <Space>
                                <EditButton
                                    hideText
                                    size="small"
                                    recordItemId={record.id}
                                />
                                <DeleteButton
                                    mutationMode={'undoable'}
                                    hideText
                                    size="small"
                                    recordItemId={record.id}
                                />
                            </Space>
                        )}
                    />

                </Table>
            </List>
    );
};
