import {
    IResourceComponentsProps,
    useCreate,
    useCustom, useCustomMutation,
    useDelete,
    useGetIdentity,
    useList,
    useMany,
    useNotification,
    useTranslate,
    useUpdate, useUpdateMany,
} from '@refinedev/core';

import {
    DateField,
    DeleteButton,
    EditButton,
    List,
    TagField,
    useForm,
    useModal,
    useSelect,
    useTable
} from '@refinedev/antd';

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import {
    Button,
    Col, Form,
    Input, InputRef, message, Modal, Popconfirm,
    Row,
    Select,
    SelectProps,
    Space, Spin, Switch,
    Table,
    Tag,
    Typography,
} from 'antd';

import React, {createRef, useContext, useEffect, useRef, useState} from 'react';
import {IAlertRestock, IProduct, IRestock, ISaleMovement, IStock} from '../../interfaces';
import {StoreContext} from '../../contexts/StoreContext';
import {SocketContext} from '../../contexts/socket';
import {DeviceContext} from '../../contexts/DeviceContext';
import {TurnContext} from '../../contexts/TurnContext';
import dayjs from 'dayjs';
import {UtilityHelper} from '../../helper/utility';
import {API_URL} from '../../constants';
import {CheckOutlined, CloseOutlined} from '@ant-design/icons';


const {Title} = Typography;
const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone')
export const VenditaList: React.FC<IResourceComponentsProps> = () => {
    dayjs.extend(utc)
    dayjs.extend(timezone)
    dayjs.locale('it') // use locale globally
    dayjs().locale('it').format() // use locale in a specific instance
    const translate = useTranslate();
    const {value, value2} = useContext(StoreContext);
    const [store, setStore] = value;
    const [userPermission, setUserPermission] = value2;
    const [turn] = useContext(TurnContext);
    const [device] = useContext(DeviceContext);
    const socket = useContext(SocketContext);
    const {open, close} = useNotification();
    const {data: userIdentity} = useGetIdentity<any>();
    const [tot, setTot] = useState<number>(0);
    const [productId, setProductId] = useState<number>();
    const [detailCategory, setDetailCategory] = useState<any>([]);
    // SEARCH SIA PER DESCRIZIONE CHE PER BARCODE
    const [textSearch, setTextSearch] = useState<any>();
    const [isBarcode, setIsBarcode] = useState(false);
    const {selectProps, queryResult, defaultValueQueryResult} = useSelect<IStock>({
        resource: 'stocks',
        meta: {
            populate: 'product, seller, product.barcodes'
        },

        queryOptions: {
            enabled: true
        },

        filters: [
            {
                operator: 'or',
                value: [
                    {
                        field: 'product][barcodes][code]',
                        operator: 'eq',
                        value: textSearch
                    },
                    {
                        field: 'product][barcode_packages][code]',
                        operator: 'eq',
                        value: textSearch
                    },
                    {
                        field: 'product][description]',
                        operator: 'contains',
                        value: textSearch
                    }
                ]
            },
            {
                field: 'seller][id]',
                operator: 'eq',
                value: store.id
            },
            {
                field: '[isRadiato]',
                operator: 'eq',
                value: 0
            }
        ],

        pagination: {
            mode: "server"
        }
    });

    const {tableProps, tableQueryResult} = useTable<ISaleMovement>({
        resource: 'sale-movements',
        queryOptions: {
            enabled: true
        },
        meta: {
            populate: 'product, product.category, product.barcodes'
        },

        pagination: {
            mode: 'server',
            pageSize: 15
        },

        filters: {
            permanent: [
                {
                    field: 'seller][id]',
                    operator: 'eq',
                    value: store.id
                },
                {
                    field: 'isClosed',
                    operator: 'eq',
                    value: false
                },
                {
                    field: 'isDeleted',
                    operator: 'eq',
                    value: false
                },
                {
                    field: 'isPatent',
                    operator: 'eq',
                    value: false
                },
                {
                    field: 'users][id]',
                    operator: (userPermission === 'operator' ? 'gte' : 'eq'),
                    value: (userPermission === 'operator' ? 0 : userIdentity?.id)
                },
            ]
        },

        sorters: {
            permanent: [
                {
                    field: 'createdAt',
                    order: 'desc'
                },
                {
                    field: 'product.category.id',
                    order: 'asc'
                },
                {
                    field: 'id',
                    order: 'desc'
                },
            ]
        }
    });

    const handleSearch = (newValue: string) => {
        setTextSearch(newValue);
    };

    const addSaleMovement = useCreate<ISaleMovement>();
    const updateStock = useUpdate<IStock>();
    const updateRestock = useUpdate<IRestock>();
    const restockList = useList<IRestock>({
        resource: 'restocks',

        queryOptions: {
            enabled: false
        },

        meta: {
            populate: 'product, seller, stock'
        },

        filters: [
            {
                field: 'seller][id]',
                operator: 'eq',
                value: store.id
            },
            {
                field: 'product][id]',
                operator: 'eq',
                value: productId
            },
            {
                field: 'isRiassortito',
                operator: 'eq',
                value: false
            },
        ]
    })

    const product = useList({
        resource: 'stocks',

        meta: {
            populate: 'product, seller, product.barcodes'
        },

        queryOptions: {
            enabled: false
        },

        filters: [
            {
                field: 'product][id]',
                operator: 'eq',
                value: productId
            },
            {
                field: 'seller][id]',
                operator: 'eq',
                value: store.id
            }
        ]
    })

    const {data: getTotaliVendite, refetch} = useList<any>({
        resource: "custom/getTotaliVendite",
        queryOptions: {
            enabled: true
        },
        config: {
          filters: [
              {
                  field: 'seller][id]',
                  operator: 'eq',
                  value: store.id
              }
          ]
        },
    });

    // const {data: getTotaliVenditeConto, refetch: refetchConto} = useList<any>({
    //     resource: "custom/getTotaliVenditeConto",
    //     queryOptions: {
    //         enabled: true
    //     },
    //     config: {
    //         filters: [
    //             {
    //                 field: 'seller][id]',
    //                 operator: 'eq',
    //                 value: store.id
    //             }
    //         ]
    //     },
    // });

    const dataMovement = useRef<any>([]);

    // console.log('Dispositivo: ', device);
    const sendToEsp32Cart = useCustomMutation();
    const handleSelect = (value: any) => {
        // console.log(device)
        const d: any = JSON.parse(localStorage.getItem('device') || '');
        if (d) {
            setIsBarcode(false);
            sendToEsp32Cart.mutateAsync({
                method: 'post',
                url: API_URL + '/api/custom/esp32toCart',
                values: {
                    device: d.serial,
                    barcode: value
                }
            }).then((res: any) => {
                setTextSearch('');
                setBarcode('');
            })
        } else {
            open?.({
                type: 'error',
                message: 'Ricordati di Selezionare il Dispositivo...',
                description: 'Attenzione',
            });
        }

    }
    const handleSelectManual = (value: any) => {
        // console.log(value);
        // console.log(device)
        const d: any = JSON.parse(localStorage.getItem('device') || '');
        setIsBarcode(false);
        if (turn && turn.dataIn !== '') {
            if (d) {
                const t: any = queryResult.data?.data.filter(x => x.product.id === value);
                if (t && t.length > 0) {
                    const barCode = t[0].product.barcodes[0].code;
                    console.log('PRODOTTO: ', barCode);
                    handleSelect(barCode);
                }
            } else {
                open?.({
                    type: 'error',
                    message: 'Ricordati di Selezionare il Dispositivo...',
                    description: 'Attenzione',
                });
            }
                // console.log(value);
                // console.log(queryResult);
                // Controllo quantità prodotto
                //const c: any = queryResult.data?.data.findIndex(x => x.product.id === value);

        } else {
            open?.({
                type: 'error',
                message: 'Ricordati di Aprire il Turno...',
                description: 'Attenzione',
            });
        }
    };

    const deleteSaleMovement = useUpdate<ISaleMovement>();

    const { confirm } = Modal;
    const inputRef: any = useRef<InputRef>(null);
    const showConfirm = (data: any) => {
        confirm({
            title: 'Per effettuare la variazione è richiesta una password...',
            icon: <Icons.ExclamationCircleFilled />,
            content: <>
                <Input type={'password'} ref={inputRef} placeholder={'Password...'}></Input>
            </>,
            onOk() {
                if (inputRef.current.input.value === passwordSeller) {
                    localStorage.setItem('passVendite', inputRef.current.input.value);
                    deleteMovement(data);
                };
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    const handleDelete = (data: any) => {
        // if (localStorage.getItem('passVendite') === passwordSeller) {
        //     deleteMovement(data);
        // } else {
        //     showConfirm(data)
        // }
        deleteMovement(data);
        dataMovement.current = [...dataMovement.current.filter((x: any) => x.id !== data.id)];
    }
    const deleteMovement = (data: any) => {
        console.log('Cancello Movimento: ', data);
        setProductId(data.product.id);
        // console.log('Prodotto Movimento: ', data.product.id)
        deleteSaleMovement.mutateAsync({
            resource: 'sale-movements',
            successNotification: false,
            id: data.id,
            values: {
                isDeleted: true,
            }
        }).then((res: any) => {
            // Trovo Prodotto in Stock
            product.refetch().then((res: any) => {
                // console.log(res);
                if (res && res.data.data && res.data.data.length > 0) {
                    // console.log(res.data.data[0].id);
                    // Aggiorno Qta Stock
                    updateStock.mutateAsync({
                        successNotification: false,
                        resource: 'stocks',
                        id: res.data.data[0].id,
                        values: {
                            scansiaPz: res.data.data[0].scansiaPz + 1
                        }
                    }).then(resUpd => {
                        // Cerco Articolo in Riassortimento
                        console.log('Cerco Articolo in Riassortimento');
                        restockList.refetch().then(resStock => {
                            // console.log('Restock: ', resStock);
                            if (resStock && resStock.data && resStock.data.data.length > 0) {
                                let qtaMancaStock;
                                const mancaStock = (res.data.data[0].scansiaPz + 1);
                                // console.log(mancaStock);
                                // const mancaStock = ((res.data.data[0].scansiaPz - 1)  - res.data.data[0].scansiaStock) % res.data.data[0].nrPezziStecca;
                                // const mancaStock = (res.data.data[0].scansiaStock - (res.data.data[0].scansiaPz + resStock.data.data[0].qtaRiassortimento) % res.data.data[0].nrPezziStecca);
                                // console.log('MancaStock: ', mancaStock);
                                if (mancaStock > res.data.data[0].scansiaStock) {
                                    // qtaMancaStock= Math.trunc((res.data.data[0].scansiaStock - res.data.data[0].scansiaPz) / res.data.data[0].nrPezziStecca) + 1;
                                    // Eseguo Update riga Riassortimento
                                    updateRestock.mutateAsync({
                                        resource: 'restocks',
                                        successNotification: false,
                                        id: resStock.data.data[0]?.id,
                                        values: {
                                            isRiassortito: false,
                                            qtaRiassortimento: 0,
                                            dataSottoscorta: null
                                        }
                                    }).then(updateRes => {
                                        // console.log(updateRes);
                                    })
                                }
                            } else {
                                console.log('Error');
                            }
                        });
                        setTimeout(() => {
                            setBarcode('');
                            inputElement?.current?.focus();
                        }, 500)
                        open?.({
                            type: 'success',
                            message: 'Articolo Tornato nell\'espositore',
                            description: 'Info!',
                        });
                        localStorage.removeItem('passVendite');
                    })
                }
            });
        })

    }
    // const updateTot = () => {
    //     if (tableQueryResult && tableQueryResult.data && tableQueryResult.data?.data.length > 0) {
    //         setTot(parseFloat(Number(tableQueryResult.data?.data.map(item => item.product.price).reduce((prev, next) => prev + next)).toFixed(2)));
    //     } else {
    //         setTot(0)
    //     }
    //     // GROUP BY CATEGORY
    //     const tempGroup: any = tableQueryResult.data?.data.reduce((r: any, a: any) => {
    //         // console.log("a", a);
    //         // console.log("r", r);
    //         r[a.product.category.description] = [...(r[a.product.category.description] || []), a];
    //         return r;
    //     }, {})
    //
    //     let priceCategory = [];
    //
    //     for (let g in tempGroup) {
    //         priceCategory.push({
    //             qta: tempGroup[g].length,
    //             category: g,
    //             tot: parseFloat(Number(tempGroup[g].map((item: any) => item.product.price).reduce((prev: number, next: number) => prev + next)).toFixed(2))
    //         })
    //     }
    //     // console.log(priceCategory)
    //     setDetailCategory(priceCategory);
    // }

    const updateDataMovement = (data: any) => {
        // console.log("DATI NUOVI: ", data);
        // console.log("DATI ESISTENTI: ", dataMovement.current);
        for (let d of data) {
            dataMovement.current = [d, ...dataMovement.current];
        }
        // console.log("DATI ESISTENTI: ", dataMovement.current);
        refetch().then();
    }

    useEffect(() => {
        // setDataMovement(tableQueryResult.data?.data);
        console.log("RIPRENDO DATI...")
        dataMovement.current = tableQueryResult.data?.data;
    }, [tableQueryResult.isFetching])

    // useEffect(() => {
    //     console.log("RIPRENDO DATI 2...")
    //     if (!dataMovement.current) {
    //         console.log(dataMovement.current);
    //     }
    // })

    useEffect(() => {
        socket.on('sendSaleMovement', (data: any, error: any) => {
            updateDataMovement(data);
            console.log('Ricevuto Aggiornamento Cart: ', data);
        });
        socket.on('sendTurnoAlert', (data: any, error: any) => {
            open?.({
                type: 'error',
                message: 'Attenzione Devi Aprire il Turno...',
                description: '',
            });
            // console.log('Ricevuto Aggiornamento Cart: ', data);
        });
        socket.on('sendBarcodeNotFound', (data: any, error: any) => {
            // console.log('Ricevuto Barcode Not Found: ', data);
            open?.({
                type: 'error',
                message: 'Barcode non Trovato...',
                description: data,
            });
        });
        return () => {
            socket.off('sendSaleMovement');
            socket.off('sendTurnoAlert');
            socket.off('sendBarcodeNotFound');
        };
    }, []);

    const inputElement: any = useRef(null) || '';
    const handleFocus = (event: any) => {
        event.target.select();
    };

    useEffect(() => {
        setTimeout(() => {
            inputElement?.current?.focus();
        }, 300)
    }, []);

    const barcodeSearch = (e: any) => {
        // console.log('Barcode:', e);
        // setBarcode(e);
        setIsBarcode(true);
        setTextSearch(e);
        handleSelect(e);
    };

    const [barcode, setBarcode] = useState('');
    // useEffect(() => {
    //     if (isBarcode) {
    //         // console.log(queryResult);
    //         setTimeout(() => {
    //             if (queryResult && queryResult.data && queryResult.data.data.length > 0) {
    //                 inputElement?.current?.select();
    //                 handleSelect(queryResult.data?.data[0]?.product?.id);
    //             } else {
    //                 setIsBarcode(false);
    //                 setTextSearch('');
    //                 // inputElement?.current?.select();
    //                 setBarcode('');
    //                 open?.({
    //                     type: 'error',
    //                     message: 'Articolo non Trovato',
    //                     description: 'Attenzione!',
    //                 });
    //             }
    //         }, 300)
    //     }
    // }, [queryResult.data?.data?.length])

    // console.log(isBarcode);
    const allOptions = [
        ...(queryResult.data?.data || []),
        ...(defaultValueQueryResult.data?.data || [])
    ];

    // console.log(tableQueryResult.isSuccess, tableQueryResult.isFetched, tableQueryResult.isFetching, tableQueryResult.isRefetching)

    const updateSaleMovement = useUpdate<ISaleMovement>();
    const updateCheck = (record: any, e: any) => {
        // console.log(record, dataMovement.current)
        // const findIndex = dataMovement.current.findIndex((item: any) => item.id === record.id);
        // // console.log(findIndex);
        // dataMovement.current[findIndex].isCheck = true;
        // record.isCheck = e;
        // dataMovement.current = [...dataMovement.current, record];
        updateSaleMovement.mutateAsync({
            resource: 'sale-movements',
            successNotification: false,
            id: record.id,
            values: {
                isCheck: e,
                idStock: record.idStock
            }
        }).then((res: any) => {
            console.log(res);
            tableQueryResult.refetch().then((res: any) => {
                dataMovement.current = res.data?.data;
            })
        });
    }


    const [loadMoltiplicatore, setLoadMoltiplicatore] = useState(false);
    const waitDelay = () => {
        return new Promise(resolve => setTimeout(resolve, 250));
    }

    const forLoop = async (n: number, code: any) => {
        console.log("Start");
        setLoadMoltiplicatore(true);
        showModal();
        for (let i = 1; i < n; i++) {
            await waitDelay();
            // console.log(i);
            handleSelect(code);
        }
        setTimeout(() => {
            inputElement?.current?.focus();
        }, 1000)
        setLoadMoltiplicatore(false);
        closeModal();
        console.log("End");
    };
    const sendMoltiplicatore = (moltiplicatore: number) => {
        // console.log(tableQueryResult.data?.data[0].product.barcodes[0].code);
        // console.log(dataMovement.current[0].product.barcodes[0].code);
        const code = dataMovement.current[0]?.product?.barcodes[0]?.code;
        if (code) {
            forLoop(moltiplicatore, code).then((res) => {})
        }
    }

    const { show: showModal, close: closeModal, modalProps } = useModal();

    const utilityHelper = UtilityHelper(API_URL + "/api");
    const [passwordSeller, setPasswordSeller] = useState('');
    useEffect(() => {
        utilityHelper.seller().then( (res: any) => {
            setPasswordSeller(res.password);
        });
    }, []);

    const { show: showModalConto, close: closeModalConto, modalProps: modalPropsConto } = useModal();
    const updateConto = useCreate();
    const closeConto = () => {
        updateConto.mutateAsync({
            resource: 'custom/chiudiConto',
            successNotification: false,
            values: {
                user: userIdentity?.id,
                seller: store.id
            }
        }).then((res: any) => {
            // message.info("Conto Chiuso Correttamente");
            setTimeout(() => {
                showModalConto()
            }, 500);
            setTimeout(() => {
                closeModalConto();
                tableQueryResult.refetch().then((res: any) => {
                    dataMovement.current = [...res?.data?.data];
                });
                refetch().then();
            }, 5000)

        })
    }

    return (
        <List
            title={<>
                <Row gutter={24}>
                    <Col span={2}>
                        <Title level={4}>{translate('pages.vendita.title', (userPermission === 'operator' ? 'Vendita' : (!turn?.dataIn || turn?.dataOut) ? 'Vendita -> Ricordati di Aprire il Turno' : ''))}</Title>
                    </Col>
                    <Col span={22}>
                        <Button hidden={(!turn?.dataIn)} type={'primary'} style={{ background: 'pink', color: 'black', width: 160, height: 70}} onClick={closeConto} >Chiudi Conto</Button>
                    </Col>
                </Row>
            </>}
        >
            <Modal {...modalPropsConto} okText={'Conferma'} cancelText={'Annulla'} onOk={closeConto} footer={false} centered style={{ top: 150 }}>
                <Row gutter={24}>
                    <Col span={24}>
                        <Title level={2}>Dettaglio Chiusura Conto</Title>
                    </Col>
                </Row>
                <hr/>
                <Row gutter={24}>
                    <Col span={12} style={{textAlign: 'left'}}>
                        <Title level={3} style={{ color: 'yellow'}}>Totale: </Title>
                    </Col>
                    <Col span={12} style={{textAlign: 'right'}}>
                        <Title level={3} style={{ color: 'yellow'}}>{getTotaliVendite?.data?.[0]?.totaliConto?.[0].tot ? Number(parseFloat(getTotaliVendite?.data?.[0]?.totaliConto?.[0].tot).toFixed(2)).toLocaleString('de', {
                            minimumFractionDigits: 2
                        }) : 0} €</Title>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12} style={{textAlign: 'left'}}>
                        <Title level={3} style={{ color: 'yellow'}}>Pezzi: </Title>
                    </Col>
                    <Col span={12} style={{textAlign: 'right'}}>
                        <Title level={3} style={{ color: 'yellow'}}>{getTotaliVendite?.data?.[0]?.totaliConto?.[0].qta}</Title>
                    </Col>
                </Row>
            </Modal>
            <Modal {...modalProps} footer={false}>
                <Row gutter={24}>
                    <Col span={24}>
                        <Title level={5}>Invio Moltiplicatore...</Title>
                    </Col>
                </Row>
                <Row gutter={24} justify={'center'}>
                    <Col span={24}>
                        <Spin spinning={loadMoltiplicatore} />
                    </Col>
                </Row>
            </Modal>
            <div hidden={(userPermission === 'operator' ? false : (!turn?.dataIn || turn?.dataOut))}>
                <Row gutter={24}>
                    <Col span={8}>
                        <Space.Compact style={{ width: '100%' }}>
                            <Input
                                style={{
                                    width: '100%'
                                }}
                                placeholder={'Barcode...'}
                                ref={inputElement}
                                onFocus={(e) => handleFocus(e)}
                                onPressEnter={(e: any) => barcodeSearch(e.target.value)}
                                onChange={(e) => setBarcode(e.target.value)}
                                allowClear={true}
                                value={barcode}
                            />
                            <Button type="primary" onClick={(e: any) => barcodeSearch(barcode)}>Conferma</Button>
                        </Space.Compact>
                    </Col>
                    <Col span={2}>
                        {/*<Space align={'center'}>*/}
                            <Button type={'primary'} block
                                    style={{backgroundColor: '#118a00'}}
                                    onClick={() => sendMoltiplicatore(3)}
                            >x3</Button>
                    </Col>
                    <Col span={2}>
                        {/*<Space align={'center'}>*/}
                        <Button type={'primary'} block
                                style={{backgroundColor: '#0f7301'}}
                                onClick={() => sendMoltiplicatore(4)}
                        >x4</Button>
                    </Col>
                    <Col span={2}>
                            <Button type={'primary'} block
                                    style={{backgroundColor: '#0d6501'}}
                                    onClick={() => sendMoltiplicatore(5)}
                            >x5</Button>
                    </Col>
                    <Col span={2}>
                            <Button type={'primary'} block
                                    style={{backgroundColor: '#0a4d00'}}
                                    onClick={() => sendMoltiplicatore(10)}
                            >x10</Button>
                    </Col>
                    <Col span={8}>
                        <Select
                            style={{
                                width: '100%'
                            }}

                            placeholder={'Ricerca Manuale...'}
                            {...selectProps}
                            options={allOptions.map((p) => ({
                                label: `${p.description}`,
                                value: p.product.id
                            }))}
                            onSearch={handleSearch}
                            // onKeyDown={(e) => handleSearchBarcode(e)}
                            allowClear={true}
                            onSelect={(e) => handleSelectManual(e)}
                        />
                    </Col>
                </Row>
                <br/>
                <Row gutter={24}>
                    <Col span={24}>
                        <Table size={'small'}
                               {...tableProps}
                               // pagination={false}
                               dataSource={dataMovement && dataMovement.current ? dataMovement.current : tableQueryResult.data?.data}
                               tableLayout={'auto'}
                               key={'id'}
                               // scroll={{y: window.innerHeight - 393 + 'px'}}
                               footer={data =>
                                getTotaliVendite?.data?.[0]?.totali.map((x: any) => {
                                    // console.log(x);
                                    return (<>
                                        <TagField value={x?.category + ': ' + x?.qta} color={'gold'}/>
                                            <span style={{
                                                marginRight: 15,
                                                color: 'coral'
                                            }}>{Number(parseFloat(x?.tot).toFixed(2)).toLocaleString('de', {
                                                minimumFractionDigits: 2
                                            })} €</span>
                                        </>)
                                    })
                                }
                               rowClassName={(record, index) => {
                                       return((index === 0)  ? "blu" : record.isCheck ? "greenLight" : record.isStored ? "bluLight" : "")
                               }}
                            >
                            <Table.Column
                                dataIndex="description"
                                key="description"
                                title="Descrizione"
                                width={200}
                            />
                            <Table.Column
                                dataIndex="createdAt"
                                key="createdAt"
                                title="Data"
                                width={100}
                                // sorter={(a: any, b: any) => {
                                //     return ((new Date(a.createdAt).getTime() - (new Date(b.createdAt).getTime())))}}
                                // defaultSortOrder={'descend'}
                                render={(value) => (
                                    <DateField value={dayjs(value)} format={'HH:mm:ss'}/>
                                )}
                            />
                            <Table.Column
                                dataIndex="product"
                                key="product"
                                title="Price"
                                width={100}
                                render={(value) => (
                                    Number(parseFloat(value?.price).toFixed(2)).toLocaleString('de', {
                                        minimumFractionDigits: 2
                                    }) + ' €'
                                )}
                            />
                            <Table.Column
                                dataIndex="qtaEspositore"
                                key="qtaEspositore"
                                width={100}
                                align={'right'}
                                title="Espositore"
                                render={(value) => (
                                    <TagField style={{
                                        color: value && value > 0 ? 'white' : 'red'
                                    }} value={value ? 'Qta ' +  value : ''} />
                                )}
                            />
                            <Table.Column<IStock>
                                dataIndex="dataCheck"
                                key="dataCheck"
                                width={150}
                                align={'center'}
                                title={translate('pages.stock.scansiaPz', 'Data Check')}
                                render={(value) => (
                                    value ? <DateField
                                        className={(Math.floor((new Date().getTime() - new Date(value).getTime()) / (1000 * 3600 * 24)) > 20) ? 'checkEspositore' : ''}
                                        // style={{
                                        //     color: (Math.floor((new Date().getTime() - new Date(value).getTime()) / (1000 * 3600 * 24)) > 20) ? 'red' : 'white'
                                        // }}
                                        value={dayjs(value)} format={'DD/MM/YYYY HH:mm:ss'}/> : <></>
                                )}
                            />
                            <Table.Column
                                dataIndex="isCheck"
                                key="isCheck"
                                align={'center'}
                                title="Check"
                                width={100}
                                render={(value, record: any) => (
                                    <Switch
                                        style={{
                                            backgroundColor: value ? 'green' : 'gray'
                                        }}
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        onClick={(e) => updateCheck(record, e)} defaultChecked={value}/>
                                )}
                            />
                            <Table.Column<{ id: string }>
                                title={translate('pages.venditas.action', 'Actions')}
                                dataIndex="actions"
                                width={50}
                                key="actions"
                                render={(_, record) => (
                                    <Space>
                                        <Popconfirm title={'Sei Sicuro di Voler Eliminare la Vendita?'}
                                                    onConfirm={() => handleDelete(record)}
                                                    cancelText={'No'}
                                                    okText={'Si'}
                                        >
                                            <Button
                                                type={'primary'}
                                                size="small"
                                                htmlType={'button'}
                                            >Delete</Button>
                                        </Popconfirm>

                                    </Space>
                                )}
                            />
                        </Table>
                    </Col>
                </Row>
            </div>
        </List>
    );
};
