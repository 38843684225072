import { IResourceComponentsProps, useCreate } from "@refinedev/core";
import { Create, useForm, useSelect } from "@refinedev/antd";
import { Col, Form, Input, Row, Select, Switch } from "antd";

import React from "react";
import {IDeviceType, ISeller} from '../../interfaces';


export const DeviceTypeCreate: React.FC<IResourceComponentsProps> = () => {

    const {formProps, saveButtonProps} = useForm<IDeviceType>({

    });

    return (
            <Create saveButtonProps={saveButtonProps}>
                <Form
                    {...formProps}
                    layout="vertical"
                >
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                label="Description"
                                name="description"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                label="Cassa"
                                name="isCassa"
                                valuePropName={'checked'}
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Switch />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                label="Magazzino"
                                name="isMagazzino"
                                valuePropName={'checked'}
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Switch />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                label="Luminaria"
                                name="isLuminaria"
                                valuePropName={'checked'}
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Switch />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Create>
    );
};
