import { IResourceComponentsProps, useCreate } from "@refinedev/core";
import { Create, useForm, useSelect } from "@refinedev/antd";
import { Col, Form, Input, Row, Select, Switch } from "antd";

import React from "react";
import {IDevice, IDeviceType, IGroup, ISeller} from '../../interfaces';


export const DeviceCreate: React.FC<IResourceComponentsProps> = () => {

    const {formProps, saveButtonProps} = useForm<IDevice>({
        meta: {
            populate: "device_type"
        }
    });

    const { selectProps: deviceSelectProps } = useSelect<IDeviceType>({
        resource: "device-types",
        optionValue: 'id',
        optionLabel: 'description',

        pagination: {
            mode: "server"
        }
    });

    // const { selectProps: sellerSelectProps } = useSelect<ISeller>({
    //     resource: "sellers",
    //     optionValue: 'id',
    //     optionLabel: 'description'
    // });

    return (
            <Create saveButtonProps={saveButtonProps}>
                <Form
                    {...formProps}
                    layout="vertical"
                >
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                label="Dispositivo"
                                name="description"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={4}>
                            <Form.Item
                                label="Numero Dispositivo"
                                name="deviceNumber"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                label="Seriale"
                                name="serial"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Indirizzo IP"
                                name="ip"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                label="Unico Turno"
                                name="isUnicoTurno"
                                valuePropName={'checked'}
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Switch />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                label="Device Type"
                                name="device-type"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Select {...deviceSelectProps} />
                            </Form.Item>
                        </Col>
                        {/*<Col span={8}>*/}
                        {/*    <Form.Item*/}
                        {/*        label="Seller"*/}
                        {/*        name="seller"*/}
                        {/*        rules={[*/}
                        {/*            {*/}
                        {/*                required: true,*/}
                        {/*            },*/}
                        {/*        ]}*/}
                        {/*    >*/}
                        {/*        <Select {...sellerSelectProps} />*/}
                        {/*    </Form.Item>*/}
                        {/*</Col>*/}
                    </Row>
                </Form>
            </Create>
    );
};
