import React from "react";
import { createRoot } from "react-dom/client";

import reportWebVitals from "./reportWebVitals";
import App from "./App";
import "./i18n";
import CacheBuster from 'react-cache-buster';
import {Spin} from 'antd';
import {VERSION} from './constants';
const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);
const isProduction = process.env.NODE_ENV === 'production';
root.render(
  // <React.StrictMode>
    <React.Suspense fallback="loading">
        <CacheBuster
            onCacheClear={(e: any) => {console.log('ELIMINO CACHE: ', e)}}
            currentVersion={VERSION}
            isEnabled={true}
            reloadOnDowngrade={false}
            isVerboseMode={false}
            loadingComponent={<Spin />}
        >
           <App />
        </CacheBuster>
    </React.Suspense>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
