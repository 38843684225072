import React, {useState} from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { Edit, useForm, useSelect } from "@refinedev/antd";
import { Form, Input, Row, Col, Switch, Select } from "antd";
import {IDeviceType, ISeller} from '../../interfaces';


export const DeviceTypeEdit: React.FC<IResourceComponentsProps> = () => {
    const {formProps, saveButtonProps, queryResult} = useForm<IDeviceType>({
        meta: {
            populate: "*"
        }
    });

    return (
        <Edit saveButtonProps={saveButtonProps}>
            <Form
                {...formProps}
                layout="vertical"
            >
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            label="Description"
                            name="description"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Cassa"
                            name="isCassa"
                            valuePropName={'checked'}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Switch />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Magazzino"
                            name="isMagazzino"
                            valuePropName={'checked'}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Switch />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Luminaria"
                            name="isLuminaria"
                            valuePropName={'checked'}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Switch />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Edit>
    );
};
