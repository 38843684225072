import React, {FC, useContext, useEffect, useState} from 'react';
import {Space, Spin, Tooltip, Typography} from 'antd';
// import {SocketContext, socket} from '../../contexts/socket';
import {UtilityHelper} from '../../helper/utility';
import {API_URL} from '../../constants';
import {socket, SocketContext} from '../../contexts/socket';
interface SelectProps {
    onSelect: () => void;
    theme?: "light" | "dark";
};

export const SocketSelect: FC<SelectProps> = ( props ) => {
    const [isConnected, setIsConnected] = useState(false);
    const socket = useContext(SocketContext);
    // const utilityHelper = UtilityHelper(API_URL + '/api');

    useEffect(() => {
        setInterval(() => {
            //console.log("CHECK", socket.connected);
            setIsConnected(socket.connected);
        }, 5000)
    }, []);

    const sendCheck = () => {
        socket.emit("sendMessage", {id: socket.id, data: "CONTROLLO"})
        console.log('CONTROLLO')
    }
    return (
        <Space>
            <Tooltip title={isConnected ? 'Connessione Attiva' : 'Connessione Non Attiva'}>
                <div className={isConnected ? 'led-yellow' : 'led-red'} style={{
                    marginRight: 30
                }} onClick={sendCheck}></div>
            </Tooltip>

        </Space>
    );
};
