import React, {useEffect, useRef, useState} from 'react';
import { IResourceComponentsProps, useCreate, useMany } from "@refinedev/core";
import { Edit, useForm, useSelect } from "@refinedev/antd";

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import {Form, Input, Select, Row, Col, Switch, Button, InputNumber, message} from 'antd';

import {IBarcode, IBarcodePackage, ICategory, IProduct, ISeller} from 'interfaces';
import {InputRef} from 'antd';
import {toast} from 'react-toastify';

export const ProductEdit: React.FC<IResourceComponentsProps> = () => {
    const inputRefB = useRef<InputRef>(null);
    const inputRefBP = useRef<InputRef>(null);
    const {formProps, saveButtonProps, queryResult} = useForm<IProduct>({
        meta: {populate: "*"},
    });

    const barcodes: any = queryResult?.data?.data?.barcodes.map((item) => {
        return {value: item.id, label: item.code}
    })

    const barcode_packages: any = queryResult?.data?.data?.barcode_packages.map((item) => {
        return {value: item.id, label: item.code}
    })

    const { selectProps: barcodesSelectProps, queryResult: barCodeSelect } = useSelect<IBarcode>({
        resource: "barcodes",
        optionValue: "id",
        optionLabel: "code",

        pagination: {
            mode: "server"
        }
    });

    const { selectProps: barcode_packagesSelectProps, queryResult: barCodePackageSelect } = useSelect<IBarcodePackage>({
        resource: "barcode-packages",
        optionValue: "id",
        optionLabel: "code",

        pagination: {
            mode: "server"
        }
    });

    const { selectProps: categorySelectProps } = useSelect<ICategory>({
        resource: "categories",
        optionValue: 'id',
        optionLabel: 'description',
        defaultValue: queryResult?.data?.data?.category?.id,

        pagination: {
            mode: "server"
        }
    });

    const newBarcode = useCreate<IBarcode>();
    const newBarcodePackage = useCreate<IBarcodePackage>();
    const addBarcode = () => {
        newBarcode.mutateAsync({
            resource: "barcodes",
            values: {
                code: inputRefB.current?.input?.value,
                isPacket: true
            }
        }).then( (res: any) => {
            barcodes.push({value: res.data.data.id, label: res.data.data.attributes.code});
            setTimeout(() => {
                barCodeSelect.refetch();
                formProps.form?.setFieldsValue({'barcodes': barcodes});
                const b = barcodes.map( (a: any) => a.value);
                formProps.form?.setFieldsValue({'barcodes': b});
            }, 600)
        })
    }

    const addBarcodePackage = () => {
        newBarcodePackage.mutateAsync({
            resource: "barcode-packages",
            values: {
                code: inputRefBP.current?.input?.value,
                isPackage: true
            }
        }).then( (res: any) => {
            barcode_packages.push({value: res.data.data.id, label: res.data.data.attributes.code});
            setTimeout(() => {
                barCodePackageSelect.refetch();
                formProps.form?.setFieldsValue({'barcode_packages': barcode_packages});
                const b = barcode_packages.map( (a: any) => a.value);
                formProps.form?.setFieldsValue({'barcode_packages': b});
            }, 600)
        })
    }

    const handleChange = () => {
        // console.log(formProps.form?.getFieldValue('barcodes'))
    }

    const handleChangePackage = () => {
        // console.log(formProps.form?.getFieldValue('barcode-packages'))
    }

    return (
        <Edit saveButtonProps={saveButtonProps}>
            <Form
                {...formProps}
                layout="vertical"
            >
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            label="Codice AMS"
                            name="codice"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Description"
                            name="description"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Categoria"
                            name={["category", "id"]}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select {...categorySelectProps}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={4}>
                        <Form.Item
                            label="Price"
                            name="price"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <InputNumber
                                style={{ width: '100%'}}
                                precision={2}
                                decimalSeparator = {','}
                                //formatter={(value) => `€ ${parseFloat(Number(value).toString()).toFixed(2)}`.replace(/\$\s?|(\.)/g, ',')}
                                //parser={(value) => parseFloat(Number(value).toString()).toFixed(2).replace('.', '').replace(/,/g, '.')}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Price Kg"
                            name="priceKg"
                        >
                            <InputNumber
                                style={{ width: '100%'}}
                                precision={2}
                                decimalSeparator = {','}
                                //formatter={(value) => `€ ${parseFloat(Number(value).toString()).toFixed(2)}`.replace(/\$\s?|(\.)/g, ',')}
                                //parser={(value) => parseFloat(Number(value).toString()).toFixed(2).replace('.', '').replace(/,/g, '.')}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Peso Fisc. Kg/€ Cf."
                            name="pesoFiscaleConf"
                        >
                            <InputNumber
                                style={{ width: '100%'}}
                                precision={2}
                                decimalSeparator = {','}
                                //formatter={(value) => `€ ${parseFloat(Number(value).toString()).toFixed(2)}`.replace(/\$\s?|(\.)/g, ',')}
                                //parser={(value) => parseFloat(Number(value).toString()).toFixed(2).replace('.', '').replace(/,/g, '.')}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Pezzi per Confezione"
                            name="nrPezziStecca"
                        >
                            <InputNumber
                                style={{ width: '100%'}}
                                />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Stock Min. Riordino Kg/Conf"
                            name="stockMinKg"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <InputNumber
                                style={{ width: '100%'}}
                                precision={2}
                                decimalSeparator = {','}
                                //formatter={(value) => `€ ${parseFloat(Number(value).toString()).toFixed(2)}`.replace(/\$\s?|(\.)/g, ',')}
                                //parser={(value) => parseFloat(Number(value).toString()).toFixed(2).replace('.', '').replace(/,/g, '.')}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Note"
                            name="note"
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            label="Add Barcode"
                            name={"addBarcode"}
                        >
                            <Input.Group compact>
                                <Input ref={inputRefB} style={{ width: 'calc(100% - 200px)' }} placeholder={'barcode'} />
                                <Button type="primary" onClick={() => addBarcode()}>Add</Button>
                            </Input.Group>
                        </Form.Item>
                    </Col>
                    <Col span={16}>
                        <Form.Item
                            label="Barcodes"
                            name={"barcodes"}
                            valuePropName={"id"}
                        >
                            <Select
                                style={{ minWidth: 200 }}
                                mode="multiple"
                                placeholder="Select Barcodes"
                                defaultValue={barcodes}
                                onChange={handleChange}
                                {...barcodesSelectProps}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            label="Add Barcode Package"
                            name={"addBarcodePackages"}
                        >
                            <Input.Group compact>
                                <Input ref={inputRefBP} style={{ width: 'calc(100% - 200px)' }} placeholder={'barcodePackage'} />
                                <Button type="primary" onClick={() => addBarcodePackage()}>Add</Button>
                            </Input.Group>
                        </Form.Item>
                    </Col>
                    <Col span={16}>
                        <Form.Item
                            label="Barcode Package"
                            name={"barcode_packages"}
                            valuePropName={"id"}
                        >
                            <Select
                                style={{ minWidth: 200 }}
                                mode="multiple"
                                placeholder="Select Barcodes"
                                defaultValue={barcode_packages}
                                onChange={handleChangePackage}
                                {...barcode_packagesSelectProps}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Edit>
    );
};
