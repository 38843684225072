import {CrudFilters, IResourceComponentsProps, useCreate, useList, useTranslate, useUpdate} from '@refinedev/core';

import {
    DateField,
    List, TagField, useForm, useModal,
    useTable,
} from '@refinedev/antd';

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import {Button, Col, Form, Input, InputNumber, InputRef, Modal, Row, Space, Typography} from 'antd';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {StoreContext} from '../../contexts/StoreContext';
import "@ant-design/charts/dist/index.css";
import {Area} from '@ant-design/charts';
import {CSVLink} from 'react-csv';
import dayjs from 'dayjs';
import {UtilityHelper} from '../../helper/utility';
import {API_URL} from '../../constants';
const {Title} = Typography

export const ReportGraficoUlpList: React.FC<IResourceComponentsProps> = () => {
    const {value, value2} = useContext(StoreContext);
    const [store, setStore] = value;
    const {form, formProps} = useForm();
    const [dataUlp, setDataUlp] = useState();
    const {data: listCausal} = useList<any>({
        resource: "causals",
        meta: {
          populate: 'seller'
        },
        pagination: {
            pageSize: 150,
            mode: "server"
        },
        queryOptions: {
            enabled: true
        },
        filters: [
            {
                field: 'seller][id]',
                operator: 'eq',
                value: store.id
            },
            {
                field: 'isLoad',
                operator: 'eq',
                value: true
            },
            {
                field: 'isTurn',
                operator: 'eq',
                value: true
            }
        ]
    });
    const {data: dataTurn, refetch: refetchDataTurn, isSuccess: isSuccessDataTurn} = useList<any>({
        resource: "custom/reportUlpGraficos",
        queryOptions: {
            enabled: false
        },
        filters: [
            {
                field: 'seller][id]',
                operator: 'eq',
                value: store.id
            },
            {
                field: 'data',
                operator: 'eq',
                value: JSON.stringify(dataUlp)
            }
        ]
    });

    const {data: parametriUlp, refetch: refetchParametriUlp} = useList<any>({
        resource: "parametri-ulps",
        queryOptions: {
            enabled: true
        },
        filters: [
            {
                field: 'seller][id]',
                operator: 'eq',
                value: store.id
            }
        ]
    });

    const [data, setData] = useState([]);
    const [dataTurnA, setDataTurnA] = useState([]);

    const asyncFetchTurn = () => {
        const d: any = [];
        const u: any = [];
        let arrayT: any = [];
        refetchDataTurn().then((response: any) => {
            console.log(response);
            response.data.data.map((x: any) => {

                d.push({
                    descrizione: x.descrizione,
                    incasso: (x?.incasso ? x.incasso : 0),
                    mese: x.mese,
                    nMese: x.nMese
                });
            });
            const r: any = [];
            response.data.data.sort((p1: any, p2: any) => p1.nMese > p2.nMese).reduce( (res: any, value: any) => {
                if (!res[value.mese]) {
                    res[value.mese] = { descrizione: 'Totale U.L.P.', incasso: 0, mese: value.mese, nMese: value.nMese };
                    d.push(res[value.mese])
                }
                res[value.mese].incasso += Math.round(value.incasso);
                return res;
            }, {});

            const n: any = [];
            d.map((u: any) => {
                if (u['descrizione'] === 'Totale U.L.P.') {
                    n.push({descrizione: 'Totale U.N.P.', incasso: u['incasso'] - parametriUlp?.data[0].valori['sgm'], mese: u.mese, nMese: u.nMese})
                }
            })
            console.log(n);

            console.log(d);
            arrayT = [...d, ...n]
            setDataTurnA(arrayT);
        })
            .catch((error) => {
                console.log('fetch data failed', error);
            });
    };

    const configTurn = {
        data: dataTurnA,
        isStack: true,
        xField: 'mese',
        yField: 'incasso',
        seriesField: 'descrizione',
        marginRatio: 0,
        locale: 'it-IT',
        label: {
            style: {
                fill: 'white',
                opacity: 0.9,
                fontSize: 14,
            },
            layout: [
                {
                    type: 'interval-adjust-position',
                },
                {
                    type: 'interval-hide-overlap',
                },
                {
                    type: 'adjusts_color',
                },
            ],
        },
        smooth: true,
        slider: {
            start: 0.1,
            end: 0.9,
            trendCfg: {
                isArea: true,
            },
        },
        animation: {
            appear: {
                animation: 'path-in',
                duration: 5000,
            },
        },
    };

    const { confirm } = Modal;
    const inputRef: any = useRef<InputRef>(null);
    const ulpPassword = useRef(false);

    const [showGrafico, setShowGrafico] = useState(false);

    const createParameterUlp = useCreate();
    const updateParameterUlp = useUpdate();
    const showConfirm = () => {
        confirm({
            title: 'Per effettuare la variazione è richiesta una password...',
            icon: <Icons.ExclamationCircleFilled />,
            content: <>
                <Input type={'password'} ref={inputRef} placeholder={'Password...'}></Input>
            </>,
            onOk() {
                if (inputRef.current.input.value === passwordSeller) {
                    localStorage.setItem('ulp', inputRef.current.input.value);
                    ulpPassword.current = true;
                    if (parametriUlp && parametriUlp.data && parametriUlp.data.length > 0) {
                        console.log('Ripristino ULP: ', parametriUlp?.data[0].valori)
                        form.setFieldsValue(parametriUlp?.data[0].valori);
                    }
                    setShowGrafico(true);
                };
            },
            onCancel() {
                console.log('Cancel');
                ulpPassword.current = false;
                setShowGrafico(false);
            },
        });
    };

    useEffect(() => {
        showConfirm()
    }, []);

    const { show, close, modalProps } = useModal();

    const showParameter = () => {
        if (parametriUlp && parametriUlp.data && parametriUlp.data.length > 0) {
            console.log('Ripristino ULP: ', parametriUlp?.data[0].valori)
            form.setFieldsValue(parametriUlp?.data[0].valori);
        }
        show();
    }
    const elabora = (e: any) => {
        setDataUlp(e);
        setTimeout(() => {
            asyncFetchTurn();
            close();
            setShowGrafico(true);
        }, 600);
        if (parametriUlp && parametriUlp.data && parametriUlp.data.length > 0) {
            updateParameterUlp.mutateAsync({
                resource: 'parametri-ulps',
                id: parametriUlp.data[0].id,
                values: {
                    valori: e,
                    seller: store.id
                }
            }).then();
        } else {
            createParameterUlp.mutateAsync({
                resource: 'parametri-ulps',
                values: {
                    valori: e,
                    seller: store.id
                }
            }).then();
        }

    }

    const utilityHelper = UtilityHelper(API_URL + "/api");
    const [passwordSeller, setPasswordSeller] = useState('');
    useEffect(() => {
        utilityHelper.seller().then( (res: any) => {
            setPasswordSeller(res.password);
        });
    }, []);

    // console.log(showGrafico);
    return (
        <>
            <List
                title={'Report Incasso'}
                headerButtons={
                    <Space>
                        <Button type={'default'} htmlType={'button'} icon={<Icons.ExportOutlined />}  onClick={showParameter}>Impostazioni</Button>
                    </Space>
                }
            >
                { showGrafico &&
                    <Row gutter={24}>
                        <Col span={24}>
                            { isSuccessDataTurn &&
                                <Area {...configTurn} legend={{
                                    layout: 'vertical',
                                    position: 'right-top'
                                }}/>
                            }
                        </Col>
                    </Row>
                }
            </List>
            <Modal {...modalProps} footer={false}
            >
                <p>Parametri % ULP</p>
                <Form form={form}
                      layout={'horizontal'}
                      onFinish={(e: any) => elabora(e)}
                >
                    <Row gutter={24}>
                        <Col span={12}>
                            <Title level={5}>Spese Generali Mensili</Title>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={''}
                                name={'sgm'}>
                                <InputNumber
                                    controls={false}
                                    style={{width: '100%', appearance: 'textfield'}}
                                    precision={2}
                                    decimalSeparator= {','}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Title level={5}>Gratta & Vinci</Title>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={''}
                                name={'gev'}>
                                <InputNumber
                                    controls={false}
                                    style={{width: '100%', appearance: 'textfield'}}
                                    precision={2}
                                    decimalSeparator= {','}
                                    />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Title level={5}>Tabacchi</Title>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={''}
                                name={'tbc'}>
                                <InputNumber
                                    controls={false}
                                    style={{width: '100%', appearance: 'textfield'}}
                                    precision={2}
                                    decimalSeparator= {','}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Title level={5}>Scontrinato</Title>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={''}
                                name={'sct'}>
                                <InputNumber
                                    controls={false}
                                    style={{width: '100%', appearance: 'textfield'}}
                                    precision={2}
                                    decimalSeparator= {','}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Title level={5}>Scontrinato 22%</Title>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={''}
                                name={'sct22'}>
                                <InputNumber
                                    controls={false}
                                    style={{width: '100%', appearance: 'textfield'}}
                                    precision={2}
                                    decimalSeparator= {','}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    { listCausal && listCausal.data && listCausal.data.map((f: any) => (
                        <Row gutter={24}>
                            <Col span={12}>
                                <Title level={5}>{f.description}</Title>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label={''}
                                    name={f.acronimo}>
                                    <InputNumber
                                        controls={false}
                                        style={{width: '100%', appearance: 'textfield'}}
                                        precision={2}
                                        decimalSeparator= {','}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    ))}
                    <Row gutter={24}>
                        <Col span={24} style={{
                            textAlign: 'right'
                        }}>
                            <Button type={'primary'} htmlType={'submit'}>Elabora</Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>

    );
};
